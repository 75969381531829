import React from 'react';

interface DeleteButtonProps {
    onClick?: () => void;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick }) => {
    return (
        <button onClick={onClick}>
            <svg width="33" height="33" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.3" y="0.3" width="16.9" height="19.5" rx="3.7" fill="#FFE6D7"/>
            <rect x="0.3" y="0.3" width="16.9" height="19.5" rx="3.7" stroke="#E40606" stroke-width="0.6"/>
            <path d="M5.9375 7.42578V13.8008H11.5625V7.42578H5.9375Z" stroke="#E40606" stroke-width="0.625" stroke-linejoin="round"/>
            <path d="M8 9.30078V11.7383" stroke="#E40606" stroke-width="0.625" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.5 9.30078V11.7383" stroke="#E40606" stroke-width="0.625" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 7.42578H12.5" stroke="#E40606" stroke-width="0.625" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.25 7.42578L7.86669 6.30078H9.64571L10.25 7.42578H7.25Z" stroke="#E40606" stroke-width="0.625" stroke-linejoin="round"/>
    </svg></button>
    );
}

export default DeleteButton;
