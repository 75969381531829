import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton, Tooltip } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

type DataRepositoryHeaderProps = {
    isAscending: boolean;
    onSortByDate: () => void;
};
function DataRepositoryHeader({ isAscending, onSortByDate }: DataRepositoryHeaderProps) {
    return (
        <>
            <Flex
                padding={"10px 15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={"#F8F8F8"}
                alignItems={"center"}
                borderTopLeftRadius={"8px"}
                borderTopRightRadius={"8px"}
            >
                <Flex padding={"0 5px"} width="5%">
                    #
                </Flex>

                <Flex padding={"0 5px"} width="25%">
                    File Name
                </Flex>
                <Flex padding={"0 5px"} width="20%">
                    File Description
                </Flex>
                <Flex padding={"0 5px"} width="15%">
                    Owned By
                </Flex>
                <Flex padding={'0 5px'} width="15%" style={{ alignItems: 'center' }}>
                    Date Uploaded
                    <Tooltip title="Click to sort by date">
                        <IconButton onClick={onSortByDate} size="small">
                            {isAscending ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
                        </IconButton>
                    </Tooltip>
                </Flex>
                <Flex padding="0 5px" gap="10px" width="5%"></Flex>
                <Flex padding={"0 5px"} width="15%">
                    Actions
                </Flex>
            </Flex>
        </>
    );
}

export default DataRepositoryHeader;