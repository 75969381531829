import React, { useEffect, useState, useRef } from "react";
import { Icon as EvaLibraryIcon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import {
    TextField,
    Chip,
    Box,
    Dialog,
    DialogActions,
    Tooltip,
    DialogContent,
    DialogTitle,
    Backdrop,
    Snackbar,
    Alert,
    Typography,
} from "@mui/material";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { axiosHelperCore } from "utils";
import { useAppSelector } from "hooks";
import * as XLSX from "xlsx";
import DeleteButton from "containers/DataRepository/component/DeleteButton";
import EditButton from "containers/DataRepository/component/EditButton";
import NoRecordFound from "containers/ManagePersonas/component/NoRecordFound";
import DeleteConfirmationPopup from "containers/ManagePersonas/component/DeleteConfirmationPopup";
import AddCustomDataComponent from "./component/AddCustomDataComponent";
import DataRepositoryHeader from "./component/DataRepositoryHeader";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "assets/icons/DeleteIcon";
import AddIcon from "assets/icons/AddIcon";
import "./file.css";
import PreviewDataContent from "./component/PreviewDataContent";
import PdfText from "react-pdftotext";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";
import SearchBar from "components/TailwindComponents/SearchBar";
import DownloadButton from "./component/DownloadButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import InstructionImage from './assets/instruction.png';

const columnHeadings = ["Title", "Tags", "Data"];
const MAX_TAGS_DISPLAY = 2;

function DataRepositoryContent() {
    const [open, setOpen] = useState(false);
    type Severity = "info" | "error" | "warning" | "success";

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<Severity>("info");

    const [loading, setLoading] = useState(false);
    const [isAscending, setIsAscending] = useState(true);
    const [dataRepositoryList, setDataRepositoryList] = useState([]);
    const [previewData, setPreviewData] = useState([]);
    const [isPreviewPopupOpen, setIsPreviewPopupOpen] = useState(false);
    const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
    const [isAddingCustomData, setIsAddingCustomData] = useState(false);
    const [editData, setEditData] = useState(null);
    const [selectedRepo, setSelectedRepo] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isTagsDialogOpen, setIsTagsDialogOpen] = useState(false);
    const [tagsToDisplay, setTagsToDisplay] = useState([]);
    const [pdfDataPreview, setPdfDataPreview] = useState(false);
    const [s3Url, setS3Url] = useState("");
    const [dataRepositoryOriginalList, setDataRepositoryOriginalList] = useState(
        []
    );

    const [expandedRows, setExpandedRows] = useState(new Set());
    const [expandedTagRows, setExpandedTagRows] = useState(new Set());
    const searchValue = useRef("");

    const [fileName, setFileName] = useState("");
    const [fileDescription, setFileDescription] = useState("");
    const [isFileNameDialogOpen, setIsFileNameDialogOpen] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [fileUploadDataError, setFileUploadDataError] = useState(false);
    const [openInstructions, setOpenInstructions] = useState(false);

    const toggleRowExpansion = (rowIndex) => {
        const newExpandedRows = new Set(expandedRows);
        if (expandedRows.has(rowIndex)) {
            newExpandedRows.delete(rowIndex);
        } else {
            newExpandedRows.add(rowIndex);
        }
        setExpandedRows(newExpandedRows);
    };

    const sortDataByDate = () => {
        const sortedData = [...dataRepositoryList].sort((a, b) => {
            let dateA = new Date(a.createdOn).getTime();
            let dateB = new Date(b.createdOn).getTime();

            return isAscending ? dateA - dateB : dateB - dateA;
        });
        setDataRepositoryList(sortedData);
        setIsAscending(!isAscending);
    };

    const toggleTagExpansion = (index) => {
        const newExpandedTagRows = new Set(expandedTagRows);
        if (expandedTagRows.has(index)) {
            newExpandedTagRows.delete(index);
        } else {
            newExpandedTagRows.add(index);
        }
        setExpandedTagRows(newExpandedTagRows);
    };

    const loggedInMemberDetails = useAppSelector(
        (state) => state.profileState.profileData
    );

    const showSnackbar = (message: string, severity: Severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleAddCustomData = () => {
        setEditData(null);
        setIsAddingCustomData(true);
    };

    const handleEditRow = (rowData) => {
        setEditData(rowData);
        setIsAddingCustomData(true);
    };

    const handleSaveCustomData = (newData) => {
        handleSubmitPreviewData(newData);
        setIsAddingCustomData(false);
        setPdfDataPreview(false);
    };

    const handleOpenPopup = (repoData) => {
        setIsPopupOpen(true);
        setSelectedRepo(repoData);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setFileUploadDataError(false);
        const file = e.target.files?.[0];
        if (file) {
            setUploadedFile(file);
            const fileType = file.type;

            if (fileType === "application/pdf") {
                try {
                    const text = await PdfText(file);
                    setEditData({ rawData: text });
                    setIsAddingCustomData(true);
                    setPdfDataPreview(true);
                } catch (err) {
                    console.error("Error extracting text:", err);
                    showSnackbar("Failed to extract text from PDF", "error");
                }
            } else {
                const reader = new FileReader();

                reader.onload = (event) => {
                    const content = event.target?.result as ArrayBuffer;
                    const data = new Uint8Array(content);
                    const workbook = XLSX.read(data, { type: "array" });

                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];

                    const jsonData = XLSX.utils.sheet_to_json(sheet, {
                        raw: false,
                    }) as any[];

                    const formattedData = jsonData
                        .map((item) => {
                            const normalizedItem = Object.keys(item).reduce((acc, key) => {
                                acc[key.toLowerCase()] = item[key];
                                return acc;
                            }, {} as Record<string, any>);

                            const { title, data, ...rest } = normalizedItem;

                            const otherData = Object.keys(rest).reduce((acc, key) => {
                                if (key !== 'data') {
                                    acc[key] = rest[key];
                                }
                                return acc;
                            }, {} as Record<string, any>);

                            return {
                                title: title ?? "",
                                rawData: data ?? "",
                                otherData,
                            };
                        })
                        .filter((item) => item?.rawData !== "");

                    if (formattedData?.length > 0) {
                        setPreviewData(formattedData);
                        // setIsPreviewPopupOpen(true);
                    } else {
                        // showSnackbar(
                        //   "File does not contain the column 'Data'. Please include the column 'Data' to continue. Please refer to the instructions image.",
                        //   "error"
                        // );
                        setFileUploadDataError(true);
                    }
                };

                reader.readAsArrayBuffer(file);
            }
        }
        // setIsUploadPopupOpen(false);
    };

    const uploadFileToS3 = async (file: File): Promise<{ data: string }> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                try {
                    const base64data = reader.result?.toString().split(",")[1];
                    const mimeType = file.type;

                    const obj = {
                        data: base64data,
                        filename: `${fileName}_${loggedInMemberDetails?.organizationId}`,
                        mimeType,
                    };

                    axiosHelperCore({
                        url: `rapport/user/uploadContentLibraryFileToS3`,
                        method: "POST",
                        JSONData: obj,
                    })
                        .then(response => {
                            const url = response.data.url;
                            console.log("File uploaded to S3 URL:", url);
                            setS3Url(url);

                            resolve({ data: url });
                        })
                        .catch(error => {
                            console.error("Error uploading file to S3:", error);
                            reject(error);
                        });
                    // resolve(obj);

                } catch (error) {
                    console.error("Error uploading file to S3:", error);
                    reject(error);
                }
            };

            reader.onerror = () => {
                reject(new Error("File reading failed"));
            };

            reader.readAsDataURL(file);
        });
    };

    const handleFilenameSubmit = () => {
        const isFileNameDuplicate = dataRepositoryOriginalList.some(
            (repo) => repo?.fileName?.toLowerCase() === fileName?.toLowerCase()
        );

        if (isFileNameDuplicate) {
            showSnackbar(
                "File name already exists. Please choose another name.",
                "error"
            );
        } else {
            // setIsFileNameDialogOpen(false);
            setIsUploadPopupOpen(false);
            // setIsPreviewPopupOpen(true);
            handleSubmitPreviewData();
        }
    };

    const handleDeleteRow = (id) => {
        const updatedData = previewData.filter((_, index) => index !== id);
        setPreviewData(updatedData);
    };

    const fetchDataRepositoryList = async () => {
        try {
            setLoading(true);

            const obj = {
                userId: loggedInMemberDetails?.userId,
                organizationId: loggedInMemberDetails?.organizationId,
            };

            const response = await axiosHelperCore({
                url: `rapport/user/getDataRepositoryList`,
                method: "POST",
                JSONData: obj,
            });

            if (response?.data?.result) {
                setDataRepositoryList(response?.data?.result);
                setDataRepositoryOriginalList(response?.data?.result);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error submitting content library:", error);
            showSnackbar("Something went wrong", "error");
        }
    };

    const handleSubmitPreviewData = async (newData = null) => {
        try {
            setLoading(true);

            let fileS3Url = "";

            if (uploadedFile) {
                try {
                    const uploadResult = await uploadFileToS3(uploadedFile);
                    fileS3Url = uploadResult?.data;
                } catch (uploadError) {
                    console.error("Error uploading file to S3:", uploadError);
                    showSnackbar("Error uploading file", "error");
                    return;
                }
            }

            const obj = {
                data: previewData?.length > 0 ? previewData : newData,
                userId: loggedInMemberDetails?.userId,
                organizationId: loggedInMemberDetails?.organizationId,
                fileName: previewData?.length > 0 ? fileName : newData?.[0]?.fileName,
                fileDescription: previewData?.length > 0 ? fileDescription : newData?.[0]?.fileDescription,
                fileUrl: fileS3Url,
                contentId: previewData?.length > 0 ? "" : newData?.[0]?.contentId,
            };

            const response = await axiosHelperCore({
                url: `rapport/user/addOrUpdateDataRepository`,
                method: "POST",
                JSONData: obj,
            });

            if (response?.data?.result) {
                showSnackbar("Data is uploaded successfully", "success");
                setFileName("");
                setFileDescription("");
                setUploadedFile(null);
                fetchDataRepositoryList();
            }

            setPreviewData([]);
            // setIsPreviewPopupOpen(false);
            setLoading(false);
            setS3Url("");
            setUploadedFile(null);
        } catch (error) {
            console.error("Error submitting content library:", error);
            showSnackbar("Something went wrong", "error");
        }
    };

    const handleDeleteData = async () => {
        try {
            setLoading(true);

            const obj = {
                userId: loggedInMemberDetails?.userId,
                contentId: selectedRepo?.contentId,
            };

            const response = await axiosHelperCore({
                url: `rapport/user/deleteDataRepositoryRecord`,
                method: "POST",
                JSONData: obj,
            });

            if (response?.data?.success) {
                showSnackbar("Data is deleted successfully", "success");
                fetchDataRepositoryList();
            }

            setLoading(false);
        } catch (error) {
            console.error("Error submitting content library:", error);
            showSnackbar("Something went wrong", "error");
        }
    };

    const handleOpenInstructions = () => {
        setOpenInstructions(true);
    };

    const handleCloseInstructions = () => {
        setOpenInstructions(false);
    };

    const renderCellContent = (content, rowIndex) => {
        const words = content.split(" ");

        if (words.length > 20) {
            if (!expandedRows.has(rowIndex)) {
                const visibleWords = words.slice(0, 20).join(" ");
                return (
                    <>
                        {visibleWords}...
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                toggleRowExpansion(rowIndex);
                            }}
                            style={{
                                color: "rgb(0, 148, 227)",
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginLeft: "3px",
                            }}
                        >
                            View More
                        </a>
                    </>
                );
            } else {
                return (
                    <>
                        {content}
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                toggleRowExpansion(rowIndex);
                            }}
                            style={{
                                color: "rgb(0, 148, 227)",
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginLeft: "3px",
                            }}
                        >
                            View Less
                        </a>
                    </>
                );
            }
        }

        return content;
    };

    const handleShowMoreTags = (tags) => {
        setTagsToDisplay(tags);
        setIsTagsDialogOpen(true);
    };

    const handleSearchChange = (e) => {
        searchValue.current = e?.target?.value?.toLowerCase();
        seachDataPipe();
    };

    const seachDataPipe = async () => {
        let searchContext = searchValue.current;
        let filteredData = dataRepositoryOriginalList;

        if (searchContext.trim() != "") {
            filteredData = filteredData.filter((m) => {
                const titleIncludes = m?.fileName?.toLowerCase().includes(searchContext);
                return titleIncludes;
            });
            setDataRepositoryList(filteredData);
        } else {
            setDataRepositoryList(filteredData);
        }
    };

    useEffect(() => {
        if (loggedInMemberDetails?.organizationId) {
            fetchDataRepositoryList();
        }
    }, []);

    return (
        <>
            {loading && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <MemoRevenoidLoader />
                </Backdrop>
            )}
            {
                // previewData.length > 0 && isPreviewPopupOpen ? (
                //     <PreviewDataContent
                //         previewData={previewData}
                //         setPreviewData={setPreviewData}
                //         handleSubmitPreviewData={handleSubmitPreviewData}
                //         onBack={() => {
                //             setIsPreviewPopupOpen(false);
                //             setFileName("");
                //             setFileDescription("");
                //             setUploadedFile(null);
                //         }}
                //         fileName={fileName}
                //     />
                // ) : 
                isAddingCustomData ? (
                    <AddCustomDataComponent
                        onBack={() => {
                            setIsAddingCustomData(false);
                            setPdfDataPreview(false);
                        }}
                        onSave={handleSaveCustomData}
                        editData={editData}
                        pdfDataPreview={pdfDataPreview}
                        setFileName={setFileName}
                        setFileDescription={setFileDescription}
                        dataRepositoryOriginalList={dataRepositoryOriginalList}
                    />
                ) : (
                    <>
                        <Flex justifyContent={"space-between"} alignItems="center">
                            <Flex>
                                <SearchBar
                                    value={searchValue.current}
                                    placeholder="Search via file name"
                                    onChange={handleSearchChange}
                                    className="h-2"
                                />
                            </Flex>

                            <Flex justifyContent="flex-end" gap="10px">
                                <Box display={"flex"}>
                                    <Button
                                        size="xs"
                                        variant="secondary"
                                        fontSize={"14px"}
                                        padding={"10px 10px"}
                                        onClick={handleAddCustomData}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "3px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <AddIcon fill="red" width="16px" height="16px"></AddIcon>
                                            <span>Add Custom Data</span>
                                        </div>
                                    </Button>
                                </Box>
                                <Box display="flex">
                                    <Button
                                        size="xs"
                                        variant="secondary"
                                        fontSize="14px"
                                        padding="10px 10px"
                                        onClick={() => setIsUploadPopupOpen(true)}
                                    >
                                        Upload Data
                                    </Button>
                                </Box>
                            </Flex>
                        </Flex>

                        <Dialog
                            open={isUploadPopupOpen}
                            onClose={() => setIsUploadPopupOpen(false)}
                            aria-labelledby="upload-data"
                            fullWidth
                        >
                            <div>
                                <DialogTitle>Upload Document</DialogTitle>
                                <DialogContent>
                                    <Typography variant="subtitle1" style={{ padding: "5px 0" }}>File Name<span style={{ "color": "red" }}>*</span></Typography>
                                    <TextField
                                        margin="dense"
                                        // label="File Name"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={fileName}
                                        required
                                        onChange={(e) => setFileName(e.target.value)}
                                    />

                                    <Typography variant="subtitle1" style={{ padding: "5px 0" }}>File Description (optional)</Typography>
                                    <TextField
                                        margin="dense"
                                        // label="File Description (optional)"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={fileDescription}
                                        onChange={(e) => setFileDescription(e.target.value)}
                                    />
                                    {fileUploadDataError && (
                                        <Alert severity="error" style={{ marginBottom: '16px', marginTop: '16px' }}>
                                            <Typography variant="body2">
                                                File does not contain the column 'Data'. Please include the column 'Data' to continue.{' '}
                                                <button
                                                    onClick={handleOpenInstructions}
                                                    style={{
                                                        textDecoration: 'underline',
                                                        color: 'blue',
                                                        background: 'none',
                                                        border: 'none',
                                                        padding: '0',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    Please refer to the instructions image.
                                                </button>
                                            </Typography>
                                        </Alert>
                                    )}
                                    <Typography variant="subtitle1" style={{ padding: "5px 0" }}>Document File<span style={{ "color": "red" }}>*</span>
                                        <span style={{ fontSize: '12px' }}> ( </span>
                                        <button
                                            onClick={handleOpenInstructions}
                                            style={{
                                                textDecoration: 'underline',
                                                color: 'blue',
                                                background: 'none',
                                                border: 'none',
                                                padding: '0',
                                                cursor: 'pointer',
                                                fontSize: "12px"
                                            }}
                                        >
                                            File upload instructions
                                        </button>
                                        <span style={{ fontSize: '12px' }}> )</span>
                                    </Typography>
                                    <div
                                        style={{
                                            marginTop: 8,
                                            border: "2px dashed #ccc",
                                            padding: 20,
                                            textAlign: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => document.getElementById('file-upload').click()}
                                    >
                                        <input
                                            type="file"
                                            accept=".pdf, .xlsx, .csv, .docx"
                                            style={{ display: "none" }}
                                            id="file-upload"
                                            onChange={handleUploadFile}
                                        />
                                        <CloudUploadIcon style={{ fontSize: 40, color: "#777" }} />
                                        {uploadedFile && !fileUploadDataError && (
                                            <Typography variant="body2" style={{ "padding": "5px 0" }}>
                                                <span style={{ fontWeight: "500" }}>Selected File</span>: {uploadedFile?.name}
                                            </Typography>
                                        )}
                                        <Typography variant="body2">
                                            Drag & drop your file here or click to browse
                                        </Typography>
                                        <Typography variant="caption">
                                            Supports PDF, XLSX, CSV, DOCX files
                                        </Typography>
                                    </div>
                                </DialogContent>
                                <DialogActions
                                    style={{ textAlign: "right", justifyContent: "right" }}
                                >
                                    <Button
                                        onClick={handleFilenameSubmit}
                                        variant="primary"
                                        size="xs"
                                        disabled={!fileName || !uploadedFile || fileUploadDataError}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        onClick={() => { setIsUploadPopupOpen(false); setFileName(""); setFileDescription(""); setUploadedFile(null); setFileUploadDataError(false); }}
                                        variant="secondary"
                                        size="xs"
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </div>
                        </Dialog>

                        <Dialog open={openInstructions} onClose={handleCloseInstructions} maxWidth="xl">
                            <DialogTitle>Instructions</DialogTitle>
                            <DialogContent>
                                <img
                                    src={InstructionImage}
                                    alt="Instructions"
                                    style={{ maxWidth: '300%', height: 'auto' }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseInstructions} variant="secondary" size="xs">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Box mt={3} zIndex={9}>
                            {dataRepositoryList.length > 0 ? (
                                <div className="flex flex-col">
                                    <DataRepositoryHeader isAscending={isAscending} onSortByDate={sortDataByDate} />
                                    {dataRepositoryList.map((data, index) => (
                                        <Flex
                                            key={data.repoId}
                                            padding="15px"
                                            fontSize="12px"
                                            fontWeight={500}
                                            backgroundColor={
                                                index % 2 === 0 ? "#FFF" : "rgb(249, 249, 249)"
                                            }
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Flex padding="0 5px" width="5%">
                                                {index + 1}
                                            </Flex>
                                            <Flex padding="0 5px" width="25%" flexWrap={"wrap"}>
                                                {data?.fileName ?? 'Unknown'}
                                            </Flex>
                                            <Flex padding="0 5px" width="20%" flexWrap={"wrap"}>
                                                {renderCellContent(data?.fileDescription, index) || "-"}
                                            </Flex>
                                            <Flex padding="0 5px" width="15%" flexWrap={"wrap"}>
                                                {data?.createdBy}
                                            </Flex>
                                            <Flex padding="0 5px" width="15%" flexWrap={"wrap"}>
                                                {data?.createdOn}
                                            </Flex>
                                            <Flex padding="0 5px" gap="10px" width="5%"></Flex>
                                            <Flex padding="0 5px" gap="10px" width="15%">
                                                <Tooltip
                                                    title={!data?.fileUploaded ? `Edit ${data.fileName}` : `Download ${data.fileName}`}
                                                    placement="top"
                                                    arrow
                                                >
                                                    <span>
                                                        {!data?.fileUploaded && <EditButton onClick={() => handleEditRow(data)} />}
                                                        {data?.fileUploaded && <DownloadButton initialUrl={data?.fileUrl} userId={data?.userId} organizationId={data?.organizationId} contentId={data?.contentId} fileName={data?.fileName} setLoadingLoader={setLoading} />}
                                                    </span>
                                                </Tooltip>
                                                <Tooltip
                                                    title={`Delete ${data.fileName}`}
                                                    placement="top"
                                                    arrow
                                                >
                                                    <span>
                                                        <DeleteButton onClick={() => handleOpenPopup(data)} />
                                                    </span>
                                                </Tooltip>
                                            </Flex>
                                        </Flex>
                                    ))}
                                </div>
                            ) : (
                                <NoRecordFound />
                            )}
                        </Box>
                        <Dialog
                            fullWidth
                            open={isTagsDialogOpen}
                            onClose={() => setIsTagsDialogOpen(false)}
                            aria-labelledby="display-tags"
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "0",
                                    height: "55px",
                                }}
                            >
                                <div>
                                    <DialogTitle
                                        style={{
                                            flex: "none",
                                            textAlign: "center",
                                            fontSize: "20px",
                                        }}
                                    >
                                        Tags
                                    </DialogTitle>
                                </div>
                                <div style={{ paddingRight: "20px" }}>
                                    <a onClick={() => setIsTagsDialogOpen(false)}>
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            height="24px"
                                            fill="currentColor"
                                        >
                                            <path d="M18.3 5.71a1 1 0 0 0-1.41 0L12 10.59l-4.88-4.88a1 1 0 0 0-1.41 1.41L10.59 12l-4.88 4.88a1 1 0 1 0 1.41 1.41L12 13.41l4.88 4.88a1 1 0 0 0 1.41-1.41L13.41 12l4.88-4.88a1 1 0 0 0 .01-1.41z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>

                            <hr></hr>
                            <DialogContent>
                                {tagsToDisplay.map((tag, i) => (
                                    <Chip
                                        key={i}
                                        label={tag}
                                        style={{
                                            border: "1px solid rgb(0, 148, 227)",
                                            color: "rgb(0, 148, 227)",
                                            backgroundColor: "white",
                                            fontSize: "12px",
                                            margin: "2px",
                                        }}
                                    />
                                ))}
                            </DialogContent>
                        </Dialog>
                        <DeleteConfirmationPopup
                            personaName={selectedRepo?.fileName}
                            onDelete={handleDeleteData}
                            isOpen={isPopupOpen}
                            onClose={handleClosePopup}
                        />

                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackbar}
                            color="red"
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            sx={{
                                width: "100%",
                                maxWidth: "600px",
                                left: "50%",
                                transform: "translateX(-50%)",
                            }}
                        >
                            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </>
                )}
        </>
    );
}

export default DataRepositoryContent;
