import { Box, styled } from "@mui/material";

const CustomDataContainer = styled(Box)({
  paddingInline: "10px",
  border: "1px solid rgb(204, 204, 204)",
  padding: "20px",
  marginLeft: "10px",
  marginRight: "5px",
  borderRadius: "6px",
  backgroundColor: "rgb(238, 241, 251)",
  marginBottom: "10px",
});

const MessageContainer = styled(Box)({
  "& p strong": {
    color: "#000000DE",
  },
  "&  p": {
    marginBlock: "10px",
    fontSize: "14px",
  },
  "& ol, &  ul": {
    listStyle: "initial",
    marginLeft: "12px",
  },
  "&  ol  li, &  ul  li": {
    marginBottom: "5px",
    fontSize: "14px",
  },
  "&  ol  li ul, &  ul  li ul": {
    listStyle: "circle",
    marginBottom: "5px",
    fontSize: "14px",
  },
  "&  h1": {
    fontSize: "16px",
  },
  "&  h2": {
    fontSize: "16px",
  },
  "&  h3": {
    fontSize: "16px",
  },
  "& table": {
    borderCollapse: "collapse",
    width: "100%",
  },
  "& table td, & table th": {
    border: "1px solid #ddd",
    padding: "8px",
  },
  "& table tr:nth-child(even)": {
    backgroundColor: "#f2f2f2",
  },
  "& table th": {
    paddingTop: "12px",
    paddingBottom: "12px",
    textAlign: "left",
  },
});

export { CustomDataContainer, MessageContainer };
