import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { ICustomData } from "../custom.interface";
import { InputAdornment, TextField } from "@mui/material";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppSelector } from "hooks";

interface CustomDataTestComponentProps {
  customData: ICustomData;
  onSubmit: (values: any) => void;
}

const CustomDataTestComponent: React.FC<CustomDataTestComponentProps> = ({
  customData,
  onSubmit,
}) => {
  const [showTest, setShowTest] = useState(false);
  const customDataFields = useAppSelector(
    (state) => state.frameworkAndContextState.customDataFieldsApiData
  );

  const placeholders =
    customData?.promptType === "fixed"
      ? []
      : extractPlaceholders(customData.query, customDataFields.variables);

  function extractPlaceholders(text, variable) {
    const matches = text.match(/\{(.*?)\}/g); // Find all occurrences inside {}
    const places: any[] = matches
      ? matches.map((match) => match.slice(1, -1))
      : []; // Remove {} and return array

    return [...new Set(places.filter((place) => variable.includes(place)))];
  }

  // Validation Schema (Optional)
  const validationSchema = Yup.object(
    placeholders.reduce((acc, key) => {
      acc[key] = Yup.string().required(`${key} is required`);
      return acc;
    }, {} as Record<string, Yup.StringSchema>)
  );

  // Generate initial values
  const initialValues = placeholders.reduce((acc, key) => {
    acc[key] = ""; // Initialize with empty string
    return acc;
  }, {} as Record<string, string>);

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    resetForm({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customData.query]);

  return (
    <div>
      {showTest && (
        <Paper sx={{ padding: "10px" }} elevation={0}>
          <form onSubmit={handleSubmit}>
            <Flex flexDirection={"column"} gap="5px">
              {placeholders.map((field: string) => (
                <TextField
                  key={field}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name={field}
                  value={values[field] ?? ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={`Enter ${field}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        <span>{field} - </span>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(errors[field] && touched[field])}
                />
              ))}
              <Flex justifyContent={"flex-end"}>
                <Button
                  type="button"
                  size="xs"
                  variant={"primary"}
                  onClick={handleSubmit}
                >
                  Test
                </Button>
              </Flex>
            </Flex>
          </form>
        </Paper>
      )}

      {!showTest && Boolean(placeholders.length) && (
        <div
          style={{
            textAlign: "right",
            width: "100%",
            display: "flex",
            justifyContent: "right",
            marginTop: "10px",
            gap: "8px",
          }}
        >
          <Button
            type="button"
            size="xs"
            variant={"primary"}
            onClick={() => {
              setShowTest(true);
            }}
          >
            Test
          </Button>
        </div>
      )}
    </div>
  );
};

export default CustomDataTestComponent;
