import React, { useEffect, useState } from "react";
import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarSection } from "components/AeSidebar/AeSidebarSection";
import { AeSidebarSectionCollapse } from "components/AeSidebar/AeSidebarSectionCollapse";
import ProfileModalData from "containers/AEReturnDashboard/ProfileModalData";
import { useAppDispatch, useAppSelector } from "hooks";
import FrameworkAndContextRightSection from "./FrameworkAndContextRightSection";
import {
  setContextId,
  setOpenContextAddPopup,
} from "./store/frameworkAndContextReducer";
import { useLocation } from "react-router-dom";

function FrameworkAndContext() {
  const profileData = useAppSelector((state) => state.profileState.profileData);
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const contextId = queryParams.get("contextId");
    const context = queryParams.get("context");
    if (contextId && context == "") {
      dispatch(setContextId(contextId));
    } else if (context == "" && !contextId) {
      dispatch(setOpenContextAddPopup(true));
    }
  }, []);

  return (
    <>
      <AeReturnJourneyLayout
        as="div"
        left={20}
        right={82}
        leftChildren={<AeSidebarSection activeTab="frameworkandcontext" />}
        leftCollapsedChildren={
          <AeSidebarSectionCollapse activeTab="frameworkandcontext" />
        }
        rightChildren={<FrameworkAndContextRightSection />}
        profileModalChild={<ProfileModalData />}
        imageUrl={profileData?.imageFile}
        value={value}
        setValue={setValue}
        openSearchModal={openModal}
        setOpenSearchModal={setOpenModal}
        pageHeading="Frameworks/Contexts"
      />
    </>
  );
}

export default FrameworkAndContext;
