import React from "react";
import {
  EditableDivContainer,
  EditableDivEndButton,
  EditableDivStartButton,
} from "./style";

import { MentionsInput, Mention } from "react-mentions";
import AIIconButton from "./AIIconButton";
// import SendIconButton from "./SendIconButton";
import TextField from "@mui/material/TextField";
import SendIconButton from "./SendIconButton";
import { ICustomData } from "./custom.interface";

interface TagEditorProps {
  value: string;
  setValue: (value: string) => void;
  variables: string[];
  disabled?: boolean;
  isDynamic?: boolean;
  handleGenerateQuery?: () => void;
  customData: ICustomData;
}

const TagEditor: React.FC<TagEditorProps> = ({
  value,
  setValue,
  variables,
  disabled,
  isDynamic,
  customData,
  handleGenerateQuery,
}) => {
  const tagList = variables;

  return (
    <>
      <EditableDivContainer disabled={disabled}>
        <EditableDivStartButton>
          <AIIconButton />
        </EditableDivStartButton>

        {disabled || !isDynamic ? (
          <TextField
            multiline
            minRows={3}
            fullWidth
            variant="standard"
            disabled={disabled}
            value={value}
            onChange={(evt) => setValue(evt.target.value)}
            placeholder="Type your prompt here…"
            InputProps={{
              disableUnderline: true,
              sx: { background: "none", border: "none", outline: "none" },
            }}
            sx={{
              "& .MuiInputBase-input": {
                border: "none",
                outline: "none",
              },
            }}
          />
        ) : (
          <MentionsInput
            placeholder="If you have many documents saved in content library use tags to pull right data from the document. Click @ to pull tag list."
            value={value}
            onChange={(evt) => setValue(evt.target.value)}
            style={{
              width: "100%",
              control: {
                backgroundColor: "#fff",
                fontSize: 14,
                fontWeight: "normal",
                width: "100%",
              },

              "&multiLine": {
                control: {
                  minHeight: 77,
                  border: "none !important",
                  outline: "none",
                },
                highlighter: {
                  padding: "9px 0px",
                  border: "none",
                },
                input: {
                  padding: "9px 0px",
                  border: "none",
                  outline: "none",
                },
              },
              "& .highlight-grey": {
                color: "grey",
              },

              suggestions: {
                maxHeight: "200px",
                overflow: "scroll",
                list: {
                  backgroundColor: "white",
                  border: "1px solid rgba(0,0,0,0.15)",
                  fontSize: 14,
                },
                item: {
                  padding: "5px 15px",
                  borderBottom: "1px solid rgba(0,0,0,0.15)",
                  "&focused": {
                    backgroundColor: "#cee4e5",
                  },
                },
              },
            }}
          >
            <Mention
              markup="@{__display__}"
              trigger="@"
              data={(tagList || []).map((tag: string) => ({
                id: tag,
                display: tag,
              }))}
              onAdd={(val) => {
                console.log("onAdd called", val);
              }}
              style={{ backgroundColor: "#cee4e5" }}
              renderSuggestion={(
                _suggestion,
                _search,
                highlightedDisplay,
                _index,
                focused
              ) => (
                <div className={`user ${focused ? "focused" : ""}`}>
                  {highlightedDisplay}
                </div>
              )}
            />
          </MentionsInput>
        )}

        <EditableDivEndButton>
          {customData?.promptType === "fixed" && (
            <SendIconButton
              // disabled={loading}
              onClick={handleGenerateQuery}
            />
          )}
        </EditableDivEndButton>
      </EditableDivContainer>
    </>
  );
};

export default TagEditor;
