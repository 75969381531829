import React, { useEffect, useMemo, useState } from "react";
import userService from "services/userService";
import { useAppSelector } from "hooks";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import * as yup from "yup";
import Select from "react-select";
import { FormikTextArea } from "@evabot/eva-react-core-library/dist/atoms/FormikTextArea/FormikTextArea";
import { ToggleButton } from "@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton";
import { uuidv4 } from "utils/cookie";
import CustomDataComponent from "./component/CustomData/CustomDataComponent";
import { ICustomData } from "./component/custom.interface";
import { toast } from "react-toastify";
import { Alert, Button as MuiButton } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const emailorlinkedinTypeOptions = [
  { value: "email", label: "Email" },
  { value: "linkedin", label: "LinkedIn" },
  { value: "callprep", label: "callPrep" },
];

interface FrameworkAndContextAddUpdateProps {
  show: boolean;
  setShow: (show?: boolean) => void;
  data?: any;
  refetchData?: () => void;
}

function FrameworkAndContextAddUpdate({
  show,
  setShow,
  data,
  refetchData,
}: FrameworkAndContextAddUpdateProps) {
  const customDataLength = 1;
  const isUpdate = Boolean(data);

  const [contextId] = useState(data?.id || uuidv4());

  const [emailorlinkedinType, setEmailorlinkedinType] = useState(
    emailorlinkedinTypeOptions
  );
  const [frameworkOrContextType, setFrameworkOrContextType] = useState(
    data?.type || ""
  );

  const [confirmationMessage, setConfirmationMessage] = useState({
    message: "",
    values: null,
  });

  const dataRepositoryFeatureEnabled = useAppSelector(
    (state) => state.frameworkAndContextState.dataRepositoryFeatureEnabled
  );
  const customDataFields = useAppSelector(
    (state) => state.frameworkAndContextState.customDataFieldsApiData
  );
  const memberListingData = useAppSelector(
    (state) => state.frameworkAndContextState.memberListingData
  );

  const contextMaxWordLimit = useAppSelector(
    (state) => state.frameworkAndContextState.contextWordMaxLimit
  );

  const selectedUserId = useAppSelector(
    (state) =>
      state.memberListingState?.tabViewState?.isTabViewMemberData?.userId
  );

  const userId = useAppSelector(
    (state) => state.profileState.profileData.userId
  );

  const [isLoading, setIsLoading] = useState(false);

  const FrameworkOrContextTypeOptions = [
    { value: "framework", label: "Framework" },
    { value: "context", label: "Context" },
  ];

  const wordCount = (text) => {
    return text ? text.split(/\s+/).length : 0;
  };

  const AddFrameworkAndContextSchema = yup.object().shape({
    name: yup
      .string()
      .required("Name is required.")
      .test(
        "unique-name",
        "Name already exists in frameworks or contexts.",
        function (value) {
          if (!value || !memberListingData) return true; // Skip validation if no data

          const nameExists = memberListingData.some((item) => {
            if (isUpdate) {
              if (
                data?.frameworkName &&
                item?.frameworkName?.toLowerCase() ===
                data?.frameworkName?.toLowerCase()
              ) {
                return false;
              }
              if (
                item?.contextName &&
                item?.contextName?.toLowerCase() ===
                data.contextName?.toLowerCase()
              )
                return false;
            }

            return (
              item.frameworkName?.toLowerCase() === value.toLowerCase() ||
              item.contextName?.toLowerCase() === value.toLowerCase()
            );
          });

          return !nameExists; // Return false if name exists (fails validation)
        }
      ),
    content: yup
      .string()
      .required("Description is required.")
      .test(
        "max-words",
        `Description must not exceed ${contextMaxWordLimit} words.`,
        (value) => value && wordCount(value) <= contextMaxWordLimit
      ),
    addType: yup.string().required("Please select a type."),
    frameworkandcontexttype: yup
      .string()
      .required("Please select a Framework/Context Type."),
    customData: yup.array().of(
      yup
        .object({
          prompt: yup
            .object({
              value: yup.string().required("Prompt value is required"),
              label: yup.string().required("Prompt label is required"),
            })
            .required("Prompt is required"),
          dataSource: yup.string().required("Data source is required"),
          document: yup
            .array()
            .of(yup.mixed()) // Adjust type if needed
            .when(["prompt.value", "dataSource"], {
              is: (promptValue, dataSource) =>
                promptValue === "New" && dataSource === "contentLibrary",
              then: (schema) =>
                schema.min(1, "At least one document is required"),
              otherwise: (schema) => schema.notRequired(),
            }),
          promptType: yup.string().required("Prompt type is required"),
          query: yup.string().required("Query is required"),
          promptName: yup.string().when("prompt.value", {
            is: "New",
            then: (schema) => schema.required("Prompt name is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
          customData: yup
            .object({
              query: yup.string().required("Query is required in custom data"),
              output: yup.string().required("Custom data is not generated"),
            })
            .test(
              "match-queries",
              "Click Send/Test button to generate custom data",
              function (customData) {
                if (!customData || !customData.query) return true; // Skip validation if empty

                const parentQuery = this.parent.query; // Get the outer query
                return customData.query === parentQuery;
              }
            ),
        })
        .when("frameworkandcontexttype", {
          is: "context",
          then: (schema: any) =>
            schema.min(1, "At least one custom data entry is required"),
          otherwise: (schema) => schema.notRequired(),
        })
    ),
  });

  function extractPlaceholders(text, variable) {
    const matches = text.match(/\{(.*?)\}/g); // Find all occurrences inside {}
    const places: any[] = matches
      ? matches.map((match) => match.slice(1, -1))
      : []; // Remove {} and return array

    return places.filter((place) => variable.includes(place));
  }

  const handleAddContextAndFramework = async (values) => {
    setIsLoading(true);
    if (isUpdate) {
      const obj = {
        id: data.objectId,
        isPublic: values.isPublic,
        userId: values.userId,
        name: values.name,
        action: "update",
        language: values?.language,
      };
      if (data.type === "framework") {
        obj["framework"] = values.content;
        obj["type"] = "framework";
        obj["frameworkId"] = data?.id;
        try {
          await userService.updateOrDeleteFramework(obj);
          toast.success(`Framework updated successfully`);
          if (refetchData) {
            refetchData();
          }
          setShow(false);
          setIsLoading(false);
        } catch (error) {
          toast.error("Error occurred while updating framework");
          console.error("Error updating member", error);
          setIsLoading(false);
        }
      } else {
        obj["context"] = values.content;
        obj["type"] = "context";
        obj["contextId"] = data?.id;

        obj["queryDetails"] = values?.customData?.map(
          (customData: ICustomData) => ({
            documents:
              customData?.document?.map((document) => document?.value) || [], // empty list for all documents otherwise list of string (array of string)
            name:
              customData?.prompt.value === "New"
                ? customData.promptName
                : customData?.prompt.value, //name of feature
            dataSource: customData.dataSource, //callTranscripts or contentLibrary
            query: customData.query,
            queryType: customData.promptType, //dynamic or fixed
            variables:
              customData.promptType === "fixed"
                ? []
                : extractPlaceholders(
                  customData.query,
                  customDataFields.variables
                ),
            tags: [],
          })
        );

        try {
          await userService.updateOrDeleteContext(obj);
          toast.success(`Context updated successfully`);
          if (refetchData) {
            refetchData();
          }
          setIsLoading(false);
          setShow(false);
        } catch (error) {
          console.error("Error updating member", error);
          setIsLoading(false);
        }
      }
      return;
    }

    try {
      const obj: any = {
        userId: selectedUserId ? selectedUserId : values.userId,
        name: values.name,
        value: values.content,
        keyType: values.addType,
        type: values.frameworkandcontexttype,
        isPublic: values.isPublic,
        contextId: contextId,
        language: values?.language,
      };
      if (values.addType === "context") {
        obj["queryDetails"] = values.customData.map(
          (customData: ICustomData) => ({
            documents: customData?.document?.find(
              (document) => document.value === "all"
            )
              ? []
              : customData?.document?.map((document) => document?.value) || [], // empty list for all documents otherwise list of string (array of string)
            name:
              customData.prompt.value === "New"
                ? customData?.promptName
                : customData?.prompt?.value, //name of feature
            dataSource: customData.dataSource, //callTranscripts or contentLibrary
            query: customData.query,
            queryType: customData.promptType, //dynamic or fixed
            variables:
              customData.promptType === "fixed"
                ? []
                : extractPlaceholders(
                  customData.query,
                  customDataFields.variables
                ),
            tags: [],
          })
        );
      }
      await userService.addMemberKey(obj);
      toast.success(`${values.addType} created successfully`);
      if (refetchData) {
        refetchData();
      }
      setFrameworkOrContextType("");
    } catch (error) {
      console.error("Error Adding Framework/Context", error);
    }
    setShow(false);
  };

  const handlePopupCancel = () => {
    setShow(false);
    setFrameworkOrContextType("");
  };

  const handleCheckFrameworkContextLink = async (values) => {
    const obj = {
      id: data.objectId,
      isPublic: data.isPublic,
      userId: data.userId,
      name: data.name,
      action: "update",
    };
    if (data.type === "framework") {
      obj["framework"] = data.content;
      obj["type"] = "framework";
      obj["frameworkId"] = data?.id;
    } else {
      obj["context"] = data.content;
      obj["type"] = "context";
      obj["contextId"] = data?.id;
    }

    try {
      const checkFrameworkContextLink =
        await userService.handleCheckFrameworkContextLink(obj);
      setConfirmationMessage({
        message: checkFrameworkContextLink?.message,
        values,
      });
    } catch (error) {
      console.error("Error updating member", error);
    }
  };

  useEffect(() => {
    if (frameworkOrContextType === "framework") {
      setEmailorlinkedinType([
        { value: "email", label: "Email" },
        { value: "callprep", label: "callPrep" },
      ]);
    } else {
      setEmailorlinkedinType(emailorlinkedinTypeOptions);
    }
  }, [frameworkOrContextType]);

  const promptOptions = useMemo(() => {
    const predefinedNames = {
      "User Gem": "UserGems",
    };
    return [{ value: "New", label: "New" }].concat(
      customDataFields?.predefinedPrompt?.map((prompt) => ({
        value: prompt.name,
        label: predefinedNames[prompt.name] || prompt.name,
      }))
    );
  }, [customDataFields]);

  const initialValues = {
    name: data?.type ? data[`${data?.type}Name`] : "",
    content: data?.type ? data[`${data?.type}Value`] : "",
    addType: data?.linkedinOremail || "",
    frameworkandcontexttype: data?.type || "",
    queryType: "",
    userId: data?.userId || userId,
    isPublic: data?.isPublic || false,
    language: data?.language || "",
    contextId,
    customData: isUpdate
      ? data?.queryDetails?.map((queryDetails: any) => ({
        prompt: promptOptions.find(
          (options) => options?.value === queryDetails?.name
        ) || { value: "New", label: "New" },
        dataSource: queryDetails.dataSource,
        document:
          queryDetails.documents?.map((document) => ({
            label: document,
            value: document,
          })) || [],
        promptType: queryDetails?.queryType,
        query: queryDetails?.query,
        promptName: queryDetails?.name,
        customData: {
          output: data?.customData?.customData || "",
          query: queryDetails?.query || "",
        },
      }))
      : [],
  };

  return (
    <>
      <ModalWrapper
        width="600px"
        height="max-content"
        hideModal={!show}
        setHideModal={() => {
          // setFrameworkOrContextType("");
          // setHideModal(true);
        }}
        bgcolor={"rgba(0, 0, 0, 0.8)"}
        padding="24px"
      >
        {!confirmationMessage?.message ? (
          <>
            <Text fontWeight="400" fontSize="24px" marginBottom={4}>
              {isUpdate ? `Update ${data?.type}` : "Add Framework/Context"}
            </Text>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                if (isUpdate) {
                  handleCheckFrameworkContextLink(values);
                } else {
                  handleAddContextAndFramework(values);
                }
              }}
              validationSchema={AddFrameworkAndContextSchema}
            >
              {({ setFieldValue, values, isSubmitting }) => {
                return (
                  <Form
                    style={{
                      maxHeight: 600,
                      overflowY: "auto",
                      scrollbarWidth: "none",
                    }}
                  >
                    <Flex flexDirection="column">
                      {!isUpdate && (
                        <>
                          <Flex
                            padding={"0 10px"}
                            marginBottom={3}
                            flexDirection="column"
                          >
                            <Text
                              variant="bodyRegular"
                              fontWeight={"600"}
                              paddingBottom={"5px"}
                            >
                              Select Type
                              <span style={{ color: "red", marginLeft: "5px" }}>
                                *
                              </span>
                            </Text>
                            <Select
                              className="single-select"
                              placeholder="Select Type"
                              name="addType"
                              options={FrameworkOrContextTypeOptions.map(
                                (option) => ({
                                  ...option,
                                  isDisabled:
                                    option.value === "framework" &&
                                    values?.frameworkandcontexttype ===
                                    "linkedin",
                                })
                              )}
                              onChange={(selectedOption) => {
                                setFrameworkOrContextType(selectedOption.value);

                                setFieldValue(
                                  "addType",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              styles={{
                                container: (
                                  provided: Record<string, unknown>
                                ) => ({
                                  ...provided,
                                  width: "100%",
                                }),
                              }}
                            />
                            <ErrorMessage
                              name="addType"
                              component="div"
                              className="custom-error-message"
                            />
                          </Flex>
                          <Flex
                            padding={"0 10px"}
                            marginBottom={3}
                            flexDirection="column"
                          >
                            <Text
                              variant="bodyRegular"
                              fontWeight={"600"}
                              paddingBottom={"5px"}
                            >
                              Select Messaging Type
                              <span style={{ color: "red", marginLeft: "5px" }}>
                                *
                              </span>
                            </Text>
                            <Select
                              className="single-select"
                              placeholder="Select Type"
                              name="frameworkandcontexttype"
                              options={emailorlinkedinType}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "frameworkandcontexttype",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              styles={{
                                control: (
                                  provided: Record<string, unknown>
                                ) => ({
                                  ...provided,
                                  width: "535px",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  width: "535px",
                                }),
                              }}
                            />
                            <ErrorMessage
                              name="frameworkandcontexttype"
                              component="div"
                              className="custom-error-message"
                            />
                          </Flex>
                        </>
                      )}
                      {frameworkOrContextType === "context" && (
                        <Flex padding={"0 10px"} flexDirection="column">
                          <Text
                            variant="bodyRegular"
                            fontWeight={"600"}
                            paddingBottom={"5px"}
                          >
                            Type Language
                            <span
                              style={{ color: "#a4a4a4", marginLeft: "5px" }}
                            >
                              (Optional)
                            </span>
                          </Text>
                          <FormikInput
                            name="language"
                            placeholder="Type the language of your choice here. Eg: German"
                            fullSize
                          />
                        </Flex>
                      )}

                      <Flex
                        padding={"0 10px"}
                        marginBottom={3}
                        flexDirection="column"
                      >
                        <Text
                          variant="bodyRegular"
                          fontWeight={"600"}
                          paddingBottom={"5px"}
                        >
                          Name
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *
                          </span>
                        </Text>
                        <FormikInput name="name" placeholder="Name" fullSize />
                      </Flex>
                      <Flex
                        padding={"0 10px"}
                        marginBottom={3}
                        marginTop={"-22px"}
                        flexDirection="column"
                      >
                        <Text
                          variant="bodyRegular"
                          fontWeight={"600"}
                          paddingBottom={"5px"}
                        >
                          Description
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *
                          </span>
                        </Text>
                        <FormikTextArea
                          marginBottom={0}
                          name="content"
                          placeholder="description"
                          width={"95%"}
                        />
                      </Flex>

                      {dataRepositoryFeatureEnabled &&
                        frameworkOrContextType === "context" && (
                          <>
                            <FieldArray
                              name="customData"
                              render={({ push, remove }) => {
                                return (
                                  <>
                                    {!!values?.customData?.length && (
                                      <Text
                                        variant="heading"
                                        fontWeight="600"
                                        marginBottom={2}
                                        ml={"10px"}
                                      >
                                        Prompted Data
                                      </Text>
                                    )}
                                    {values?.customData?.map(
                                      (customData, i) => {
                                        return (
                                          <CustomDataComponent
                                            index={i}
                                            customData={customData}
                                            showRemoveButton={
                                              values?.customData?.length > 1
                                            }
                                            handleRemove={() => {
                                              remove(i);
                                            }}
                                            isUpdate={isUpdate}
                                          />
                                        );
                                      }
                                    )}
                                    {values?.customData?.length <
                                      customDataLength && (
                                        <Flex
                                          my={"10px"}
                                          justifyContent={"flex-end"}
                                        >
                                          <MuiButton
                                            variant="contained"
                                            size="small"
                                            disableElevation
                                            onClick={
                                              () =>
                                                push({
                                                  prompt: {
                                                    value: "New",
                                                    label: "New",
                                                  },
                                                  dataSource: "",
                                                  document: [],
                                                  promptType: "",
                                                  query: "",
                                                  promptName: "",
                                                })
                                              // {
                                            }
                                          >
                                            Add Custom Data
                                          </MuiButton>
                                        </Flex>
                                      )}
                                  </>
                                );
                              }}
                            ></FieldArray>
                          </>
                        )}
                      {frameworkOrContextType && (
                        <Flex
                          padding={"3px 10px"}
                          flexDirection="row"
                          alignItems={"center"}
                          gap="5px"
                        >
                          <Text
                            variant="bodyRegular"
                            fontWeight={"600"}
                            fontSize={"12px"}
                            paddingBottom={"5px"}
                          >
                            Make this {frameworkOrContextType} visible to the
                            team
                          </Text>
                          <ToggleButton
                            smallSize
                            variant="tertiary"
                            value={values.isPublic}
                            name="isActive"
                            onChange={(value) => {
                              setFieldValue("isPublic", value);
                            }}
                          />
                        </Flex>
                      )}
                      <Flex marginRight={1} marginTop={3} padding={"5px 10px"}>
                        <Flex justifyContent={"left"}>
                          <Button
                            disabled={
                              // !(isValid && dirty) ||
                              isLoading || isSubmitting
                            }
                            type="submit"
                          >
                            {isUpdate ? "Update" : "Save"}
                          </Button>
                        </Flex>
                        <Flex justifyContent={"right"}>
                          <Button
                            variant="secondary"
                            type="button"
                            onClick={() => {
                              handlePopupCancel();
                            }}
                            marginLeft={2}
                          >
                            Cancel
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Form>
                );
              }}
            </Formik>
          </>
        ) : (
          <>
            <Alert
              icon={<WarningAmberIcon />}
              severity="error"
              style={{ borderRadius: "8px", marginTop: "10px" }}
            >
              {confirmationMessage?.message}
            </Alert>

            <Flex justifyContent={"flex-end"}>
              <Flex marginRight={1} marginTop={3} padding={"5px 10px"}>
                <Flex justifyContent={"left"}>
                  <Button
                    disabled={isLoading}
                    onClick={() => {
                      handleAddContextAndFramework(confirmationMessage.values);
                    }}
                  >
                    {"Confirm"}
                  </Button>
                </Flex>
                <Flex justifyContent={"right"}>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => {
                      setConfirmationMessage({ message: "", values: "" });
                    }}
                    marginLeft={2}
                  >
                    Cancel
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </ModalWrapper>
    </>
  );
}

export default FrameworkAndContextAddUpdate;
