import React from 'react';
import ProfileEditIcon from '../component/ProfileEditIcon';

interface EditButtonProps {
    onClick?: (e?: any) => any | void;
}

const EditButton: React.FC<EditButtonProps> = ({ onClick }) => {
    return (
        <button
            style={{
                color: '#0094E3',
                display: 'flex',
                padding: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                borderRadius: '4px',
                border: '0.6px solid var(--Colours-Eva-EvaBlue, #0094E3)',
                background: '#FFF',
                cursor: 'pointer' 
            }}
            onClick={onClick}
        >
            {/* Edit */}
            <ProfileEditIcon  sx={{ 
                    fontSize: '24px'
                }}  /> 
        </button>
    );
}

export default EditButton;
