import FrameworkAndContextTable from "./FrameworkAndContextTable";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";

function FrameworkAndContextContent() {
  return (
    <Flex flexDirection={"column"} padding="25px 32px" overflowY={"scroll"}>
      <FrameworkAndContextTable tabView={false} />
    </Flex>
  );
}

export default FrameworkAndContextContent;
