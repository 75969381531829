import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
function ManageUseCaseHeader() {
    return (
        <>
            <Flex
                padding={"10px 15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={"#F8F8F8"}
                alignItems={"center"}
                borderTopLeftRadius={"8px"}
                borderTopRightRadius={"8px"}
              >
                <Flex padding={"0 5px"} width="5%" >
                    #
                </Flex>
                <Flex padding={"0 5px"} width="20%" >
                    Product Pitch Name
                </Flex>
                <Flex padding={"0 5px"} width="25%" >
                    Updated By
                </Flex>
                <Flex padding={"0 5px"} width="25%" >
                    Updated On
                </Flex>
                <Flex padding={"0 5px"} width="25%">
                    Actions
                </Flex>
            </Flex>

        </>
    );
}

export default ManageUseCaseHeader;