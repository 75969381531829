import React, { useState } from "react";
import { TextField, Box, Typography, Chip, Tooltip } from "@mui/material";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        border: '1px solid #ccc',
        borderRadius: '4px',
        resize: 'vertical',
        overflow: 'auto',
        boxSizing: 'border-box',
        paddingLeft: '10px',
        paddingTop: '10px',
        alignItems: 'flex-start',
        minHeight: '80px',
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        borderBottom: 'none',
    },
    marginTop: '-8px',
});

function AddCustomDataComponent({ onBack, onSave, editData = null, pdfDataPreview = false, setFileName, setFileDescription, dataRepositoryOriginalList }) {

    const cleanTags = (tags) => {
        const lowerCaseTags = tags.map(tag => tag.toLowerCase());
        return [...new Set(lowerCaseTags)];
    };

    const [title, setTitle] = useState(editData?.dataRepository?.[0]?.title || "");
    const [tagInput, setTagInput] = useState("");
    const [tags, setTags] = useState(cleanTags(editData?.dataRepository?.[0]?.tags || []));
    const [data, setData] = useState(editData?.dataRepository?.[0]?.rawData || "");
    const [manualFileName, setManualFileName] = useState(editData?.fileName || "");
    const [manualFileDescription, setManualFileDescription] = useState(editData?.fileDescription || "");
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [errors, setErrors] = useState({
        data: false,
        fileName: false
    });

    const handleTagAdd = () => {
        const trimmedTag = tagInput.trim().toLowerCase();
        if (trimmedTag && !tags.includes(trimmedTag)) {
            setTags((prevTags) => [...prevTags, trimmedTag]);
            setTagInput("");
        } else {
            setSnackbarMessage('Tag already exists');
            setSnackbarType('error');
            setShowSnackBar(true);
        }
    };

    const handleTagDelete = (tagToDelete) => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
    };

    const validateFields = () => {
        const newErrors = {
            data: !data,
            fileName: !manualFileName
        };
        setErrors(newErrors);
        return !(newErrors.fileName || newErrors.data);
    };

    const handleSave = () => {
        if (!validateFields()) {
            return;
        }

        const isFileNameDuplicate = dataRepositoryOriginalList.some(
            (repo) => repo?.fileName?.toLowerCase() === manualFileName?.toLowerCase()
        );

        if (isFileNameDuplicate) {
            setSnackbarMessage('File name already exist');
            setSnackbarType('error');
            setShowSnackBar(true);
            return;
        }

        const customDataObject = {
            title: title,
            tags: tags,
            rawData: data,
            repoId: editData?.dataRepository?.[0]?.repoId || null,
            contentId: editData?.contentId || null,
            fileName: manualFileName,
            fileDescription: manualFileDescription
        };

        onSave([customDataObject]);
    };

    return (
        <Box>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            <Box marginBottom={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"} justifyItems={"center"} >
                <Typography variant="h6">
                    {pdfDataPreview ? "Preview Data" : editData ? "Edit Custom Data" : "Custom Data Setup"}
                </Typography>
                <Button size="xs" variant="secondary" onClick={onBack} iconName="arrowLeft">
                    Back
                </Button>
            </Box>
            <Box sx={{ backgroundColor: 'white', borderRadius: 2, padding: 2, boxShadow: 1, paddingY: 2, paddingBottom: "40px" }}>
                <Box display="flex" flexDirection="column" gap={2}>
                    <label>File Name<span style={{ "color": "red" }}>*</span></label>
                    <TextField
                        style={{ marginTop: "-8px" }}
                        placeholder="File Name"
                        variant="outlined"
                        value={manualFileName}
                        onChange={(e) => { setFileName(e.target.value); setManualFileName(e.target.value) }}
                        fullWidth
                        error={errors.fileName}
                        helperText={errors.fileName ? "File name is required" : ""}
                    // disabled={editData ? true : false}
                    />
                    <label>File Description (optional)</label>
                    <TextField
                        style={{ marginTop: "-8px" }}
                        placeholder="File Description"
                        variant="outlined"
                        value={manualFileDescription}
                        onChange={(e) => { setFileDescription(e.target.value); setManualFileDescription(e.target.value) }}
                        fullWidth
                    />
                    <label>Title</label>
                    <TextField
                        style={{ marginTop: "-8px" }}
                        placeholder="Input Custom Data title"
                        variant="outlined"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                    // error={errors.title}
                    // helperText={errors.title ? "Title is required" : ""}
                    />
                    {/* <label>Tags*</label>
                    <Tooltip
                        style={{ marginTop: "-10px", marginBottom:"3px" }}
                        title="Add Tag and then press enter"
                        placement="top"
                        arrow
                    >
                        <TextField
                            value={tagInput}
                            placeholder="Add Tag and then press enter"
                            onChange={(e) => setTagInput(e.target.value)}
                            onKeyPress={(e) => e.key === "Enter" && handleTagAdd()}
                            fullWidth
                            multiline
                            margin="normal"
                            error={errors.tags}
                            helperText={errors.tags ? "At least one tag is required" : ""}
                            sx={{
                                "& .MuiInputBase-root": {
                                    display: "block",
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: "5px",
                                            marginRight: "8px",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        {tags.map((tag, index) => (
                                            <Chip
                                                key={index}
                                                label={String(tag)}
                                                onDelete={() => handleTagDelete(tag)}
                                                style={{
                                                    border: "1px solid rgb(0, 148, 227)",
                                                    color: "rgb(0, 148, 227)",
                                                    backgroundColor: "white",
                                                    fontSize: "12px",
                                                }}
                                                sx={{
                                                    "& .MuiChip-deleteIcon": {
                                                        display: "none",
                                                    },
                                                    "&:hover .MuiChip-deleteIcon": {
                                                        display: "inline",
                                                        color: "white",
                                                    },
                                                    "&:hover": {
                                                        backgroundColor: "rgb(0, 148, 227) !important",
                                                        color: "white !important",
                                                        borderColor: "transparent",
                                                    },
                                                }}
                                            />
                                        ))}
                                    </Box>
                                ),
                            }}
                        />
                    </Tooltip> */}
                    <label style={{ padding: "0", margin: "0" }}>Data<span style={{ "color": "red" }}>*</span></label>
                    <CustomTextField
                        InputProps={{
                            disableUnderline: true
                        }}
                        placeholder="Input Custom Data"
                        variant="standard"
                        multiline
                        minRows={4}
                        value={data}
                        onChange={(e) => setData(e.target.value)}
                        fullWidth
                        error={Boolean(errors.data)}
                        helperText={errors.data ? "Data is required" : ""}
                    />
                    <Button
                        size="xs"
                        variant="primary"
                        fontSize={"14px"}
                        padding={"10px 50px"}
                        onClick={handleSave}
                        top={20}
                        alignSelf={"center"}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default AddCustomDataComponent;
