import React, { useEffect, useRef, useState, useCallback } from "react";
import IconButton from "@mui/material/IconButton";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import * as XLSX from "xlsx";
import DeleteIcon from "assets/icons/DeleteIcon";
import { Backdrop, Skeleton } from "@mui/material";
import Select from 'react-select';
import { ErrorMessage, Formik, Form } from "formik";
import * as yup from "yup";
import userService from "services/userService";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import TextField from '@mui/material/TextField';
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

const BATCH_SIZE = 1000;
const PAGE_SIZE = 30;
const columnHeadings = ["Email Address", "LinkedIn URL"];

const validationSchema = yup.object().shape({
  user: yup.string().required("user is required"),
  tagName: yup.string().required('Please enter a tag name'),
});

// Skeleton loader component
const RowSkeleton = () => (
  <Flex
    alignItems="center"
    justifyContent="space-between"
    padding="15px"
    width="100%"
  >
    <Flex width="10%">
      <Skeleton width={30} height={20} />
    </Flex>
    <Flex width="80%" justifyContent="space-between">
      <Flex padding="0 5px" width="50%" alignItems="center">
        <Skeleton width="90%" height={20} />
      </Flex>
      <Flex padding="0 5px" width="50%" alignItems="center">
        <Skeleton width="90%" height={20} />
      </Flex>
    </Flex>
    <Flex width="10%">
      <Skeleton width={30} height={30} variant="circular" />
    </Flex>
  </Flex>
);

const UploadModal = ({ isOpen, onClose, handleListUpload }) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [showDataPopup, setShowDataPopup] = useState(false);
  const [userOption, setUserOption] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const inputRef = useRef(null);
  const observerRef = useRef(null);
  const tableRef = useRef(null);

  const loadMoreData = useCallback(() => {
    if (isLoadingMore || !hasMore) return;

    setIsLoadingMore(true);
    const filteredData = searchTerm
      ? fileData.filter(row =>
          (row[0]?.toLowerCase().includes(searchTerm.toLowerCase()) ||
           row[1]?.toLowerCase().includes(searchTerm.toLowerCase()))
        )
      : fileData;

    const start = page * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    const newItems = filteredData.slice(start, end);

    if (newItems.length > 0) {
      setDisplayData(prev => [...prev, ...newItems]);
      setPage(prev => prev + 1);
    } else {
      setHasMore(false);
    }
    setIsLoadingMore(false);
  }, [page, fileData, searchTerm, isLoadingMore, hasMore]);

  // Intersection Observer setup
  useEffect(() => {
    const options = {
      root: tableRef.current,
      rootMargin: '20px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        loadMoreData();
      }
    }, options);

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => observer.disconnect();
  }, [loadMoreData]);

  // Reset display data when search term changes
  useEffect(() => {
    setDisplayData([]);
    setPage(0);
    setHasMore(true);
  }, [searchTerm]);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);
  };

  const fetchMemberList = async () => {
    try {
        userService.getAllMember()
            .then((response) => {
                const adminMemberList = response.data
                    .filter(item => item.organizationId)
                    .map(({ userId, firstName, lastName, email }) => ({
                        value: userId,
                        label: `${firstName} ${lastName} <${email}>`,
                    }));
                setUserOption(adminMemberList);


            })
    }
    catch (error) {
        console.error("Error fetching company list", error);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      handleUpload();
    }
  }, [selectedFile]);

  useEffect(() => {
    fetchMemberList();
  }, []);

  const processDataInBatches = async (data) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const linkedinUrlRegex = /^https?:\/\/(www\.)?linkedin\.com\/(in|pub|company|school)\/[\p{L}\d_-]+(\/)?(\?.*)?$/u;
    
    const processedData = [];
    
    for (let i = 1; i < data.length; i += BATCH_SIZE) {
      const batch = data.slice(i, Math.min(i + BATCH_SIZE, data.length));
      const validRows = batch.filter(row => {
        const email = row[0]?.trim();
        const linkedinUrl = row[1]?.trim();
        return emailRegex.test(email) || linkedinUrlRegex.test(linkedinUrl);
      });
      processedData.push(...validRows);
      
      await new Promise(resolve => setTimeout(resolve, 0));
    }
    
    return processedData;
  };

  const handleUpload = async () => {
    setLoading(true);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const content = e.target?.result?.toString() || "";
          const extension = selectedFile.name.split(".").pop()?.toLowerCase();
          let data = [];

          if (extension === "csv") {
            const rows = content.split(/\r?\n/);
            data = rows.map(row => row.split(","));
          } else if (extension === "xlsx" || extension === "xls") {
            const workbook = XLSX.read(content, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          }

          const processedData = await processDataInBatches(data);
          setFileData(processedData);
          setDisplayData([]);
          setPage(0);
          setHasMore(true);
          setShowDataPopup(true);
          setSelectedFile(null);
          onClose();
        } catch (error) {
          console.error("Error processing file:", error);
        } finally {
          setLoading(false);
        }
      };

      if (selectedFile.name.endsWith(".xlsx") || selectedFile.name.endsWith(".xls")) {
        reader.readAsBinaryString(selectedFile);
      } else {
        reader.readAsText(selectedFile);
      }
    }
  };

  const handleDeleteRow = (index) => {
    const updatedFileData = [...fileData];
    updatedFileData.splice(index, 1);
    setFileData(updatedFileData);
    setDisplayData([]);
    setPage(0);
    setHasMore(true);
  };

  const handleDataPopupClose = () => {
    setFileData([]);
    setDisplayData([]);
    setShowDataPopup(false);
    setPage(0);
    setSearchTerm("");
    setHasMore(true);
  };

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
          open={true}
        >
          <MemoRevenoidLoader />
        </Backdrop>
      )}
      <ModalWrapper
        width="max-content"
        height="max-content"
        hideModal={!isOpen}
        setHideModal={onClose}
        bgcolor={"rgba(0, 0, 0, 0.8)"}
        padding="18px"
      >
        {/* First modal content remains the same */}
        <Flex flexDirection="column" gap="10px">
          <Text fontSize="20px" fontWeight="700" textAlign={"center"}>
            Upload the List
          </Text>

          <Flex
            gap="20px"
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"20px"}
          >
            <Button
              size="s"
              variant="primary"
              borderRadius={"15px"}
              onClick={() => inputRef.current.click()}
            >
              Upload
            </Button>
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={inputRef}
            />

            <Button
              size="s"
              variant="secondary"
              borderRadius={"15px"}
              onClick={onClose}
            >
              Close
            </Button>
          </Flex>
          <Text fontSize="12px" fontWeight="300" textAlign={"center"}>
            {selectedFile ? (
              <p>Selected file: {selectedFile.name}</p>
            ) : (
              <p>No file selected</p>
            )}
          </Text>
        </Flex>
      </ModalWrapper>

      {showDataPopup && (
        <ModalWrapper
          width="auto"
          height="auto"
          hideModal={!showDataPopup}
          setHideModal={handleDataPopupClose}
          bgcolor={"rgba(0, 0, 0, 0.8)"}
          padding="48px"
        >
          <Formik
            enableReinitialize
            initialValues={{user: "", tagName: ""}}
            onSubmit={(values) => {
              const selectedUser = userOption.find((user) => user.value === values.user);
              const parts = selectedUser.label.split(/<|>/);
              const ownerName = parts[0].trim();
              const ownerEmail = parts[1].trim();
              handleListUpload(values, fileData, ownerName, ownerEmail);
              setShowDataPopup(false);
            }}
            validationSchema={validationSchema}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <Flex flexDirection="column" gap="15px">
                  <Flex alignItems={"left"}>
                    <Box width={"auto"}>
                      <Select
                        className="single-select"
                        placeholder="Select User"
                        name="user"
                        options={userOption}
                        onChange={(user) => setFieldValue("user", user ? user.value : null)}
                      />
                      <ErrorMessage
                        name="user"
                        component="div"
                        className="custom-error-message"
                      />
                    </Box>
                    <Box marginLeft={"10px"} width={"auto"}>
                      <FormikInput
                        height={"50px"}
                        padding={"0 12px"}
                        placeholder="Enter a tag name"
                        name="tagName"
                        id="tagName"
                      />
                    </Box>
                  </Flex>

                  <div ref={tableRef} style={{ maxHeight: '50vh', overflow: 'auto' }}>
                    <Flex 
                      alignItems="center" 
                      justifyContent="space-between" 
                      width="100%"
                      style={{ 
                        top: 0, 
                        backgroundColor: '#fff', 
                        padding: '10px 0',
                        borderBottom: '1px solid #eee'
                      }}
                    >
                      <Flex width="10%">
                        <Text>#</Text>
                      </Flex>
                      <Flex width="80%" justifyContent="space-between">
                        {columnHeadings.map((heading, columnIndex) => (
                          <Flex
                            key={columnIndex}
                            padding="0 5px"
                            textWrap="balance"
                            width="50%"
                          >   
                            <Text fontWeight={400}>
                              {heading}
                            </Text>
                          </Flex>   
                        ))}
                      </Flex>
                      <Flex width="10%">
                        <Text>Action</Text>
                      </Flex>
                    </Flex>

                    <div style={{ 
                      overflow: 'auto', 
                      border: '1px solid lightblue', 
                      borderRadius: '10px',
                      backgroundColor: '#fff'
                    }}>    
                      {displayData.map((rowData, rowIndex) => (
                        <Flex
                          key={rowIndex}
                          alignItems="center"
                          justifyContent="space-between"
                          padding="15px"
                          fontSize="12px"
                          fontWeight={500}
                          width="100%"
                          backgroundColor={rowIndex % 2 === 0 ? "#FFF" : "rgb(249, 249, 249)"}
                        > 
                          <Flex width="10%">
                            <Text>{rowIndex + 1}</Text>
                          </Flex>
                          <Flex width="80%" justifyContent="space-between">
                            <Flex padding="0 5px" textWrap="balance" width="50%" alignItems="center">
                              <Text wordBreak="break-word">{rowData[0] || ""}</Text>
                            </Flex>
                            <Flex padding="0 5px" textWrap="balance" width="50%" alignItems="center">
                              <Text wordBreak='break-word'>{rowData[1] || ""}</Text>
                            </Flex>
                          </Flex> 
                          <Flex width="10%">
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() => handleDeleteRow(rowIndex)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Flex>  
                        </Flex>
                      ))}

                      {/* Skeleton loader for loading more data */}
                      {isLoadingMore && hasMore && (
                        <>
                          <RowSkeleton />
                          <RowSkeleton />
                          <RowSkeleton />
                        </>
                      )}

                      {/* Intersection Observer target */}
                      {hasMore && (
                        <div
                          ref={observerRef}
                          style={{ height: '20px', width: '100%' }}
                        />
                      )}

                      {/* No results message */}
                      {displayData.length === 0 && !isLoadingMore && (
                        <Flex 
                          justifyContent="center" 
                          alignItems="center" 
                          padding="20px"
                        >
                          <Text>No records found</Text>
                        </Flex>
                      )}
                    </div>
                  </div>

                  <Flex justifyContent={"right"} alignItems={"center"} marginTop={3}>
                    <Button
                      size="s"
                      variant="primary"
                      borderRadius={"15px"}
                      type="submit"
                      marginRight={2}
                    >
                      Upload list
                    </Button>
                    <Button
                      size="s"
                      variant="secondary"
                      borderRadius={"15px"}
                      onClick={handleDataPopupClose}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalWrapper>
      )}
    </>
  );
};

export default UploadModal;