import React, { useState } from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import { axiosHelperCore } from 'utils';

interface DownloadButtonProps {
    initialUrl: string;
    userId?: string;
    organizationId?: string;
    contentId?: string;
    fileName?: string;
    setLoadingLoader?: any;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ initialUrl, userId, organizationId, contentId, fileName, setLoadingLoader }) => {
    const [url, setUrl] = useState(initialUrl);

    const fetchUrl = async () => {
        try {
            const obj = {
                userId: userId,
                fileName: `${fileName}_${organizationId}`,
                contentId: contentId
            };
            const response = await axiosHelperCore({
                url: `rapport/user/getFileUrl`,
                method: "POST",
                JSONData: obj,
            });
            return response?.data?.url;
        } catch (error) {
            console.error('Error fetching the URL:', error);
            return null;
        }
    };

    const handleButtonClick = async () => {
        setLoadingLoader(true);
        const fetchedUrl = await fetchUrl();
        setLoadingLoader(false);
        if (fetchedUrl) {
            setUrl(fetchedUrl);
            window.open(fetchedUrl, '_blank');
        }
    };

    return (
        <button
            style={{
                color: '#0094E3',
                display: 'flex',
                padding: '6px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                borderRadius: '4px',
                border: '0.6px solid var(--Colours-Eva-EvaBlue, #0094E3)',
                background: '#FFF',
                cursor: 'pointer'
            }}
            onClick={handleButtonClick}
        >
            <GetAppIcon sx={{ fontSize: "18px", color: 'transparent', stroke: '#0094E3', strokeWidth: 1 }} />
        </button>
    );
};

export default DownloadButton;
