import React, { useState, CSSProperties, useEffect } from 'react';
import { TextField, Select, MenuItem, IconButton, FormControl, InputLabel, Autocomplete, Box, Typography, InputAdornment, Tooltip } from '@mui/material';
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { ToggleButton } from '@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Link } from 'react-router-dom';
import { uuidv4 } from "utils/cookie";
import { axiosHelperCore } from 'utils';
import { useAppSelector } from 'hooks';
import { useSalesforceFields } from 'utility/salesForce';
import InfoIcon from '@mui/icons-material/Info';

const linkedinOptions = [
    { value: 'linkedinConnectionRequest', label: 'LinkedIn Connection Request' },
    { value: 'linkedinDirectMessage', label: 'LinkedIn Direct Message' },
    { value: 'linkedinInMail', label: 'LinkedIn InMail' }
];
const callPrepOptions = [{ value: 'call', label: 'Phone' }];


const SavedSalesloftSequenceStepList = ({ sequenceStepList, emailStepOptions, followUpOptions, sequenceId, frameworkList, defaultEmail, defaultLinkedin, sequenceName, toneList, wordCountList, contextList, onBack, handleSequenceSteps, integrationType, selectedManualSequence, autoPersonaSelectionSetting, pipelinerField }) => {
    const selectedClass = "bg-[#0094E3] px-3 py-2 border rounded-[30px] border-gray-200 text-[#fff] text-[13px]";
    const notSelectedClass = "bg-[white] px-3 py-2 border rounded-[30px] border-gray-200 text-[#000] text-[13px]";

    const [followUpOptionsState, setFollowUpOptionsState] = useState(followUpOptions);
    const [isDeleteConfirmationShow, setIsDeleteConfirmationShow] = useState(false);
    const [emailOptions, setEmailOptions] = useState(emailStepOptions);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [sequenceSteps, setSequenceSteps] = useState(sequenceStepList);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isActivated, setIsActivated] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorDetail, setErrorDetail] = useState('');
    const [showError, setShowError] = useState(false);

    const [dataSource, setDataSource] = useState(selectedManualSequence?.dataSource);

    const loggedInUserDetail = useAppSelector((state) => state.profileState.profileData);

    const [fields, setFields] = useState(pipelinerField);

    // validate sequence form
    const validateSteps = () => {
        const fieldValues = new Set();
        const emailValues = new Set();
        const followUpValues = new Set();
        const stepTypeValues = new Set();  // New Set to track unique step types
        let errorDetails = '';
    
        for (const stepId in sequenceSteps) {
            const stepData = sequenceSteps[stepId];
    
            if (stepData.activated) {
    
                // Check if stepType exists
                if (!stepData.stepType) {
                    errorDetails = "Please select step type.";
                    const element = "id_" + stepId;
                    return { element, errorDetails };
                }
    
                // Check for duplicate step types
                if (stepTypeValues.has(stepData.stepType)) {
                    errorDetails = `Step type "${stepData.stepType}" appears multiple times.`;
                    const element = "id_" + stepId;
                    return { element, errorDetails };
                }
                stepTypeValues.add(stepData.stepType);
    
                // Email validations
                if (stepData.stepType.includes('email')) {
                    if (!stepData.customFields.emailSubject || !stepData.customFields.emailBody) {
                        errorDetails = "";
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                    if (emailValues.has(stepData.stepType)) {
                        errorDetails = `Step "${stepData.stepName}" has duplicate email type "${stepData.stepType}".`;
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                    emailValues.add(stepData.stepType);
                }
    
                // Follow-up validations and presence of corresponding email step
                if (stepData.stepType.includes('followUp')) {
                    if (!stepData.customFields.emailBody) {
                        errorDetails = "";
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
    
                    const correspondingEmailStep = `email-${stepData.stepType.split('-')[1]}`;
                    if (!emailValues.has(correspondingEmailStep)) {
                        errorDetails = `Corresponding email step "${correspondingEmailStep}" must be present before "${stepData.stepName}".`;
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
    
                    if (followUpValues.has(stepData.stepType)) {
                        errorDetails = `Step "${stepData.stepName}" has duplicate follow-up type "${stepData.stepType}".`;
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                    followUpValues.add(stepData.stepType);
                }
    
                // LinkedIn InMail validations
                if (stepData.stepType.includes('linkedinInMail')) {
                    if (!stepData.customFields.linkedinSubject || !stepData.customFields.linkedinBody) {
                        errorDetails = "";
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                }
    
                // LinkedIn Direct Message and Connection Request name validation
                if (stepData.stepName.includes('Connection Request') && stepData.stepType !== 'linkedinConnectionRequest') {
                    errorDetails = `Step "${stepData.stepName}" must have type "LinkedIn Connection Request".`;
                    const element = "id_" + stepId;
                    return { element, errorDetails };
                }
    
                if (stepData.stepName.includes('Send Message') && stepData.stepType !== 'linkedinDirectMessage') {
                    errorDetails = `Step "${stepData.stepName}" must have type "LinkedIn Direct Message".`;
                    const element = "id_" + stepId;
                    return { element, errorDetails };
                }
    
                if (stepData.stepType.includes('linkedinDirectMessage') || stepData.stepType.includes('linkedinConnectionRequest')) {
                    if (!stepData.customFields.linkedinBody) {
                        errorDetails = "";
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                }
    
                if (stepData.stepType.includes('call')) {
                    if (!stepData.customFields.callprepBody) {
                        errorDetails = "";
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                }
    
                // Check for duplicate custom field values
                const fieldsToCheck = [
                    stepData.customFields.emailSubject?.trim(),
                    stepData.customFields.emailBody?.trim(),
                    stepData.customFields.linkedinBody?.trim(),
                    stepData.customFields.linkedinSubject?.trim(),
                    stepData.customFields.callprepBody?.trim()
                ];
    
                for (const field of fieldsToCheck) {
                    if (field && fieldValues.has(field)) {
                        errorDetails = `Step "${stepData.stepName}" - Similar custom field name used in same or other step. Please check.`;
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                    if (field) fieldValues.add(field);
                }
            }
        }
    
        // Return null if no errors found
        return null;
    };
    

    const inputDisabled = (index: number): CSSProperties => {
        return {
            opacity: sequenceSteps[index]?.activated ? 1 : 0.5,
            pointerEvents: sequenceSteps[index]?.activated ? 'auto' : 'none' as 'none',
        };
    };

    // Handle step change (custom fields, step type etc.)
    const handleStepChange = (index, field, value) => {
        const updatedSteps = [...sequenceSteps];
        updatedSteps[index][field] = value;

        if (field === 'framework' && value === '') {
            updatedSteps[index]['tone'] = toneList.find(tone => tone?.value === defaultEmail?.tone).toneId;
        }

        // If an email option is selected, expose the next follow-up option
        if (field === 'stepType') {
            updatedSteps[index]['customFields'] = {};
            if (value.includes('email')) {
                const checkIndex = value.split("-");
                // updatedSteps[index]['stepName'] = `Step ${index + 1} (Email ${checkIndex[1]})`;
                updatedSteps[index]['framework'] = defaultEmail?.frameworkId;
                updatedSteps[index]['tone'] = '';
                updatedSteps[index]['wordCount'] = wordCountList.find(wordLimit => wordLimit?.value === defaultEmail?.wordLimit).wordId;
            }

            if (value.includes('followUp')) {
                const checkIndex = value.split("-");
                // updatedSteps[index]['stepName'] = `Step ${index + 1} (FollowUp Email ${checkIndex[1]})`;
                updatedSteps[index]['framework'] = '';
                updatedSteps[index]['wordCount'] = '';
                updatedSteps[index]['tone'] = '';
            }

            if (value === 'linkedinDirectMessage') {
                // updatedSteps[index]['stepName'] = `Step ${index + 1} (${linkedinOptions.find(option => option.value === value)?.label})`;
                updatedSteps[index]['framework'] = '';
                updatedSteps[index]['wordCount'] = wordCountList.find(wordLimit => wordLimit?.value === defaultLinkedin?.linkedinDirectMessage?.wordLimit).wordId;
                updatedSteps[index]['tone'] = toneList.find(tone => tone?.value === defaultLinkedin?.linkedinDirectMessage?.tone).toneId;
            }

            if (value === 'linkedinConnectionRequest') {
                // updatedSteps[index]['stepName'] = `Step ${index + 1} (${linkedinOptions.find(option => option.value === value)?.label})`;
                updatedSteps[index]['framework'] = '';
                updatedSteps[index]['tone'] = toneList.find(tone => tone?.value === defaultLinkedin?.linkedinConnectionRequest?.tone).toneId;
            }

            if (value === 'call') {
                // updatedSteps[index]['stepName'] = `Step ${index + 1} (${callPrepOptions.find(option => option.value === value)?.label})`;
                updatedSteps[index]['framework'] = frameworkList.find(fw => fw.name === "DEFAULT")?.frameworkId;
                updatedSteps[index]['tone'] = "";
            }

            if (value === 'linkedinInMail') {
                // updatedSteps[index]['stepName'] = `Step ${index + 1} (${linkedinOptions.find(option => option.value === value)?.label})`;
                updatedSteps[index]['framework'] = defaultEmail?.frameworkId;
                updatedSteps[index]['wordCount'] = wordCountList.find(wordLimit => wordLimit?.value === defaultEmail?.wordLimit).wordId;
            }
        }

        setSequenceSteps(updatedSteps);
    };

    // Handle form changes for custom fields
    const handleCustomFieldChange = (index, field, value) => {
        const updatedSteps = [...sequenceSteps];
        updatedSteps[index].customFields[field] = value;
        setSequenceSteps(updatedSteps);
    };

    // const onDeleteSetting = async () => {
    //     const updatedSteps = [...sequenceSteps];
    //     updatedSteps[selectedIndex].activated = !updatedSteps[selectedIndex]?.activated;
    //     setIsDeleteConfirmationShow(false);
    //     setSequenceSteps(updatedSteps);
    // }

    const onDeleteSetting = async () => {
        const result: any = await axiosHelperCore({
            url: `rapport/sequence/updateSequenceActivateSetting`,
            method: "POST",
            JSONData: { userId: loggedInUserDetail?.userId, sequenceId: sequenceId, stepId: sequenceSteps[selectedIndex]?.stepId, activated: !isActivated, activityType: "stepEdit" }
        });

        if (result?.data?.data) {
            const updatedSteps = [...sequenceSteps];
            updatedSteps[selectedIndex].activated = !updatedSteps[selectedIndex]?.activated;
            setSequenceSteps(updatedSteps);
        }
        setIsDeleteConfirmationShow(false);
    }

    const handleSubmitSequence = () => {
        setIsSubmitted(true);

        const status = validateSteps();
        if (status && (status?.element || status?.errorDetails?.trim() != "")) {
            document.getElementById(status?.element).scrollIntoView({ behavior: 'smooth' });
            setErrorMessage("Please check all the errors");
            setShowError(true);
            setErrorDetail(status?.element + "$$$" + status.errorDetails);
            return;
        }

        const formattedData = Object.keys(sequenceSteps).map((stepId, index) => {
            const stepData = sequenceSteps[stepId];
            let field1 = "";
            let field2 = "";
            let type = "";

            if (stepData?.stepType.includes('email') || stepData?.stepType.includes('followUp')) {
                field1 = stepData?.customFields?.emailSubject || '';
                field2 = stepData?.customFields?.emailBody || '';
                type = stepData?.stepType;
            } else if (stepData.stepType.includes('linkedin')) {
                field1 = stepData?.customFields?.linkedinSubject || '';
                field2 = stepData?.customFields?.linkedinBody || '';
                type = stepData?.stepType;
            }else if (stepData.stepType.includes('call')) {
                field1 = '';
                field2 = stepData?.customFields?.callprepBody || '';
                type = stepData?.stepType;
            }

            return {
                crmStepId: stepData?.crmStepId || uuidv4(),
                sequenceId: stepData?.sequenceId || sequenceId,
                stepId: stepData?.stepId || uuidv4(),
                stepName: stepData.stepName,
                stepType: stepData.stepType,
                type,
                orderBy: index + 1,
                field1,
                field2,
                frameworkId: stepData?.framework || '',
                frameworkName: frameworkList.filter(fw => fw.frameworkId).find(fw => fw.frameworkId === stepData.framework)?.name || '',
                framework: frameworkList.filter(fw => fw.frameworkId).find(fw => fw.frameworkId === stepData.framework)?.framework || '',
                contextId: stepData?.context || '',
                contextName: contextList.filter(fw => fw.contextId).find(fw => fw.contextId === stepData.context)?.name || '',
                context: contextList.filter(fw => fw.contextId).find(fw => fw.contextId === stepData.context)?.context || '',
                isContextPublic: contextList.filter(fw => fw.contextId).find(fw => fw.contextId === stepData.context)?.isPublic,
                toneId: stepData?.tone,
                tone: toneList.find(tone => tone.toneId === stepData.tone)?.value || '',
                wordId: stepData?.wordCount,
                wordLimit: wordCountList.find(wc => wc.wordId === stepData.wordCount)?.value || '',
                activated: stepData?.activated,
                dataSource: integrationType,
                dataSourceType: integrationType
            };
        });

        console.log(formattedData);

        handleSequenceSteps(formattedData, sequenceName);
    }

    return (
        <div style={{ padding: '20px' }}>
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", padding: "0 10px" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "30px" }}>
                    <div style={{ fontSize: "24px" }}>{sequenceName}</div>
                </div>
                <div style={{ justifyContent: "flex-end" }}><Button iconName="arrowLeft" onClick={onBack} variant="secondary" padding={"10px 10px"} fontSize={"14px"} size="xs">Back</Button></div>
            </div>
            <SnackBar
                iconName={"info"}
                message={errorMessage}
                setShowSnackBar={setShowError}
                show={showError}
                timeout={8000}
                anchor={"top-right"}
                type={'error'}
            />

            {/* Sequence Steps */}
            {sequenceSteps.map((step, index) => (
                <div
                    id={"id_" + index}
                    key={index}
                    style={{
                        margin: '10px 0',
                        padding: '20px',
                        border: '1px solid #ddd',
                        borderRadius: '10px',
                        backgroundColor: '#f9f9f9',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        opacity: `${!step?.activated ? '0.6' : '1'}`
                    }}
                >
                    {errorDetail && isSubmitted && errorDetail.split("$$$")[0] === "id_" + index && <div className="text-red-500 text-[12px]">{errorDetail.split("$$$")[1]}</div>}
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                        {/* Step Name */}
                        <div style={{ marginBottom: '10px' }}>
                            <Typography fontSize={"20px"}>{step?.stepName}</Typography>
                        </div>
                        {/* Enable/Disable Icon */}
                        <Tooltip title={"Disabling this step will remove it from the sequence and prevent output generation"} placement="top-start" disableHoverListener={!step?.activated} arrow>
                            <div>
                                <ToggleButton
                                    value={step?.activated}
                                    name='activated'
                                    variant="tertiary"
                                    onChange={(value) => {
                                        setSelectedIndex(index);
                                        setIsDeleteConfirmationShow(true);
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </div>

                    {<div style={inputDisabled(index)}>
                        {/* Step Type */}
                        <FormControl margin="normal" style={{ width: "150px" }}>
                            <InputLabel id="select-type-label">Select Type</InputLabel>
                            <Select
                                labelId="select-type-label"
                                label="Select Type"
                                name='stepType'
                                value={step.stepType}
                                style={{ width: "200px" }}
                                onChange={(e) => handleStepChange(index, 'stepType', e.target.value)}
                            >
                                {step.stepType.includes('email') && emailOptions?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}

                                {step.stepType.includes('followUp') && followUpOptionsState?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}

                                {step.stepType.includes('linkedin') && linkedinOptions?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}

                                {step.stepType.includes('call') && callPrepOptions?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <div className="flex items-start input-fields col my-1" style={{ alignItems: 'flex-start', gap: '1rem' }}>
                            {/* custom email field */}
                            {step?.stepType?.includes('email') && (
                                <>
                                    <div className='w-1/2'>
                                        <Autocomplete
                                            options={fields}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Email Subject Custom Field *" variant="outlined" />}
                                            value={fields?.filter(c => c.value == step.customFields.emailSubject)?.map(f => ({ name: f.name, value: step.customFields.emailSubject }))[0] || null}
                                            onChange={(e, newValue) => {
                                                handleCustomFieldChange(index, 'emailSubject', newValue?.value)

                                            }}
                                            style={{ marginTop: "16px" }}
                                        />
                                        
                                        {!step.customFields.emailSubject?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">Email subject is required</div>}
                                    </div>
                                    <div className='w-1/2'>
                                        <Autocomplete
                                            options={fields}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Email Body Custom Field *" variant="outlined" />}
                                            value={fields?.filter(c => c.value == step.customFields.emailBody)?.map(f => ({ name: f.name, value: step.customFields.emailBody }))[0] || null}
                                            onChange={(e, newValue) => {
                                                handleCustomFieldChange(index, 'emailBody', newValue?.value)

                                            }}
                                            style={{ marginTop: "16px" }}
                                        />
                                        {!step.customFields.emailBody?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">Email body is required</div>}
                                    </div>
                                </>
                            )}

                            {step?.stepType?.includes('call') && (
                                <>
                                    <div style={{ width: "100%" }}>
                                        <Autocomplete
                                            options={fields}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Call Prep Custom Field *" variant="outlined" />}
                                            value={fields?.filter(c => c.name == step.customFields.callprepBody)?.map(f => ({ name: f.name, value: step.customFields.callprepBody }))[0] || null}

                                            onChange={(e, newValue) => {
                                                handleCustomFieldChange(index, 'callprepBody', newValue?.value)

                                            }}
                                            style={{ marginTop: "16px" }}
                                        />
                                    
                                        {!step.customFields.callprepBody?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">Call Prep body is required</div>}
                                    </div>
                                </>
                            )}


                            {step?.stepType?.includes('linkedinInMail') && (
                                <>
                                    <div className='w-1/2'>
                                        <Autocomplete
                                            options={fields}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Linkedin Subject Custom Field *" variant="outlined" />}
                                            value={fields?.filter(c => c.name == step.customFields.linkedinSubject)?.map(f => ({ name: f.name, value: step.customFields.linkedinSubject }))[0] || null}

                                            onChange={(e, newValue) => {
                                                handleCustomFieldChange(index, 'linkedinSubject', newValue?.value)

                                            }}
                                            style={{ marginTop: "16px" }}
                                        />
                                        
                                        {!step.customFields.linkedinSubject?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">linkedin subject is required</div>}
                                    </div>
                                    <div className='w-1/2'>
                                        <Autocomplete
                                            options={fields}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Linkedin Body Custom Field *" variant="outlined" />}
                                            value={fields?.filter(c => c.name == step.customFields.linkedinBody)?.map(f => ({ name: f.name, value: step.customFields.linkedinBody }))[0] || null}

                                            onChange={(e, newValue) => {
                                                handleCustomFieldChange(index, 'linkedinBody', newValue?.value)

                                            }}
                                            style={{ marginTop: "16px" }}
                                        />
                                        {!step.customFields.linkedinBody?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">linkedin body is required</div>}
                                    </div>

                                </>
                            )}

                            {/* custom linkedinDirectMessage and linkedinConnectionRequest field */}
                            {(step?.stepType.includes('linkedinDirectMessage') || step?.stepType.includes('linkedinConnectionRequest')) && (
                                <div style={{ width: "100%" }}>
                                    <Autocomplete
                                        options={fields}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="LinkedIn Custom Field *" variant="outlined" />}
                                        value={fields?.filter(c => c.name == step.customFields.linkedinBody)?.map(f => ({ name: f.name, value: step.customFields.linkedinBody }))[0] || null}

                                        onChange={(e, newValue) => {
                                            handleCustomFieldChange(index, 'linkedinBody', newValue?.value)

                                        }}
                                        style={{ marginTop: "16px" }}
                                    />
                                    
                                    {!step.customFields.linkedinBody?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">linkedin body is required</div>}
                                </div>
                            )}

                            {/* custom followUp field */}
                            {step?.stepType.includes('followUp') && (
                                <>
                                    <div style={{ width: "100%" }}>
                                        <Autocomplete
                                            options={fields}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Follow Up Email Body Custom Field *" variant="outlined" />}
                                            value={fields?.filter(c => c.name == step.customFields.emailBody)?.map(f => ({ name: f.name, value: step.customFields.emailBody }))[0] || null}

                                            onChange={(e, newValue) => {
                                                handleCustomFieldChange(index, 'emailBody', newValue?.value)

                                            }}
                                            style={{ marginTop: "16px" }}
                                        />
                                        {!step.customFields.emailBody?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">follow up email body is required</div>}
                                    </div>
                                </>
                            )}
                        </div>

                        {/* Framework List */}
                        
                        { !(step.stepType === 'linkedinConnectionRequest' || step.stepType === 'linkedinDirectMessage') && (
                            <Tooltip title={(step.framework && !(frameworkList.find(fw => fw.frameworkId === step.framework)?.isPublic) && !(loggedInUserDetail?.userId === frameworkList.find(fw => fw.frameworkId === step.framework)?.createdBy?.userId)) ? "Framework is private" : ""}>
                                <div>
                                    <Autocomplete
                                        options={step.stepType.includes('call') 
                                            ? frameworkList.filter(fw => (fw.type === "callprep") && (fw?.isPublic === undefined || fw?.isPublic)) 
                                            : frameworkList.filter(fw => (fw.type === "email" || fw.frameworkId == "") && (fw?.isPublic === undefined || fw?.isPublic))}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option) => {
                                            const isPublic = option?.isPublic === undefined || option?.isPublic === true;
                                            return (
                                                <li {...props}>
                                                    {option.name}{" "}
                                                    <span style={{ color: isPublic ? "green" : "red", marginLeft: "8px" }}>
                                                        {isPublic ? "(Public)" : "(Private)"}
                                                    </span>
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select framework" variant="outlined" />}
                                        value={frameworkList.find(fw => fw.frameworkId === step.framework && fw.frameworkId != "") || (step?.stepType.includes('call') ? frameworkList.find(fw => fw.name === "DEFAULT") : frameworkList.find(fw => fw.frameworkId === ""))}
                                        // disabled={(step.framework && !(frameworkList.find(fw => fw.frameworkId === step.framework)?.isPublic) && !(loggedInUserDetail?.userId === frameworkList.find(fw => fw.frameworkId === step.framework)?.createdBy?.userId))}
                                        onChange={(e, newValue) => {
                                            // handleStepChange(index, 'framework', newValue ? newValue.frameworkId : '');
                                            if (step?.stepType.includes('call'))
                                                handleStepChange(index, 'framework', newValue ? newValue.frameworkId : frameworkList.find(fw => fw.name === "DEFAULT")?.frameworkId);
                                            else
                                                handleStepChange(index, 'framework', newValue ? newValue.frameworkId : '');
                                        }}
                                        style={{ width: "50%", marginTop: "20px" }}
                                    />
                                </div>
                            </Tooltip>
                        )}

                        { !(step.stepType === 'linkedinConnectionRequest' || step.stepType === 'linkedinDirectMessage') &&
                            <div className='font-normal' style={{ fontSize: "12px", color: "#57585C", display: 'flex', alignItems: 'center', gap: '5px', marginTop: "10px" }}>
                                Want to add a custom framework?<Link to="/frameworkandcontext" style={{ color: "#0094E3", textDecoration: "underline" }}>click here</Link>
                            </div>}
                        {!(step.stepType === 'linkedinConnectionRequest' || step.stepType === 'linkedinDirectMessage' || step.framework === '') && <div style={{ fontSize: "10px", backgroundColor: "#DCF9FF", color: "#57585C", display: 'flex', alignItems: 'center', gap: '10px', marginTop: "10px", border: "1px solid #0094E3", padding: '5px', borderRadius: '5px' }}>
                            {(step?.stepType.includes('email') || step?.stepType.includes('call') || step?.stepType.includes('followUp') || step?.stepType.includes('linkedinInMail') || step.framework !== '') && frameworkList.find(fw => fw.frameworkId === step.framework)?.framework}
                        </div>}

                        {/* Tone List */}
                        {!step?.framework && !step.stepType.includes('followUp') && <div className="py-2">
                            <div className="py-2 text-[16px]">Select tone</div>
                            <div className="flex gap-4 cursor-pointer">
                                {toneList?.map((tone) => (
                                    <div
                                        key={tone.toneId}
                                        className={step.tone === tone.toneId ? selectedClass : notSelectedClass}
                                        onClick={(e) => handleStepChange(index, 'tone', tone.toneId)}
                                    >
                                        {tone.name}
                                    </div>
                                ))}
                            </div>
                        </div>}

                        {/* Word Count List */}

                        {!(step.stepType === 'linkedinConnectionRequest') && !step.stepType.includes('followUp') && !step.stepType.includes('call') && <div className="py-2">
                            <div className="py-2 text-[16px]">Select word limit <span style={{ fontSize: "12px", color: "#57585C" }}>(120 recommended limit)</span></div>
                            <div className="flex gap-2 cursor-pointer">
                                {wordCountList.map((wordCount) => (
                                    <div
                                        key={wordCount.wordId}
                                        className={step.wordCount === wordCount.wordId ? selectedClass : notSelectedClass}
                                        onClick={(e) => handleStepChange(index, 'wordCount', wordCount.wordId)}
                                    >
                                        {wordCount.name}
                                    </div>
                                ))}
                            </div>
                        </div>}

                        {/* Context List (optional) */}
                        {!autoPersonaSelectionSetting && (step.stepType.includes('followUp') || step.stepType.includes('email')) && (
                            <Tooltip title={(step.context && !(contextList.find(fw => fw.contextId === step.context)?.isPublic) && !(loggedInUserDetail?.userId === contextList.find(fw => fw.contextId === step.context)?.createdBy?.userId)) ? "Context is private" : ""}>
                                <div>
                                    <Autocomplete
                                        options={contextList?.filter(item => (item.type === 'email') && (item?.isPublic === true || item?.isPublic === undefined))}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option) => {
                                            const isPublic = option?.isPublic === undefined || option?.isPublic === true;
                                            return (
                                                <li {...props}>
                                                    {option.name}{" "}
                                                    <span style={{ color: isPublic ? "green" : "red", marginLeft: "8px" }}>
                                                        {isPublic ? "(Public)" : "(Private)"}
                                                    </span>
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select Context" variant="outlined" />}
                                        value={contextList?.filter(item => item.type === 'email' && (item.isPublic === undefined || item.isPublic))?.find(fw => fw.contextId === step.context) || null}
                                        // disabled={(step.context && !(contextList.find(fw => fw.contextId === step.context)?.isPublic) && !(loggedInUserDetail?.userId === contextList.find(fw => fw.contextId === step.context)?.createdBy?.userId))}
                                        onChange={(e, newValue) => {
                                            handleStepChange(index, 'context', newValue ? newValue.contextId : '');
                                        }}
                                        style={{ width: "50%", marginTop: '20px' }}
                                    />
                                </div>
                            </Tooltip>
                        )}

                        {!autoPersonaSelectionSetting && !step.stepType.includes('followUp') && step.stepType.includes('linkedin') && (
                            <Tooltip title={(step.context && !(contextList.find(fw => fw.contextId === step.context)?.isPublic) && !(loggedInUserDetail?.userId === contextList.find(fw => fw.contextId === step.context)?.createdBy?.userId)) ? "Context is private" : ""}>
                                <div>
                                    <Autocomplete
                                        options={contextList?.filter(item => (item.type === 'linkedin') && (item?.isPublic === true || item?.isPublic === undefined))}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option) => {
                                            const isPublic = option?.isPublic === undefined || option?.isPublic === true;
                                            return (
                                                <li {...props}>
                                                    {option.name}{" "}
                                                    <span style={{ color: isPublic ? "green" : "red", marginLeft: "8px" }}>
                                                        {isPublic ? "(Public)" : "(Private)"}
                                                    </span>
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select Context" variant="outlined" />}
                                        value={contextList?.filter(item => item.type === 'linkedin' && (item.isPublic === undefined || item.isPublic))?.find(fw => fw.contextId === step.context) || null}
                                        // disabled={(step.context && !(contextList.find(fw => fw.contextId === step.context)?.isPublic) && !(loggedInUserDetail?.userId === contextList.find(fw => fw.contextId === step.context)?.createdBy?.userId))}
                                        onChange={(e, newValue) => {
                                            handleStepChange(index, 'context', newValue ? newValue.contextId : '');
                                        }}
                                        style={{ width: "50%", marginTop: '20px' }}
                                    />
                                </div>
                            </Tooltip>
                        )}

                        {!autoPersonaSelectionSetting && !step.stepType.includes('followUp') && step.stepType.includes('call') && <Autocomplete
                            options={contextList.filter(item => item.type === 'callprep' && (item?.isPublic === undefined || item?.isPublic))}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => {
                                const isPublic = option?.isPublic === undefined || option?.isPublic === true;
                                return (
                                    <li {...props}>
                                        {option.name}{" "}
                                        <span style={{ color: isPublic ? "green" : "red", marginLeft: "8px" }}>
                                            {isPublic ? "(Public)" : "(Private)"}
                                        </span>
                                    </li>
                                );
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Context" variant="outlined" />}
                            value={contextList.filter(item => item.type === 'callprep')?.find(fw => fw.contextId === step.context) || null}
                            onChange={(e, newValue) => {
                                handleStepChange(index, 'context', newValue ? newValue.contextId : '');
                            }}
                            style={{ width: "50%", marginTop: '20px' }}
                        />}
                        {step.context !== '' && <div style={{ fontSize: "10px", backgroundColor: "#DCF9FF", color: "#57585C", display: 'flex', alignItems: 'center', gap: '10px', marginTop: "10px", border: "1px solid #0094E3", padding: '5px', borderRadius: '5px' }}>
                            {contextList.find(cx => cx.contextId === step.context)?.context}
                        </div>}
                    </div>}
                </div>
            ))}

            {/* Button to Add Sequence Step and submit sequence*/}
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                <Tooltip title={sequenceName ? (sequenceSteps.length > 0 ? '' : 'Please add step') : 'Sequence Name is mandatory'} placement="top" arrow>
                    <span>
                        <Button
                            onClick={handleSubmitSequence}
                            variant={"primary"}
                            padding={"20px"}
                            disabled={sequenceName ? (sequenceSteps.length > 0 ? false : true) : true}
                        >
                            Save Sequence
                        </Button>
                    </span>
                </Tooltip>
            </div>


            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={!isDeleteConfirmationShow}
                setHideModal={() => {
                    setIsDeleteConfirmationShow(false);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="40px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text fontSize={"20px"} fontWeight={300}>
                        {sequenceSteps[selectedIndex]?.activated ? "Are you sure you want to deactivate this Setting" : "Are you sure you want to activate this Setting"}
                    </Text>
                    <Flex mt="15px">
                        <Flex
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"14px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => { onDeleteSetting() }}
                        >
                            Yes
                        </Flex>
                        <Flex
                            ml="16px"
                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"14px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => { setIsDeleteConfirmationShow(false) }}
                        >
                            Cancel
                        </Flex>
                    </Flex>
                </Flex>
            </ModalWrapper>
        </div>
    );
};

export default SavedSalesloftSequenceStepList;
