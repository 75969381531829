import { Box, styled } from "@mui/material";

const EditableDivContainer = styled(Box)<{ disabled?: boolean }>(
  ({ disabled }) => ({
    border: `1px solid ${disabled ? "#B0B0B0" : "#8D68F6"}`, // Gray when disabled
    borderRadius: "10px",
    backgroundColor: "#FFF",
    padding: "6px 10px",
    display: "flex",
    marginTop: "10px",
    gap: "6px",
  })
);

const EditableDiv = styled(Box)({
  flexGrow: 1,

  "& .highlight": {
    background: "linear-gradient(90deg, #8D68F6 0%, #0094E3 100%)",
    color: "#FFF",
    padding: "2px",
    borderRadius: "2px",
  },
});

const EditableDivStartButton = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
});

const EditableDivEndButton = styled(Box)({
  display: "flex",
  alignItems: "flex-end",
});

const HighlightBox = styled(Box)({
  background: "linear-gradient(90deg, #8D68F6 0%, #0094E3 100%)",
  display: "flex",
  gap: "10px",
  padding: "11px 16px",
  alignItems: "center",
  borderRadius: "8px",
  marginTop: "10px",
  "& path": {
    fill: "#FFF",
  },
});

export {
  EditableDivContainer,
  EditableDiv,
  EditableDivStartButton,
  EditableDivEndButton,
  HighlightBox,
};
