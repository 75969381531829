import Button from 'components/Elements/Button';
import React, { useEffect, useState } from 'react';
import { axiosHelperCore } from "utils";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { TextField, Backdrop } from "@mui/material";
import { useAppSelector } from 'hooks';
import MemoRevenoidLoader from 'components/RevenoidLoader/RevenoidLoader';
import Select from 'react-select';

const OutreachForm = ({ isDualMode, isFieldAdded, setShowForm, setOutreachText, setIsFieldsAdded, mode }) => {
    const [loading, setLoading] = useState(false);
    const [IsError, setIsError] = useState(false);
    const [fields, setFields] = useState([]);
    const [accountFields, setAccountFields] = useState([]);
    const [accountFieldData, setAccountFieldData] = useState({ accountScoreField: "", accountSummaryField: "" });
    const [prospectFieldData, setProspectFieldData] = useState({ tagField: "evabot", callPrepField: "", prospectScore: "" });

    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 999 }),
        menu: (base) => ({
            ...base,
            maxHeight: 400,
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: 400,
            overflowY: 'auto',
        }),
    };


    const orgId = useAppSelector(
        (state) => state.profileState.profileData.organizationId
    );

    const getOutreachFieldData = async () => {
        setLoading(true);
        try {
            const result = await axiosHelperCore({
                url: `outreach/getData`,
                method: "POST",
                JSONData: { organizationId: orgId }
            });

            console.log("resultresultresult", result);

            if (result?.data?.outreachData) {
                const outreachData = result?.data?.outreachData;
                setAccountFieldData({ accountScoreField: outreachData?.accountScore, accountSummaryField: outreachData?.accountSummary });
                setProspectFieldData({ tagField: outreachData?.tag || "evabot", callPrepField: outreachData?.callPrep, prospectScore: outreachData?.prospectScore });
            }
        } catch (error) {
            console.error("Error fetching outreach field data:", error);
        } finally {
            setLoading(false);
        }
    };

    const getFieldNames = async () => {
        try {
            const result = await axiosHelperCore({
                url: `outreach/getFieldNames`,
                method: "POST",
                JSONData: {}
            });
            console.log("resultresultresult", result);
            setFields(result?.data?.prospectFields);
            setAccountFields(result?.data?.accountFields);
        }
        catch (error) {
            console.error("Error fetching outreach field data:", error);
        } finally {
            setLoading(false);
        }
    }

    const saveOutreachFieldData = async () => {
        if (!validateFields()) {
            setIsError(true);
            const div = document.getElementById('scrollableDiv');
            div.scrollTop = 0;
            return;
        }

        setLoading(true);

        try {
            await axiosHelperCore({
                url: `outreach/saveData`,
                method: "POST",
                JSONData: { accountData: accountFieldData, prospectData: prospectFieldData }
            });
            setIsFieldsAdded(true);
            setShowForm(false);
            setOutreachText("Connected");
        } catch (error) {
            console.error("Error saving outreach field data:", error);
        } finally {
            setLoading(false);
        }
    };


    const validateFields = () => {
        const accountFields = [
            accountFieldData?.accountScoreField,
            accountFieldData?.accountSummaryField
        ];

        const normalizedAccountFields = accountFields.map(field =>
            field !== undefined && field !== null && field.trim() !== ''
                ? field.trim().toLowerCase()
                : field
        );

        const filteredAccountFields = normalizedAccountFields.filter(
            field => field !== undefined && field !== null && field !== ''
        );

        // Check if account fields are unique
        const uniqueAccountFields = new Set(filteredAccountFields);
        if (uniqueAccountFields.size !== filteredAccountFields.length) {
            return false; // Duplicate found in account fields
        }

        // Extract and normalize prospect fields
        const prospectFields = [
            prospectFieldData?.tagField,
            prospectFieldData?.callPrepField,
            prospectFieldData?.prospectScore
        ];

        const normalizedProspectFields = prospectFields.map(field =>
            field !== undefined && field !== null && field.trim() !== ''
                ? field.trim().toLowerCase()
                : field
        );

        const filteredProspectFields = normalizedProspectFields.filter(
            field => field !== undefined && field !== null && field !== ''
        );

        // Check if prospect fields are unique
        const uniqueProspectFields = new Set(filteredProspectFields);
        if (uniqueProspectFields.size !== filteredProspectFields.length) {
            return false; // Duplicate found in prospect fields
        }

        return true; // All fields in both groups are unique within their groups
    }

    useEffect(() => {
        if (orgId) {
            getOutreachFieldData();
        }
    }, [orgId]);

    useEffect(() => {
        setShowForm(true);
        getFieldNames();
    }, []);


    const cancel = () => {
        setIsFieldsAdded(true);
        setShowForm(false);
    }

    if (isDualMode && !mode)
        return (<>  </>)

    return (
        <>
            {loading && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <MemoRevenoidLoader />
                </Backdrop>
            )}

            <div id='scrollableDiv' className="text-left" style={{ width: "90%", margin: "0 auto", maxHeight: "40vh", scrollbarWidth: "none", overflowY: "auto" }} >
                {
                    mode === "write" && (<div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div>
                            <div>Prospect Fields</div>
                            <div style={{ padding: "10px" }}>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Call Prep</div>
                                    <Select
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        className="text-sm"
                                        menuPlacement='auto'
                                        placeholder="Select"
                                        name="field2"
                                        value={fields.find(f => f.value === prospectFieldData.callPrepField) || null}
                                        options={fields}
                                        onChange={(selectedField) => {
                                            setProspectFieldData({ ...prospectFieldData, callPrepField: selectedField?.value })
                                        }} />
                                </div>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Prospect Account Score</div>
                                    <Select
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        className="text-sm"
                                        menuPlacement='auto'
                                        placeholder="Select"
                                        name="prospectScore"
                                        value={fields.find(f => f.value === prospectFieldData.prospectScore) || null}
                                        options={fields}
                                        onChange={(selectedField) => {
                                            setProspectFieldData({ ...prospectFieldData, prospectScore: selectedField?.value })
                                        }} />
                                </div>

                            </div>


                            <div >Account Fields</div>
                            <div style={{ padding: "10px" }}>
                                <div className='text-sm text-[#57585C] mt-1 mb-1'>Account Score</div>
                                <Select
                                    styles={customStyles}
                                    menuPortalTarget={document.body}
                                    className="text-sm"
                                    menuPlacement='auto'
                                    placeholder="Select"
                                    name="accountscore"
                                    value={accountFields.find(f => f.value === accountFieldData.accountScoreField) || null}
                                    options={accountFields}
                                    onChange={(selectedField) => {
                                        setAccountFieldData({ ...accountFieldData, accountScoreField: selectedField?.value })
                                    }} />

                                <div>
                                    <div className='text-sm text-[#57585C]'>Account Summary</div>
                                    <Select
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        className="text-sm"
                                        menuPlacement='auto'
                                        placeholder="Select"
                                        name="accountsummary"
                                        value={accountFields.find(f => f.value === accountFieldData.accountSummaryField) || null}
                                        options={accountFields}
                                        onChange={(selectedField) => {
                                            setAccountFieldData({ ...accountFieldData, accountSummaryField: selectedField?.value })
                                        }} />

                                </div>
                            </div>
                        </div>
                    </div>)
                }
                {
                    mode === "read" &&
                    (<div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div>
                            <div className='mb-1'>Prospect Fields</div>
                            <div style={{ padding: "10px" }}>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Tag</div>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={prospectFieldData.tagField || ''}
                                        onChange={(e) => setProspectFieldData({ ...prospectFieldData, tagField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>


                            </div>
                        </div>
                    </div>)
                }
                {
                    (!mode || mode === "all") && (<div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div>
                            <div className='mb-1'>Prospect Fields</div>
                            <div style={{ padding: "10px" }}>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Tag</div>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={prospectFieldData.tagField || ''}
                                        onChange={(e) => setProspectFieldData({ ...prospectFieldData, tagField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Call Prep</div>
                                    <Select
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        className="text-sm"
                                        menuPlacement='auto'
                                        placeholder="Select"
                                        name="field2"
                                        value={fields.find(f => f.value === prospectFieldData.callPrepField) || null}
                                        options={fields}
                                        onChange={(selectedField) => {
                                            setProspectFieldData({ ...prospectFieldData, callPrepField: selectedField?.value })
                                        }} />
                                </div>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Prospect Account Score</div>
                                    <Select
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        className="text-sm"
                                        menuPlacement='auto'
                                        placeholder="Select"
                                        name="prospectScore"
                                        value={fields.find(f => f.value === prospectFieldData.prospectScore) || null}
                                        options={fields}
                                        onChange={(selectedField) => {
                                            setProspectFieldData({ ...prospectFieldData, prospectScore: selectedField?.value })
                                        }} />

                                </div>

                            </div>
                        </div>
                        <div className="mt-3">
                            <div className='mb-1'>Account Fields</div>
                            <div style={{ padding: "10px" }}>

                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Account Score</div>
                                    <Select
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        className="text-sm"
                                        menuPlacement='auto'
                                        placeholder="Select"
                                        name="accountscore"
                                        value={accountFields.find(f => f.value === accountFieldData.accountScoreField) || null}
                                        options={accountFields}
                                        onChange={(selectedField) => {
                                            setAccountFieldData({ ...accountFieldData, accountScoreField: selectedField?.value })
                                        }} />

                                </div>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Account Summary</div>
                                    <Select
                                        styles={customStyles}
                                        menuPortalTarget={document.body}
                                        className="text-sm"
                                        menuPlacement='auto'
                                        placeholder="Select"
                                        name="accountsummary"
                                        value={accountFields.find(f => f.value === accountFieldData.accountSummaryField) || null}
                                        options={accountFields}
                                        onChange={(selectedField) => {
                                            setAccountFieldData({ ...accountFieldData, accountSummaryField: selectedField?.value })
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>)
                }

                <div className="mt-3 text-center flex flex-wrap space-x-2 justify-center">
                    <div className='mt-1'><Button variant={"salesforce"} text={isFieldAdded ? "Save" : "Save"} onClick={saveOutreachFieldData} /></div>
                    <div className='mt-1'><Button variant={"secondary"} text={"Cancel"} onClick={cancel} /></div>
                </div>
            </div>
        </>
    );
};

export default OutreachForm;
