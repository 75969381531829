import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import Image from "../../assets/images/ImageDef.jpeg";
import { convertDate, getDatesBetweenDates } from "utils/constants";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { ImageTag } from "@evabot/eva-react-core-library/dist/molecules/ImageTag/ImageTag";
import { useAppSelector } from "hooks";
import { ToolTip } from "@evabot/eva-react-core-library/dist/molecules/ToolTip/ToolTip";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { background, borderStyle } from "styled-system";

interface AeReturnAnalyticsTableBodyFieldProps {
  index: number,
  data: any;
}

const AeReturnAnalyticsTableBodyField: React.FC<
  AeReturnAnalyticsTableBodyFieldProps
> = ({ index, data }) => {
  const dateValues = useAppSelector(
    (state) => state?.analyticsState?.dateRange
  );
  const dateRange = getDatesBetweenDates(
    convertDate(dateValues[0]),
    convertDate(dateValues[1])
  );



  return (
    <Flex
      fontSize="14px"
      fontWeight={400}
      color="#050505"
      border="1px solid rgba(0, 0, 0, 0.10)"
      borderRadius={"12px"}
      backgroundColor={index % 2 == 0 ? "#FFF" : "rgb(249, 249, 249)"}
    >
      <Flex
        width="22%"
        justifyContent={"center"}
        alignItems={"center"}
        borderRight="1px solid rgba(0, 0, 0, 0.10)"
        flexDirection={"column"}
        

      >
        <Flex gap="14px" alignItems={"center"}>
          <ImageTag width="32px" height="32px" image={data?.image || Image} />

          <Flex
            flexDirection={"column"}
            gap="5px"
            color="#050505"
            fontSize={"14px"}
            fontWeight={400}
          >
            <Text>
              {data?.firstName} {data?.lastName}
            </Text>
            <Text fontSize={"12px"}>{data?.email}</Text>
            {(data?.email?.includes("evabot.ai") || data?.email?.includes("acceldata.io")) && <div>
              <div style={{ display: "inline", background: "#3064f6", fontSize: "12px", color: "white", padding: "5px", borderRadius: "8px" }}>{"Meetings Booked: " + (data?.meetings || "0")}</div>
            </div>}

          </Flex>

        </Flex>
        <Flex>
          <Text marginTop={"25px"} fontSize={"12px"} color={"#777575"}>App Version: {data?.appVersion || "N/A"}</Text>
        </Flex>
      </Flex>
      <Box width={"78%"}>
        <Flex
          justifyContent={"space-between"}
          padding={"10px"}
          borderBottom="1px solid rgba(0, 0, 0, 0.10)"
        >
          <Flex width={"26%"}>Profiles researched</Flex>
          <Flex width={"74%"} justifyContent={"space-between"}>
            {dateRange?.map((dataItem) => {
              return (
                <Flex
                  width="100%"
                  justifyContent={"center"}
                  color="#050505"
                  fontSize={"12px"}
                  fontWeight={400}
                  cursor="pointer"
                >
                  {dataItem.date === "total"
                    ? dateRange.reduce((total, item) => total + (data[item.date]?.totalResearched || 0), 0)
                    : <>

                      <ToolTip style={{ cursor: "pointer" }} color="#FFFFFF" bgColor="#000" boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)" direction="top-start" title={"Total Profile Researched (Unique profiles researched)"}>
                        {data[dataItem.date]?.totalResearched || 0}({(data[dataItem.date]?.researched || 0) != 0 ? <a >{(data[dataItem.date]?.researched || 0)}</a> : 0})
                      </ToolTip>
                    </>
                  }
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex
          justifyContent={"space-between"}
          padding={"10px"}
          borderBottom="1px solid rgba(0, 0, 0, 0.10)"
        >
          <Flex width={"26%"}>Emails generated</Flex>
          <Flex width={"74%"} justifyContent={"space-between"}>
            {dateRange?.map((dataItem) => {
              return (
                <Flex
                  width="100%"
                  justifyContent={"center"}
                  color="#050505"
                  fontSize={"12px"}
                  fontWeight={400}
                  cursor="pointer"
                >
                  {dataItem.date === "total"
                    ? dateRange.reduce((total, item) => total + (data[item.date]?.totalColdEmail || 0), 0)
                    : <>

                      <ToolTip style={{ cursor: "pointer" }} color="#FFFFFF" bgColor="#000" boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)" direction="top-start" title={"Total Emails generated (Unique emails generated)"}>
                        <div>{data[dataItem.date]?.totalColdEmail || 0}({(data[dataItem.date]?.coldEmail || 0) != 0 ? <a >{(data[dataItem.date]?.coldEmail || 0)}</a> : 0})</div>
                      </ToolTip>
                    </>
                  }
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex
          justifyContent={"space-between"}
          padding={"10px"}
          borderBottom="1px solid rgba(0, 0, 0, 0.10)"
        >
          <Flex width={"26%"}>LI Messages</Flex>
          <Flex width={"74%"} justifyContent={"space-between"}>
            {dateRange?.map((dataItem) => {
              return (
                <Flex
                  width="100%"
                  justifyContent={"center"}
                  color="#050505"
                  fontSize={"12px"}
                  fontWeight={400}
                  cursor="pointer"

                >
                  {dataItem.date === "total"
                    ? dateRange.reduce((total, item) => total + (data[item.date]?.totalLinkedin || 0), 0)
                    : <>

                      <ToolTip style={{ cursor: "pointer" }} color="#FFFFFF" bgColor="#000" boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)" direction="top-start" title={"Total LI messages (Unique LI messages)"}>
                        {data[dataItem.date]?.totalLinkedin || 0}({(data[dataItem.date]?.linkedin || 0) != 0 ? <a >{(data[dataItem.date]?.linkedin || 0)}</a> : 0})
                      </ToolTip>
                    </>
                  }
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex
          justifyContent={"space-between"}
          padding={"10px"}
          borderBottom="1px solid rgba(0, 0, 0, 0.10)"
        >
          <Flex width={"26%"}>Call scripts</Flex>
          <Flex width={"74%"} justifyContent={"space-between"}>
            {dateRange?.map((dataItem) => {
              return (
                <Flex
                  width="100%"
                  justifyContent={"center"}
                  color="#050505"
                  fontSize={"12px"}
                  fontWeight={400}
                  cursor="pointer"

                >
                  {dataItem.date === "total"
                    ? dateRange.reduce((total, item) => total + (data[item.date]?.totalCallPrep || 0), 0)
                    : <>

                      <ToolTip style={{ cursor: "pointer" }} color="#FFFFFF" bgColor="#000" boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)" direction="top-start" title={"Total call scripts (Unique call scripts)"}>
                        {data[dataItem.date]?.totalCallPrep || 0}({(data[dataItem.date]?.callPrep || 0) != 0 ? <a >{(data[dataItem.date]?.callPrep || 0)}</a> : 0})
                      </ToolTip>
                    </>
                  }

                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Flex
          justifyContent={"space-between"}
          padding={"10px"}
          borderBottom="1px solid rgba(0, 0, 0, 0.10)"
        >
          <Flex width={"26%"}>Sequence(s)</Flex>
          <Flex width={"74%"} justifyContent={"space-between"}>
            {dateRange?.map((dataItem) => {
              return (
                <Flex
                  width="100%"
                  justifyContent={"center"}
                  color="#050505"
                  fontSize={"12px"}
                  fontWeight={400}
                  cursor="pointer"
                >
                  {dataItem.date === "total"
                    ? dateRange.reduce((total, item) => total + (data[item.date]?.totalSequenceEmailsGenerated || 0), 0)
                    : <>

                      <ToolTip style={{ cursor: "pointer" }} color="#FFFFFF" bgColor="#000" boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)" direction="top-start" title={"Total sequences (Unique sequences)"}>
                        {data[dataItem.date]?.totalSequenceEmailsGenerated || 0}({(data[dataItem.date]?.sequenceEmailGenerated || 0) != 0 ? <a >{(data[dataItem.date]?.sequenceEmailGenerated || 0)}</a> : 0})
                      </ToolTip>
                    </>
                  }

                </Flex>
              );
            })}
          </Flex>
        </Flex>


        <Flex
          justifyContent={"space-between"}
          padding={"10px"}
          borderBottom="1px solid rgba(0, 0, 0, 0.10)"
        >
          <Flex width={"26%"}>Sequence(s) inserted in CRM</Flex>
          <Flex width={"74%"} justifyContent={"space-between"}>
            {dateRange?.map((dataItem) => {
              return (
                <Flex
                  width="100%"
                  justifyContent={"center"}
                  color="#050505"
                  fontSize={"12px"}
                  fontWeight={400}
                  cursor="pointer"

                >
                  {dataItem.date === "total"
                    ? dateRange.reduce((total, item) => total + (data[item.date]?.totalSequenceEmails || 0), 0)
                    : <>

                      <ToolTip style={{ cursor: "pointer" }} color="#FFFFFF" bgColor="#000" boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)" direction="top-start" title={"Total sequences inserted in CRM (Unique sequences inserted in CRM)"}>
                        {data[dataItem.date]?.totalSequenceEmails || 0}({(data[dataItem.date]?.sequenceEmail || 0) != 0 ? <a >{(data[dataItem.date]?.sequenceEmail || 0)}</a> : 0})
                      </ToolTip>
                    </>
                  }

                </Flex>
              );
            })}
          </Flex>
        </Flex>

        <Flex
          justifyContent={"space-between"}
          padding={"10px"}
          borderBottom="1px solid rgba(0, 0, 0, 0.10)"
        >
          <Flex width={"26%"}>Total insights selected</Flex>
          <Flex width={"74%"} justifyContent={"space-between"}>
            {dateRange?.map((dataItem) => {
              return (
                <Flex
                  width="100%"
                  justifyContent={"center"}
                  color="#050505"
                  fontSize={"12px"}
                  fontWeight={400}
                  cursor="pointer"

                >
                  {dataItem.date === "total" ?
                    dateRange.reduce(
                      (total, item) =>
                        total + (data[item.date]?.totalInsight || 0),
                      0
                    )
                    :
                    <ToolTip style={{ cursor: "pointer" }} color="#FFFFFF" bgColor="#000" boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)" direction="top-start" title={"Total insights selected"}>
                      {data[dataItem.date]?.totalInsight || 0}
                    </ToolTip>}
                </Flex>
              );
            })}
          </Flex>
        </Flex>

        {/* <Flex justifyContent={"space-between"} padding={"10px"}>
          <Flex width={"26%"}>Avg. time per profile</Flex>
          <Flex width={"74%"} justifyContent={"space-between"}>
            <Flex
              width="100%"
              justifyContent={"center"}
              color="#050505"
              fontSize={"12px"}
              fontWeight={400}
            >
              -
            </Flex>
            <Flex
              width="100%"
              justifyContent={"center"}
              color="#050505"
              fontSize={"12px"}
              fontWeight={400}
            >
              -
            </Flex>
            <Flex
              width="100%"
              justifyContent={"center"}
              color="#050505"
              fontSize={"12px"}
              fontWeight={400}
            >
              -
            </Flex>
            <Flex
              width="100%"
              justifyContent={"center"}
              color="#050505"
              fontSize={"12px"}
              fontWeight={400}
            >
              -
            </Flex>
            <Flex
              width="100%"
              justifyContent={"center"}
              color="#050505"
              fontSize={"12px"}
              fontWeight={400}
            >
              -
            </Flex>
            <Flex
              width="100%"
              justifyContent={"center"}
              color="#050505"
              fontSize={"12px"}
              fontWeight={400}
            >
              -
            </Flex>
            <Flex
              width="100%"
              justifyContent={"center"}
              color="#050505"
              fontSize={"12px"}
              fontWeight={400}
            >
              -
            </Flex>
          </Flex>
        </Flex> */}
      </Box>
    </Flex>
  );
};

export default AeReturnAnalyticsTableBodyField;
