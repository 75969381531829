import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { useEffect, useState } from "react"
import { axiosHelperCore, CORE_API_URL } from "utils";
import GongForm from "./GongForm";
import "./integration.css";
import moment from 'moment';
import GongQuestion from "./GongQuestion/GongTable";
import GongLogoIcon from "./GongLogo";
import AvomaLogoIcon from "./AvomaLogo";
import { Box, Backdrop } from "@mui/material";
import AvomaForm from "./AvomaForm";
import MemoRevenoidLoader from 'components/RevenoidLoader/RevenoidLoader';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';

export default function IntegrationList() {
    const initialIntegrations = [{ type: "gong" }, { type: "avoma" }];
    const [connectedIntegrationList, setConnectedIntegrationList] = useState([]);
    const [hideModal, setHideModal] = useState(true);
    const [showAvomaConnectModal, setShowAvomaConnectModal] = useState(false);
    const [clientKey, setClientKey] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [selectedDisconnectIntegartionType, setSelectedDisconnectIntegartionType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await axiosHelperCore({
                url: 'rapport/user/getOtherIntegrations',
                method: 'POST'
            });
            console.log("resultresultresult", result);
            const connections = result?.data?.integrations?.connections || [];
            setConnectedIntegrationList(connections);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const disconnectIntegration = async (integrationType) => {
        try {
            const result = await axiosHelperCore({
                url: `${integrationType}/deleteRefreshToken`,
                method: 'POST',
                JSONData: { type: integrationType }
            });
            fetchData();
            setHideModal(true)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleAvomaConnect = () => {
        setShowAvomaConnectModal(true);
    };

    const handleAvomaVerify = async () => {
        setLoading(true);
        const values = {
            clientKey: clientKey,
            secretKey: clientSecret,
            type: "avoma"
        }
        const result = await axiosHelperCore({
            url: `avoma/saveKeys`,
            method: "POST",
            JSONData: values
        });

        if (!result?.data?.success) {
            setSnackbarMessage(result?.data?.message);
            setShowSnackBar(true);
            setSnackbarType('error');
        }

        if (result?.data?.success) {
            fetchData();
        }
        localStorage.removeItem("isRegInt");
        setShowAvomaConnectModal(false);
        setLoading(false);
    };

    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}>
                <MemoRevenoidLoader />
            </Backdrop>}
            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={hideModal}
                setHideModal={() => {
                    setHideModal(true);
                    setSelectedDisconnectIntegartionType(null);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="48px"
            >
                {selectedDisconnectIntegartionType && (<Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text display={"flex"} mt="24px" fontSize={"24px"} fontWeight={300}>
                        {" "}
                        Are you sure you want to disconnect&nbsp;<Text textTransform={"capitalize"}>{selectedDisconnectIntegartionType}</Text>?
                    </Text>

                    <Flex mt="15px">
                        <Flex

                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => disconnectIntegration(selectedDisconnectIntegartionType)}

                        >
                            Yes
                        </Flex>
                        <Flex
                            ml="16px"
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => setHideModal(true)}

                        >
                            Cancel
                        </Flex>

                    </Flex>
                </Flex>)}
            </ModalWrapper>

            <ModalWrapper
                width="40%"
                height="max-content"
                hideModal={!showAvomaConnectModal}
                setHideModal={() => setShowAvomaConnectModal(false)}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="24px"
            >
                <Flex flexDirection={"column"} alignItems={"left"} justifyContent={"left"} gap="20px">
                    <Text fontSize={"20px"} fontWeight={300} marginBottom={"10px"}>
                        Connect with Avoma
                    </Text>
                    <div>
                        <div style={{ fontSize: "16px", marginBottom: "2px" }}>Client Key</div>
                        <input
                            type="text"
                            value={clientKey}
                            onChange={(e) => setClientKey(e.target.value)}
                            style={{ padding: "8px", width: "100%", border: "1px solid #ccc", borderRadius: "4px" }}
                        />
                    </div>
                    <div>
                        <div style={{ fontSize: "16px", marginBottom: "2px" }}>Client Secret</div>
                        <input
                            type="text"
                            value={clientSecret}
                            onChange={(e) => setClientSecret(e.target.value)}
                            style={{ padding: "8px", width: "100%", border: "1px solid #ccc", borderRadius: "4px" }}
                        />
                    </div>
                    <Box display={"flex"} gap={"10px"} justifyContent={"flex-end"} marginTop={"40px"}>
                    <Button disabled={clientKey?.trim() == "" || clientSecret?.trim() == ""} variant="primary" fontSize={"16px"} padding={"10px 10px"} onClick={handleAvomaVerify} color="primary">
                            Verify
                        </Button>
                        <Button variant="secondary" fontSize={"16px"} padding={"10px 10px"} onClick={() => setShowAvomaConnectModal(false)}>Cancel</Button>
                       
                    </Box>
                </Flex>
            </ModalWrapper>
            <div style={{ width: "100%", margin: "0 auto", marginTop: "20px" }}>
                {initialIntegrations.map((initIntegration) => {
                    const connectedIntegration = connectedIntegrationList.find(integration => integration.type === initIntegration.type);
                    const isActive = !!connectedIntegration?.refreshToken;

                    if (initIntegration.type === "gong") {
                        return (
                            <div style={{ background: "#fff", border: "1px solid rgb(232, 233, 253)", borderRadius: "8px", padding: "15px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }} key={initIntegration.type}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <GongLogoIcon />
                                    <div>
                                        {isActive ? (
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "end", gap: "5px" }}>
                                                <Button size="xs" onClick={() => { setHideModal(false); setSelectedDisconnectIntegartionType(initIntegration.type) }} borderColor={"#E40606"} backgroundColor={"#FFF2F2 !important"} color={"#E40606"} variant="primary" borderRadius={"12px"} fontSize="14px">
                                                    Disconnect
                                                </Button>
                                                {connectedIntegration?.fields?.syncedOn && (
                                                    <Text fontSize="14px" color="#4F5B67">
                                                        <span style={{ fontWeight: "800" }}>Last Synced On:</span> {moment(connectedIntegration.fields.syncedOn).format('MMM D, YYYY')}
                                                    </Text>
                                                )}
                                            </div>
                                        ) : (
                                            <a href={CORE_API_URL + "gong/getAuth"} style={{ background: "#DD015B", padding: "9px 16px", color: "#FFFFFF", borderRadius: "9px", fontSize: "16px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                                Connect&nbsp;<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.25 7.75C10.25 7.33578 9.91422 7 9.5 7H9.125C6.84682 7 5 8.8468 5 11.125C5 13.4032 6.84682 15.25 9.125 15.25H12.875C15.1532 15.25 17 13.4032 17 11.125C17 9.25682 15.7581 7.67866 14.0547 7.17117C13.6323 7.04532 13.25 7.39665 13.25 7.83745V7.87622C13.25 8.24942 13.5309 8.55513 13.8759 8.69755C14.8291 9.091 15.5 10.0296 15.5 11.125C15.5 12.5747 14.3247 13.75 12.875 13.75H9.125C7.67525 13.75 6.5 12.5747 6.5 11.125C6.5 9.67525 7.67525 8.5 9.125 8.5H9.5C9.91422 8.5 10.25 8.16422 10.25 7.75Z" fill="white"/>
                                                    <path d="M8.625 3C10.9032 3 12.75 4.84682 12.75 7.125C12.75 9.4032 10.9032 11.25 8.625 11.25H8.25C7.83577 11.25 7.5 10.9142 7.5 10.5C7.5 10.0858 7.83577 9.75 8.25 9.75H8.625C10.0747 9.75 11.25 8.57475 11.25 7.125C11.25 5.67525 10.0747 4.5 8.625 4.5H4.875C3.42525 4.5 2.25 5.67525 2.25 7.125C2.25 8.22038 2.92088 9.159 3.87415 9.55245C4.21911 9.6948 4.5 10.0006 4.5 10.3738V10.4125C4.5 10.8533 4.11768 11.2047 3.69524 11.0788C1.99194 10.5713 0.75 8.99318 0.75 7.125C0.75 4.84682 2.59682 3 4.875 3H8.625Z" fill="white"/>
                                                </svg>
                                            </a>
                                        )}
                                    </div>
                                </div>
                                <hr />
                                {isActive && 
                                    <div style={{ width: "100%" }}>
                                        <GongForm integration={connectedIntegration} setConnectedIntegrationList={setConnectedIntegrationList} />
                                    </div>    
                                }
                                {isActive && <div style={{ marginTop: "40px", width: "100%" }}><GongQuestion /></div>}
                            </div>
                        );
                    }

                    if (initIntegration.type === "avoma") {
                        return (
                            <div style={{ background: "#fff", border: "1px solid rgb(232, 233, 253)", borderRadius: "8px", padding: "15px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", marginTop : "15px" }} key={initIntegration.type}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <AvomaLogoIcon />
                                    <div>
                                        {isActive ? (
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "end", gap: "5px" }}>
                                                <Button size="xs" onClick={() => { setHideModal(false); setSelectedDisconnectIntegartionType(initIntegration.type) }} borderColor={"#E40606"} backgroundColor={"#FFF2F2 !important"} color={"#E40606"} variant="primary" borderRadius={"12px"} fontSize="14px">
                                                    Disconnect
                                                </Button>
                                                {connectedIntegration?.fields?.syncedOn && (
                                                    <Text fontSize="14px" color="#4F5B67">
                                                        <span style={{ fontWeight: "800" }}>Last Synced On:</span> {moment(connectedIntegration.fields.syncedOn).format('MMM D, YYYY')}
                                                    </Text>
                                                )}
                                            </div>
                                        ) : (
                                            <div onClick={handleAvomaConnect} style={{ background: "#DD015B", padding: "9px 16px", color: "#FFFFFF", borderRadius: "9px", fontSize: "16px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                                Connect&nbsp;<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.25 7.75C10.25 7.33578 9.91422 7 9.5 7H9.125C6.84682 7 5 8.8468 5 11.125C5 13.4032 6.84682 15.25 9.125 15.25H12.875C15.1532 15.25 17 13.4032 17 11.125C17 9.25682 15.7581 7.67866 14.0547 7.17117C13.6323 7.04532 13.25 7.39665 13.25 7.83745V7.87622C13.25 8.24942 13.5309 8.55513 13.8759 8.69755C14.8291 9.091 15.5 10.0296 15.5 11.125C15.5 12.5747 14.3247 13.75 12.875 13.75H9.125C7.67525 13.75 6.5 12.5747 6.5 11.125C6.5 9.67525 7.67525 8.5 9.125 8.5H9.5C9.91422 8.5 10.25 8.16422 10.25 7.75Z" fill="white"/>
                                                    <path d="M8.625 3C10.9032 3 12.75 4.84682 12.75 7.125C12.75 9.4032 10.9032 11.25 8.625 11.25H8.25C7.83577 11.25 7.5 10.9142 7.5 10.5C7.5 10.0858 7.83577 9.75 8.25 9.75H8.625C10.0747 9.75 11.25 8.57475 11.25 7.125C11.25 5.67525 10.0747 4.5 8.625 4.5H4.875C3.42525 4.5 2.25 5.67525 2.25 7.125C2.25 8.22038 2.92088 9.159 3.87415 9.55245C4.21911 9.6948 4.5 10.0006 4.5 10.3738V10.4125C4.5 10.8533 4.11768 11.2047 3.69524 11.0788C1.99194 10.5713 0.75 8.99318 0.75 7.125C0.75 4.84682 2.59682 3 4.875 3H8.625Z" fill="white"/>
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <hr />
                                {isActive &&                                     
                                    <div style={{ width: "100%" }}>
                                        <AvomaForm integration={connectedIntegration} setConnectedIntegrationList={setConnectedIntegrationList} />
                                    </div>
                                }
                            </div>
                        );
                    }
                })}
            </div>
        </>
    );
}