import React, { useEffect, useRef, useState } from "react";
import FrameworkAndContextTableBody from "./FrameworkAndContextTableBody";
import FrameworkAndContextTableHeader from "./FrameworkAndContextTableHeader";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import userService from "services/userService";
import { Backdrop } from "@mui/material";
import { useAppSelector } from "hooks";
import { useDispatch } from "react-redux";
import {
  customDataFieldsApiData,
  setContextMaxWordLimit,
  setDataRepositoryFeatureEnabled,
  setMemberListingData,
} from "./store/frameworkAndContextReducer";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import Select from "react-select";
import SearchBar from "components/TailwindComponents/SearchBar";
import { axiosHelperCore } from "utils";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";
import { setOpenContextAddPopup } from "./store/frameworkAndContextReducer";
import FrameworkAndContextAddUpdate from "./FrameworkAndContextAddUpdate";
import { useLocation, useNavigate } from "react-router-dom";

// const StatusValues = ["All Framework/Context", "Framework : LinkedIn", "Framework : Email", "Context : LinkedIn", "Context : Email"];
const StatusValues = [
  { label: "All Framework/Context", value: "All Framework/Context" },
  { label: "Framework : Email", value: "Framework : Email" },
  { label: "Framework : CallPrep", value: "Framework : CallPrep" },
  { label: "Context : LinkedIn", value: "Context : LinkedIn" },
  { label: "Context : Email", value: "Context : Email" },
  { label: "Context : CallPrep", value: "Context : CallPrep" },
];

function FrameworkAndContextTable(props) {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { tabView } = props;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [filterStatus, setFilterStatus] = useState("All Framework/Context");
  const [membersData, setMembersData] = useState([]);
  // const [contextMaxWordLimit, setContextMaxWordLimit] = useState(150);
  const [contextData, setContextData] = useState();

  const dispatch = useDispatch();
  const searchValue = useRef("");

  const userOrganizationId = useAppSelector(
    (state) => state.memberListingState?.tabViewState?.isOrgId
  );

  const selectedUserId = useAppSelector(
    (state) =>
      state.memberListingState?.tabViewState?.isTabViewMemberData?.userId
  );

  const loggedInUserOrgId = useAppSelector(
    (state) => state.profileState.profileData.organizationId
  );

  const userId = useAppSelector(
    (state) => state.profileState.profileData.userId
  );

  const contextPopupUrlParam = useAppSelector(
    (state) => state.frameworkAndContextState.openContextAddPopup
  );

  const fetchReport = async () => {
    try {
      setLoading(true);
      let obj = {};
      if (tabView) {
        obj = { organizationId: userOrganizationId };
      } else {
        obj = { organizationId: loggedInUserOrgId };
      }
      userService.getAllMemberByOrgId(obj).then((response) => {
        let membersData = response?.data;

        let modifiedMemberData = [];
        membersData.forEach((member) => {
          member.frameworks?.forEach((framework) => {
            if (!framework.isDeleted) {
              modifiedMemberData.push({
                userId: member.userId,
                lastName: member.lastName,
                firstName: member.firstName,
                email: member.email,
                type: "framework",
                frameworkName: framework?.name,
                frameworkValue: framework?.framework,
                id: framework?.frameworkId,
                isPublic: framework?.isPublic,
                objectId: framework?._id,
                linkedinOremail: framework?.type,
                isExtensionVisibilityEnabled:
                  framework?.isExtensionVisibilityEnabled,
                defaultSettings: member?.defaultSettings,
              });
            }
          });
          member.contexts?.forEach((context) => {
            if (!context.isDeleted) {
              modifiedMemberData.push({
                userId: member.userId,
                lastName: member.lastName,
                firstName: member.firstName,
                email: member.email,
                type: "context",
                contextName: context?.name,
                contextValue: context?.context,
                id: context?.contextId,
                isPublic: context?.isPublic,
                objectId: context?._id,
                language: context?.language,
                linkedinOremail: context?.type,
                isExtensionVisibilityEnabled:
                  context?.isExtensionVisibilityEnabled,
                defaultSettings: member?.defaultSettings,
                queryDetails: context?.queryDetails,
                customData: context?.customData,
              });
            }
          });
        });

        dispatch(setMemberListingData(modifiedMemberData));
        setFilteredMembers(modifiedMemberData);
        setMembersData(modifiedMemberData);
        dispatch(setContextMaxWordLimit(response?.contextWordMaxLimit));
        // setContextMaxWordLimit(response?.contextWordMaxLimit);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching report:", error);
      setLoading(false);
    }
  };
  console.log("selectedUserIdselectedUserId", selectedUserId);

  const handleSearchChange = (e) => {
    searchValue.current = e?.target?.value?.toLowerCase();
    seachDataPipe();
  };

  const seachDataPipe = async () => {
    let searchContext = searchValue.current;
    let filteredData = membersData;
    if (filterStatus && filterStatus !== "All Framework/Context") {
      const splitStatus = filterStatus.split(":");
      const filterType = splitStatus[0]?.trim()?.toLowerCase();
      const filterLinkedinOrEmail = splitStatus[1]?.trim()?.toLowerCase();

      filteredData = filteredData.filter((item) => {
        const type = item.type?.toLowerCase();
        const linkedinOrEmail = item.linkedinOremail?.toLowerCase();
        return type === filterType && linkedinOrEmail === filterLinkedinOrEmail;
      });
    }
    if (searchContext.trim() != "") {
      filteredData = filteredData.filter(
        (m) =>
          m?.firstName?.toLowerCase().includes(searchContext) ||
          m?.lastName?.toLowerCase().includes(searchContext) ||
          m?.email?.toLowerCase().includes(searchContext) ||
          m?.frameworkName?.toLowerCase().includes(searchContext) ||
          m?.contextName?.toLowerCase().includes(searchContext) ||
          m?.frameworkValue?.toLowerCase().includes(searchContext) ||
          m?.contextValue?.toLowerCase().includes(searchContext)
      );
      setFilteredMembers(filteredData);
    } else {
      setFilteredMembers(filteredData);
    }
  };

  const getDataRepositoryTags = async () => {
    try {
      setLoading(true);

      const obj = {
        userId: userId,
        organizationId: loggedInUserOrgId,
      };

      const response = await axiosHelperCore({
        url: `rapport/user/getDataRepositoryTags`,
        method: "POST",
        JSONData: obj,
      });

      if (response?.data?.result) {
        // setDataRepositoryTags(response?.data?.result);
        dispatch(
          setDataRepositoryFeatureEnabled(
            response?.data?.dataRepositoryFeatureEnabled
          )
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetchong content library tags:", error);
    }
  };

  useEffect(() => {
    if (userOrganizationId || loggedInUserOrgId) {
      console.log("calling useEffect for framework and custom table ");
      fetchReport();
      getDataRepositoryTags();
    }
  }, [userOrganizationId, loggedInUserOrgId]);

  useEffect(() => {
    if (userId) {
      userService.getCustomDataFields({ userId }).then((res) => {
        dispatch(customDataFieldsApiData(res));
      });
    }
  }, [userId]);

  useEffect(() => {
    let filteredData = membersData;

    let searchContext = searchValue.current;
    //alert(filterStatus);
    if (searchContext.trim() != "") {
      filteredData = filteredData.filter(
        (m) =>
          m?.firstName?.toLowerCase().includes(searchContext) ||
          m?.lastName?.toLowerCase().includes(searchContext) ||
          m?.email?.toLowerCase().includes(searchContext) ||
          m?.frameworkName?.toLowerCase().includes(searchContext) ||
          m?.contextName?.toLowerCase().includes(searchContext) ||
          m?.frameworkValue?.toLowerCase().includes(searchContext) ||
          m?.contextValue?.toLowerCase().includes(searchContext)
      );
      setFilteredMembers(filteredData);
    }

    if (filterStatus && filterStatus !== "All Framework/Context") {
      const splitStatus = filterStatus.split(":");
      const filterType = splitStatus[0]?.trim()?.toLowerCase();
      const filterLinkedinOrEmail = splitStatus[1]?.trim()?.toLowerCase();

      filteredData = filteredData.filter((item) => {
        const type = item.type?.toLowerCase();
        const linkedinOrEmail = item.linkedinOremail?.toLowerCase();
        return type === filterType && linkedinOrEmail === filterLinkedinOrEmail;
      });
    }

    setFilteredMembers(filteredData);
  }, [filterStatus]);

  useEffect(() => {
    seachDataPipe();
  }, [membersData]);

  useEffect(() => {
    if (contextPopupUrlParam) {
      setOpenContextAddPopup(false);
    }
  }, [contextPopupUrlParam]);

  const handleOpenAddModal = () => {
    navigate(`${pathname}?type=add`);
  };

  useEffect(() => {
    if (search && membersData.length) {
      const urlParams = new URLSearchParams(window.location.search);
      const paramValue = urlParams.get("contextId");
      const foundData = membersData.find((member) => member.id === paramValue);
      setShowModal(Boolean(foundData));
      setContextData(foundData);
    } else {
      setContextData(undefined);
      setShowModal(false);
    }
  }, [membersData, search]);

  return (
    <>
      <Box>
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
            open={true}
          >
            <MemoRevenoidLoader />
          </Backdrop>
        )}
        {tabView && (
          <Flex
            justifyContent={"flex-end"}
            marginBottom={2}
            position={"fixed"}
            top={"11.5%"}
            right={"3%"}
          >
            <Button onClick={handleOpenAddModal} variant="secondary">
              Add Framework/Context
            </Button>
          </Flex>
        )}

        {!tabView && (
          <Flex
            justifyContent={"space-between"}
            alignItems="center"
            marginBottom={"20px"}
            gap="20px"
          >
            <Flex>
              {/* <Text fontWeight="400" fontSize="32px">Framework/Context</Text> */}
              <SearchBar
                placeholder="Search via name/framework/context"
                onChange={handleSearchChange}
              />
            </Flex>
            <Flex justifyContent={"flex-end"}>
              <Text fontSize={"12px"} marginRight={"15px"} alignSelf={"center"}>
                Filter By :{" "}
              </Text>
              <Box display={"flex"} marginRight={"15px"}>
                <Select
                  placeholder="Type: e.g. Context:LinkedIn"
                  options={StatusValues}
                  onChange={(e) => setFilterStatus(e.value)}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "230px",
                      height: "48px",
                      border: "6px",
                      fontSize: "12px",
                    }),
                  }}
                />
              </Box>
              <Flex justifyContent={"flex-end"}>
                <Button
                  onClick={handleOpenAddModal}
                  variant="secondary"
                  fontSize={"14px"}
                  padding={"10px 10px"}
                >
                  Add Framework/Context
                </Button>
              </Flex>
            </Flex>
          </Flex>
        )}

        <FrameworkAndContextTableHeader />
        <FrameworkAndContextTableBody
          data={filteredMembers}
          fetchData={fetchReport}
        />
        <Flex
          borderBottomLeftRadius={"8px"}
          borderBottomRightRadius={"8px"}
          background={"#fff"}
          height={"10px"}
        ></Flex>

        {(new URLSearchParams(search).get("type") === "add" || showModal) && (
          <FrameworkAndContextAddUpdate
            show={true}
            setShow={() => {
              navigate(pathname);
              setShowModal(false);
            }}
            data={contextData}
            refetchData={() => {
              fetchReport();
            }}
          />
        )}
      </Box>
    </>
  );
}

export default FrameworkAndContextTable;
