import React, { useMemo, useState } from "react";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { CustomDataContainer, MessageContainer } from "./style";
import SelectInput from "./components/SelectPrompt";
import { useFormikContext } from "formik";
import SelectRadio from "./components/SelectRadio";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import TagEditor from "../TagEditor";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ICustomData } from "../custom.interface";
import { Box, Paper, Skeleton, TextField, Tooltip } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CustomDataTestComponent from "./CustomDataTestComponent";
import _ from "lodash";
import { useAppSelector } from "hooks";
import Markdown from "react-markdown";
import { axiosHelperCore } from "utils";
import remarkBreaks from "remark-breaks";
import * as yup from "yup";

interface CustomDataComponentProps {
  index: number;
  handleRemove?: () => void;
  showRemoveButton?: boolean;
  customData: ICustomData | any;
  isUpdate?: boolean;
}

const CustomDataComponent: React.FC<CustomDataComponentProps> = ({
  index,
  handleRemove,
  showRemoveButton,
  customData,
  isUpdate,
}) => {
  const disabledAllFields = customData.prompt.value !== "New";

  const { values, setFieldValue, handleChange, setValues, errors, touched } =
    useFormikContext() as any;
  const customDataFields = useAppSelector(
    (state) => state.frameworkAndContextState.customDataFieldsApiData
  );

  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [oldValues, setOldValue] = useState<any>();

  const promptOptions = useMemo(() => {
    const predefinedNames = {
      "User Gem": "UserGems",
    };

    return [{ value: "New", label: "New" }].concat(
      customDataFields?.predefinedPrompt?.map((prompt) => ({
        value: prompt.name,
        label: predefinedNames[prompt.name] || prompt.name,
      }))
    );
  }, [customDataFields]);

  const promptTypeOptions = [
    { label: "Fixed", value: "fixed" },
    { label: "Dynamic", value: "dynamic" },
  ];

  const dataSourceOptions = [
    { label: "Content Library", value: "contentLibrary" },
    { label: "Call Transcripts", value: "callTranscripts" },
  ];

  const handleAutoFillData = (value: any) => {
    const customDataArray = [...(values as any).customData];
    if (value.value === "New") {
      customDataArray[index] = {
        prompt: value,
        dataSource: "",
        promptType: "",
        query: "",
      };
      setValues({
        ...(values as any),
        customData: customDataArray,
      });
    } else {
      const customFieldValues = customDataFields.predefinedPrompt.find(
        (predefinedPrompt) => {
          return predefinedPrompt.name === value.value;
        }
      );
      if (customFieldValues)
        customDataArray[index] = {
          prompt: value,
          dataSource: customFieldValues.dataSource,
          promptType: customFieldValues.queryType,
          query: customFieldValues.query,
        };

      setValues({
        ...(values as any),
        customData: customDataArray,
      });
    }
  };

  const generateCustomData = async (payload) => {
    try {
      setIsLoading(true);
      const obj = { payload };
      const response = await axiosHelperCore({
        url: `rapport/user/generateCustomData`,
        method: "POST",
        JSONData: obj,
      });

      setIsLoading(false);
      return response?.data;
    } catch (error) {
      console.error("Error calling ML API:", error);
    }
  };

  const onSubmit = async (formValues) => {
    try {
      const valiationSchema = yup.object({
        prompt: yup
          .object({
            value: yup.string().required("Prompt value is required"),
            label: yup.string().required("Prompt label is required"),
          })
          .required("Prompt is required"),
        dataSource: yup.string().required("Data source is required"),
        document: yup
          .array()
          .of(yup.mixed()) // Adjust type if needed
          .when(["prompt.value", "dataSource"], {
            is: (promptValue, dataSource) => {
              return promptValue === "New" && dataSource === "contentLibrary";
            },
            then: (schema) =>
              schema.min(1, "At least one document is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
        promptType: yup.string().required("Prompt type is required"),
        query: yup.string().required("Query is required"),
        promptName: yup.string().when("prompt.value", {
          is: "New",
          then: (schema) => schema.required("Prompt name is required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      });

      await valiationSchema.validate(customData, {
        abortEarly: false,
      });

      setValidationErrors([]);

      const queryDetails = [
        {
          query: customData?.query,
          dataSource: customData?.dataSource, // content library, call transcript
          variables: Object.entries(formValues).map(([key, value]) => ({
            key,
            value,
          })),
          name:
            customData.prompt.value === "New"
              ? customData.promptName
              : customData?.prompt?.value || "",
          tags: [],
          queryType: customData?.promptType,
          documents: customData?.document?.find(
            (document) => document?.value === "all"
          )
            ? []
            : customData?.document?.map((document) => document.value) || [],
        },
      ];

      console.log({
        h1: oldValues,
        h2: queryDetails[index],
        h3: !_.isEqual(oldValues, queryDetails[index]),
      });

      let payload = {
        userId: values?.userId,
        refId: values.contextId, // uuid of context/framework
        refIdType: "context", // context/framework as a string
        forceRefresh: oldValues
          ? !_.isEqual(oldValues, queryDetails[index])
          : !!isUpdate || false,
        queryDetails,
      };

      setFieldValue("isPublic", true);

      setOldValue(queryDetails?.[index]);
      const preview: any = await generateCustomData(payload);
      const output = preview?.result?.[index]?.customData;
      console.log({ preview });

      if (!preview?.success) {
        setValidationErrors([preview?.result?.customData]);
      } else {
        setValidationErrors([]);
      }
      setFieldValue(`customData[${index}].customData`, {
        query: customData?.query,
        output,
      });
    } catch (e) {
      if (e?.inner?.length) {
        setValidationErrors(e?.inner?.map((e) => e?.message) || []);
      }
      console.log("onSubmit is called", e);
    }
    // Extract tags using matchAll
  };

  const handleGenerateQuery = () => {
    onSubmit({});
  };

  return (
    <CustomDataContainer>
      <Flex justifyContent={"space-between"}>
        {/* <Text variant="heading" fontWeight="600" marginBottom={2} ml={}>
          Prompted Data
        </Text> */}

        {showRemoveButton && (
          <IconButton onClick={handleRemove} aria-label="delete" size="small">
            <DeleteIcon color="error" fontSize="inherit" />
          </IconButton>
        )}
      </Flex>

      <SelectInput
        title="Select Prompt"
        placeholder={"Select Prompt Type"}
        options={promptOptions}
        name={`customData[${index}].prompt`}
        value={(values as any)?.customData?.[index].prompt}
        errors={errors}
        handleChange={(value: any) => {
          handleAutoFillData(value);
          // setFieldValue(`customData[${index}].prompt`, value);
        }}
        custom
      />

      {["New"].includes((values as any)?.customData?.[index].prompt.value) && (
        <>
          <Text
            variant="bodyRegular"
            fontWeight="600"
            paddingBottom="5px"
            marginTop={"10px"}
          >
            Prompt Name
            <span style={{ color: "red", marginLeft: "5px" }}>*</span>
          </Text>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            name={`customData[${index}].promptName`}
            placeholder="Enter a name for your prompt"
            onChange={handleChange}
            sx={{ backgroundColor: "#FFF" }}
            value={(values as any)?.customData?.[index].promptName}
            error={
              _.get(errors, `customData[${index}].promptName`) &&
              _.get(touched, `customData[${index}].promptName`)
            }
          />
          {_.get(errors, `customData[${index}].promptName`) &&
            _.get(touched, `customData[${index}].promptName`) && (
              <Text color={"red"} fontSize={"12px"}>
                {_.get(errors, `customData[${index}].promptName`)}
              </Text>
            )}
        </>
      )}
      <div style={{ height: "8px" }} />
      <SelectRadio
        title="Select Data Source"
        required
        options={dataSourceOptions}
        value={(values as any)?.customData?.[index].dataSource}
        name={`customData[${index}].dataSource`}
        disabled={disabledAllFields}
      />

      {["contentLibrary"].includes(
        (values as any)?.customData?.[index].dataSource
      ) &&
        ["New"].includes((values as any)?.customData?.[index].prompt.value) && (
          <SelectInput
            required
            title="Select Document"
            options={
              customDataFields?.allFileNameofContentLibrary?.map((str) => ({
                value: str,
                label: str,
              })) || []
            }
            name={`customData[${index}].document`}
            value={(values as any)?.customData?.[index].document}
            handleChange={(value) =>
              setFieldValue(`customData[${index}].document`, value)
            }
            errors={errors}
            isMulti={true}
            disabled={disabledAllFields}
            touched={touched}
          />
        )}

      <SelectRadio
        required
        title="Select Prompt Type"
        options={promptTypeOptions}
        value={(values as any)?.customData?.[index].promptType}
        name={`customData[${index}].promptType`}
        disabled={disabledAllFields}
        handleValuesChange={() => {
          setFieldValue(`customData[${index}].query`, "");
        }}
        extraIcon={
          <Tooltip
            placement="right"
            title={
              <p>
                Choosing the 'Dynamic' option allows the selection of variables
                in the prompt which will be filled based on a pre-defined
                criteria when the user transitions between prospects/accounts
                and the prompted data changes dynamically as a result <br />
                Choosing the 'Fixed' option allows the curation of a simple
                natural language prompt without variables and the prompted data
                generated will not change and remain static across all
                prospects/accounts
              </p>
            }
          >
            <HelpOutlineOutlinedIcon
              sx={{ fontSize: "16px", color: "gray", marginLeft: "5px" }}
            />
          </Tooltip>
        }
      />

      <Flex
        padding={"3px"}
        marginBottom={3}
        flexDirection="column"
        alignItems={"center"}
        gap="5px"
      >
        <div style={{ width: "100%" }}>
          <Text
            variant="bodyRegular"
            fontWeight={"600"}
            paddingLeft={"5px"}
            paddingTop={"10px"}
            marginBottom={"-5px"}
          >
            Add Prompt
            <span style={{ color: "red", marginLeft: "5px" }}>*</span>
          </Text>
          <TagEditor
            customData={customData}
            disabled={disabledAllFields}
            isDynamic={Boolean(
              (values as any)?.customData?.[index].promptType === "dynamic"
            )}
            variables={customDataFields.variables}
            value={(values as any)?.customData?.[index].query}
            setValue={(value) => {
              setFieldValue(`customData[${index}].query`, value);
            }}
            handleGenerateQuery={handleGenerateQuery}
          />
          {_.get(errors, `customData[${index}].query`) &&
            _.get(touched, `customData[${index}].query`) && (
              <Text color={"red"} fontSize={"12px"}>
                {_.get(errors, `customData[${index}].query`)}
              </Text>
            )}

          {_.get(errors, `customData[${index}].customData`) &&
            _.get(touched, `customData[${index}].customData`) && (
              <Text color={"red"} fontSize={"12px"}>
                {typeof _.get(errors, `customData[${index}].customData`) ===
                "string"
                  ? _.get(errors, `customData[${index}].customData`)
                  : _.get(errors, `customData[${index}].customData.output`)}
              </Text>
            )}
        </div>
      </Flex>

      {validationErrors.map((err, i) => (
        <p key={err} style={{ fontSize: "12px", color: "red" }}>
          {i + 1}. {err}
        </p>
      ))}

      {customData?.promptType === "dynamic" && (
        <CustomDataTestComponent customData={customData} onSubmit={onSubmit} />
      )}

      {(isLoading || customData?.customData?.output) && (
        <Paper sx={{ padding: "10px" }} elevation={0}>
          <Box my={"10px"}>
            {isLoading && (
              <Box>
                <Skeleton />
                <Skeleton />
                <Skeleton width={"75%"} />
              </Box>
            )}
            <MessageContainer>
              <Markdown remarkPlugins={[remarkBreaks]}>
                {customData?.customData?.output}
              </Markdown>
            </MessageContainer>
          </Box>
        </Paper>
      )}
    </CustomDataContainer>
  );
};

export default CustomDataComponent;
