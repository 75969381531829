import FrameworkAndContextTableBodyField from "./FrameworkAndContextTableBodyField";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import React from "react";

function FrameworkAndContextTableBody({ data, fetchData }) {
  if (!Array.isArray(data) || data.length === 0) {
    console.log("Array not found", data);
    return (
      <Box background="#fff">
        <Flex justifyContent="center" alignItems="center" height="100px">
          <span>No data available</span>
        </Flex>
      </Box>
    );
  }

  const refetchData = () => {
    fetchData();
  };

  return (
    <>
      <Box background="#fff" className="table-data-framework-context">
        {data.map((item, index) => {
          return (
            <FrameworkAndContextTableBodyField
              key={index}
              index={index}
              item={item}
              reFetch={refetchData}
            />
          );
        })}
      </Box>
    </>
  );
}

export default FrameworkAndContextTableBody;
