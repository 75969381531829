import React, { useState } from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { ToggleButton } from "@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import userService from "services/userService";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { Backdrop, Tooltip, Box as MuiBox, Box } from "@mui/material";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import EditButton from "./component/EditButton";
import DeleteButton from "./component/DeleteButton";
import EyeIcon from "./component/EyeIcon";
import FrameworkAndContextAddUpdate from "./FrameworkAndContextAddUpdate";
import { useAppSelector } from "hooks";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";
import { useLocation, useNavigate } from "react-router-dom";

const showMoreLessLinkStyle = {
  color: "rgb(0, 148, 227)",
  marginLeft: "10px",
};

function FrameworkAndContextTableBodyField({ index, item, reFetch }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [isAIVisibilityEnabled, setIsAIVisibilityEnabled] = useState(
    item?.isExtensionVisibilityEnabled ?? true
  );
  const [hide, setHide] = useState(true);
  const [associtaedUserName, setAssociatedUserName] = useState([]);

  const [showMore, setShowMore] = useState(false);
  const maxLength = 50;

  const organizationUserData = useAppSelector(
    (state) => state.memberListingState.memberListingData
  );

  const content =
    item.type === "framework" ? item?.frameworkValue : item?.contextValue;
  const displayContent = showMore ? content : content?.slice(0, maxLength);

  const toggleAIVisibility = async (values) => {
    setIsAIVisibilityEnabled((prev) => !prev);

    setLoading(true);
    let dataObj = {};
    if (values?.type === "framework") {
      dataObj = {
        userId: values?.userId,
        type: "framework",
        frameworkId: values?.id,
        isAIVisibilityEnabled:
          values?.isExtensionVisibilityEnabled !== undefined
            ? !values.isExtensionVisibilityEnabled
            : !isAIVisibilityEnabled,
      };
    } else {
      dataObj = {
        userId: values?.userId,
        type: "context",
        contextId: values?.id,
        isAIVisibilityEnabled:
          values?.isExtensionVisibilityEnabled !== undefined
            ? !values.isExtensionVisibilityEnabled
            : !isAIVisibilityEnabled,
      };
    }

    try {
      const updateMember = await userService.updateAIVisibility(dataObj);
      if (updateMember?.success) {
        reFetch();
        setLoading(false);
      } else {
        setSnackbarMessage(
          updateMember?.result?.message || "Error Changing AI Sequence Status"
        );
        setShowSnackBar(true);
        setSnackbarType("error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error Changing AI Sequence Status", error);
      setLoading(false);
    }
  };

  const handlePublicPrivateStatus = async (updatedStatus, id, type, userId) => {
    setLoading(true);
    let dataObj = {};
    if (type === "framework") {
      dataObj = {
        userId: userId,
        frameworkId: id,
        contextId: null,
        isPublic: updatedStatus,
        type: type,
      };
    } else {
      dataObj = {
        userId: userId,
        frameworkId: null,
        contextId: id,
        isPublic: updatedStatus,
        type: type,
      };
    }

    try {
      const updateMember = await userService.updateFrameworkOrContextStatus(
        dataObj
      );
      if (updateMember?.success) {
        reFetch();
        setLoading(false);
        setSnackbarMessage("Status Changed");
        setShowSnackBar(true);
        setSnackbarType("success");
      } else {
        setSnackbarMessage(
          updateMember?.result?.message || "Error Changing Status"
        );
        setShowSnackBar(true);
        setSnackbarType("error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating member", error);
      setLoading(false);
    }
  };

  const deleteData = async (values) => {
    const obj = {
      id: values.objectId,
      userId: values.userId,
      name: values.name,
      action: "delete",
    };
    if (values.type === "framework") {
      obj["framework"] = values.content;
      obj["frameworkId"] = values.id;
      obj["type"] = "framework";
      const updateFramework = await userService.updateOrDeleteFramework(obj);
      updatingMessage(updateFramework, true);
    } else {
      obj["context"] = values.content;
      obj["contextId"] = values.id;
      obj["type"] = "context";
      const updateContext = await userService.updateOrDeleteContext(obj);
      updatingMessage(updateContext, true);
    }
  };

  const updatingMessage = (msg, isDeleted = false) => {
    if (msg?.success) {
      reFetch();
      setLoading(false);
      setSnackbarMessage(
        isDeleted ? "Remove Framework/Context" : "Framework/Context Updated"
      );
      setShowSnackBar(true);
      setSnackbarType("success");
    } else {
      setSnackbarMessage(msg?.result?.message || msg?.message);
      setShowSnackBar(true);
      setSnackbarType("error");
      setLoading(false);
    }
    setHide(true);
  };

  const deletedFrameworkAndContext = () => {
    const matchedSettingUsers = organizationUserData.filter(
      (member) =>
        member.defaultSettings?.email?.contextId === item?.id ||
        member.defaultSettings?.callprep?.contextId === item?.id ||
        member.defaultSettings?.linkedin?.contextId === item?.id ||
        member.defaultSettings?.email?.frameworkId === item?.id ||
        member.defaultSettings?.callprep?.frameworkId === item?.id ||
        member.defaultSettings?.linkedin?.frameworkId === item?.id
    );

    const settingUserNames = matchedSettingUsers.map(
      (member) => `${member.firstName} ${member.lastName}`
    );

    const uniqueUserNames = [...new Set(settingUserNames)];
    setAssociatedUserName(uniqueUserNames);

    setHide(false);
  };

  return (
    <>
      <SnackBar
        iconName={"info"}
        message={snackbarMessage}
        setShowSnackBar={setShowSnackBar}
        show={showSnackBar}
        timeout={4000}
        anchor={"top-right"}
        type={snackbarType}
      />
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
          open={true}
        >
          <MemoRevenoidLoader />
        </Backdrop>
      )}
      <Flex
        padding={"15px"}
        fontSize="12px"
        fontWeight={500}
        position="sticky"
        backgroundColor={index % 2 === 0 ? "#FFF" : "rgb(249, 249, 249)"}
        alignItems={"center"}
        borderRadius={index % 2 !== 0 && "12px"}
        margin="0 16px"
      >
        <Flex padding={"0 5px"} textWrap="balance" width="5%">
          {index + 1}
        </Flex>

        <Flex
          style={{ wordBreak: "break-word" }}
          padding={"0 5px"}
          width="15%"
          gap={"15px"}
        >
          <Flex width="80%">
            {item?.type === "framework"
              ? item?.frameworkName
              : item?.contextName}
          </Flex>
          {!!item?.queryDetails?.length && (
            <Flex width="20%">
              <Tooltip
                title={item?.queryDetails[0]?.query || ""}
                placement="top"
                arrow
              >
                <MuiBox
                  component="span"
                  sx={{
                    position: "absolute",
                    top: "-5px",
                    right: "-5px",
                    padding: "2px 4px",
                    backgroundColor: "#E8E9FD",
                    border: "1px solid #8d68f6",
                    borderRadius: "15px",
                    fontSize: "10px",
                    color: "#8d68f6",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#8d68f6",
                      color: "#FFFFFF",
                      transition: "background-color 0.3s ease",
                    },
                  }}
                >
                  Custom
                </MuiBox>
              </Tooltip>
            </Flex>
          )}
        </Flex>
        <Flex
          style={{ wordBreak: "break-word" }}
          padding={"0 5px"}
          width="10%"
          flexDirection={"column"}
        >
          <Tag
            color={item.type === "framework" ? "#60C678" : "#0094E3"}
            bgColor={item.type === "framework" ? "#EFF9F1" : "#DCF9FF"}
            text={`${item.type} : ${item.linkedinOremail || "N/A"}`}
          />
        </Flex>
        <Flex padding={"0 5px"} width="5%"></Flex>
        <Flex style={{ wordBreak: "break-word" }} padding={"0 5px"} width="20%">
          <Text>
            {displayContent}
            {content?.length > maxLength && !showMore && "..."}
            {content?.length > maxLength && (
              <>
                {showMore && (
                  <span
                    onClick={() => setShowMore(!showMore)}
                    style={showMoreLessLinkStyle}
                  >
                    view less
                  </span>
                )}
                {!showMore && (
                  <span
                    onClick={() => setShowMore(!showMore)}
                    style={showMoreLessLinkStyle}
                  >
                    view more
                  </span>
                )}
              </>
            )}
          </Text>
        </Flex>
        <Flex padding={"0 5px"} width="10%">
          <Tooltip
            title={
              item?.isExtensionVisibilityEnabled ?? true
                ? "Can be visible at Extension"
                : "Not visible at Extension"
            }
            placement="bottom"
            arrow
          >
            <div onClick={() => toggleAIVisibility(item)}>
              <EyeIcon
                isAIVisibilityEnabled={
                  item?.isExtensionVisibilityEnabled ?? true
                }
              />
            </div>
          </Tooltip>
        </Flex>
        <Flex padding={"0 5px"} width="10%">
          <ToggleButton
            variant="tertiary"
            value={item?.isPublic}
            name="isActive"
            onChange={(value) =>
              handlePublicPrivateStatus(
                value,
                item?.id,
                item?.type,
                item.userId
              )
            }
          />
        </Flex>
        <Flex
          style={{ wordBreak: "break-word" }}
          padding={"0 5px"}
          width="15%"
          flexDirection={"column"}
        >
          <Flex style={{ textTransform: "capitalize" }} mb={"5px"}>
            {item.firstName} {item.lastName}
          </Flex>
          <Flex>{item.email}</Flex>
        </Flex>
        <Flex padding={"0 5px"} width="10%" gap="10px">
          <EditButton
            onClick={() => {
              if (item.type === "context") {
                navigate(`${pathname}?contextId=${item.id}`);
              } else {
                setShowEdit(true);
              }
            }}
          />
          <DeleteButton onClick={() => deletedFrameworkAndContext()} />
        </Flex>
      </Flex>

      {showEdit && (
        <FrameworkAndContextAddUpdate
          show={showEdit}
          data={item}
          setShow={(value) => setShowEdit(value)}
          refetchData={reFetch}
        />
      )}

      <ModalWrapper
        width="max-content"
        height="max-content"
        hideModal={hide}
        setHideModal={() => {
          setHide(true);
        }}
        bgcolor={"rgba(0, 0, 0, 0.8)"}
        padding="40px"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {associtaedUserName.length > 0 && (
            <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
              <Text fontSize={"16px"} fontWeight={300} maxWidth={"auto"}>
                {" "}
                The context and framework are set as default settings by&nbsp;
                {associtaedUserName.length > 3 ? (
                  <span style={{ fontWeight: "bold" }}>
                    {associtaedUserName.slice(0, 3).join(", ")} {`and`}
                    <Box
                      component={"a"}
                      // href="#"
                      style={showMoreLessLinkStyle}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {`+${associtaedUserName.length - 3} more`}
                    </Box>
                  </span>
                ) : (
                  <span style={{ fontWeight: "bold" }}>
                    {associtaedUserName.join(", ")}
                  </span>
                )}
                <br />
                <span>
                  Please get in touch with them before making any changes.
                </span>
              </Text>
            </div>
          )}
          {associtaedUserName.length === 0 && (
            <Text fontSize={"16px"} fontWeight={300}>
              Are you sure you want to remove this {item.type} ?
            </Text>
          )}

          <Flex mt="15px">
            {associtaedUserName.length === 0 && (
              <Flex
                background={"#DD015B"}
                color={"#fff"}
                fontSize={"16px"}
                fontWeight={500}
                p="10px 32px"
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"12px"}
                width={"max-content"}
                cursor="pointer"
                onClick={() => deleteData(item)}
              >
                Yes
              </Flex>
            )}
            <Flex
              ml="16px"
              border="1px solid #0094E3"
              background={"#fff"}
              color={"#0094E3"}
              fontSize={"16px"}
              fontWeight={500}
              p="10px 32px"
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"12px"}
              width={"max-content"}
              cursor="pointer"
              onClick={() => setHide(true)}
            >
              Cancel
            </Flex>
          </Flex>
        </Flex>
      </ModalWrapper>
    </>
  );
}

export default FrameworkAndContextTableBodyField;
