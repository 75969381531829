import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
function ManagePersonasHeader() {
    return (
            <Flex
                padding={"10px 15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={"#F8F8F8"}
                alignItems={"center"}
                borderTopLeftRadius={"8px"}
                borderTopRightRadius={"8px"}
              >
                <Flex padding={"0 5px"} width="5%" >
                    #
                </Flex>
              
                <Flex padding={"0 5px"} width="20%" >
                    ICP and Persona Name
                </Flex>
                <Flex padding={"0 5px"} width="22%" >
                    Mapped Context
                </Flex>
                <Flex padding={"0 5px"} width="13%" >
                    Updated By
                </Flex>
                <Flex padding={"0 5px"} width="12%" >
                    Updated On
                </Flex>
                <Flex padding={"0 5px"} width="15%" >
                    Activate/Deactivate
                </Flex>
                <Flex padding={"0 5px"} width="13%" >
                    Actions
                </Flex>
            </Flex>
    );
}

export default ManagePersonasHeader;