import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
function SettingDataHeader({ autoPersonaSelectioncheck }) {
    return (
        <>
            <Flex
                padding={"10px 15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={"#F8F8F8"}
                alignItems={"center"}
                borderTopLeftRadius={"8px"}
                borderTopRightRadius={"8px"}
            >
                <Flex padding={"0 5px"} width="5%" >
                    #
                </Flex>

                <Flex padding={"0 5px"} width="15%" >
                    Sequence Name <br />(No. of Steps)
                </Flex>
                <Flex padding={"0 5px"} width={autoPersonaSelectioncheck ? "12%" : "8%"} >
                    AI SDR
                </Flex>
                {autoPersonaSelectioncheck &&
                    <Flex padding={"0 5px"} width={autoPersonaSelectioncheck && "8%"} >
                        Auto Persona Selection
                    </Flex>}
                <Flex padding={"0 5px"} width="10%" >
                    Integration Type
                </Flex>
                <Flex padding={"0 5px"} width={autoPersonaSelectioncheck ? "9%" : "13%"} >
                    Context Visibility
                </Flex>
                <Flex padding={"0 5px"} width="18%" >
                    Owned By<br />
                    (Activated On)
                </Flex>
                {/* <Flex padding={"0 5px"} width="10%" >
                    Created On
                </Flex> */}
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="12%" >
                    Activate/Deactivate
                </Flex>
                <Flex padding={"0 5px"} width="15%" >
                    Actions
                </Flex>
            </Flex>

        </>
    );
}

export default SettingDataHeader;