import React from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import Select from "react-select";
import _ from "lodash";

interface SelectInputProps {
  name: string;
  title: string;
  options: { label: any; value: any }[];
  defaultValue?: { label: string; value: any } | undefined;
  handleChange: (value: any) => void;
  value: any;
  placeholder?: string;
  isMulti?: boolean;
  disabled?: boolean;
  errors: any;
  required?: boolean;
  touched?: any;
  custom?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = ({
  name,
  title,
  options,
  defaultValue,
  handleChange,
  value,
  placeholder,
  isMulti,
  disabled,
  errors,
  required,
  touched,
  custom,
}) => {
  console.log("value11", value);

  const CustomOption = (props) => {
    const { data, isSelected, innerRef, innerProps } = props;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          padding: "10px",
          background: isSelected ? "#ddd" : "#fff",
          cursor: "pointer",
        }}
      >
        {/* Customize the rendering */}
        <strong style={{ color: data.label !== "New" ? "#4d4d4f" : "black" }}>
          {data.label}
        </strong>{" "}
        {data.label !== "New" && (
          <span style={{ color: "gray" }}>(system generated)</span>
        )}
      </div>
    );
  };

  return (
    <Flex flexDirection="column" paddingY="5px">
      <Text variant="bodyRegular" fontWeight="600" paddingBottom="5px">
        {title}
        {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
      </Text>
      <Select
        className="single-select"
        styles={{
          container: (provided) => ({
            ...provided,
            width: "100%",
            height: "initial",
          }),
          menu: (provided) => ({ ...provided, width: "100%" }),
        }}
        placeholder={placeholder}
        name={name}
        options={
          isMulti
            ? value.find((v) => v?.value === "all")
              ? [{ label: "Select All", value: "all" }]
              : [{ label: "Select All", value: "all" }].concat(options)
            : options
        }
        defaultValue={defaultValue}
        onChange={(value) => {
          handleChange(
            isMulti
              ? value.find((v) => v?.value === "all")
                ? [{ label: "Select All", value: "all" }]
                : value
              : value
          );
        }}
        isMulti={isMulti}
        value={value}
        isDisabled={disabled}
        components={custom ? { Option: CustomOption } : {}}
      />
      {_.get(errors, name) && _.get(touched, name) && (
        <Text color={"red"} fontSize={"12px"}>
          {_.get(errors, name)}
        </Text>
      )}
    </Flex>
  );
};

export default SelectInput;
