import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { SettingCard, Tags } from "containers/MemberListing/component/subcomponent/Style";
import { Box as Box1, Typography, TextareaAutosize, FormControl } from '@mui/material';
import KeywordTagManager from "containers/MemberListing/component/subcomponent/KeywordTagManager";
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import { Backdrop, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useEffect, useState, useCallback, useMemo } from "react";
import userService from "services/userService";
import { useAppSelector } from 'hooks';
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { axiosHelperCore } from "utils";
import Select, {components} from 'react-select';

function SettingContent() {

    const MAX_LENGTH_FOR_PERSONA = 150;
    const [trackingLoading, setTrackingLoading] = useState(false);
    const [hiringLoading, setHiringLoading] = useState(false);
    const [factLoading, setFactLoading] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [loading, setLoading] = useState(false);
    const [trackingKeywordsUpdatedOn, setTrackingKeywordsUpdatedOn] = useState("");
    const [hiringKeywordsUpdatedOn, setHiringKeywordsUpdatedOn] = useState("");
    const [factKeywordsUpdatedOn, setFactKeywordsUpdatedOn] = useState("");
    const [viewMemberOrgId, setViewMemberOrgId] = useState(null);
    const [userInfoData, setUserInfoData] = useState({ pitch: "", persona: "" });
    const [pitch, setPitch] = useState("");
    const [persona, setPersona] = useState("");
    const [isEditingPitch, setIsEditingPitch] = useState(false);
    const [isEditingPersona, setIsEditingPersona] = useState(false);
    const [stageList, setStageList] = useState([]);
    const [selectedStages, setSelectedStages] = useState([]);
    const [isFrameworkSelection, setIsFrameworkSelection] = useState(false);
    const [memberData, setMemberData] = useState([]);
    const [userList, setUserList] = useState([]);
    const [isEditingMembers, setIsEditingMembers] = useState(false);
    const [showRemoveWarning, setShowRemoveWarning] = useState(false);
    const [userToRemove, setUserToRemove] = useState([]);

    const loggedInMemberDetails = useAppSelector((state) => state.profileState.profileData);

    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 999 }),
        menu: (base) => ({
            ...base,
            maxHeight: 400,
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: 400,
            overflowY: 'auto',
        }),
    };

    interface KeywordData {
        trackingKeywords: string[];
        hiringKeywords: string[];
        factKeywords: string[];
        trackingKeywordsUpdatedOn?: string;
        hiringKeywordsUpdatedOn?: string;
        factKeywordsUpdatedOn?: string;
        trackingKeywordsUpdatedBy?: string;
        hiringKeywordsUpdatedBy?: string;
        factKeywordsUpdatedBy?: string;
        trackingLimit?: number;
        hiringLimit?: number;
        factLimit?: number;
    }

    const [keywordArray, setKeywordArray] = useState<KeywordData>({
        trackingKeywords: [],
        hiringKeywords: [],
        factKeywords: [],
    });

    const countWords = (str) => {
        str = str?.trim();
        let words = str?.split(/\s+/);
        return words?.length;
    };

    useEffect(() => {
        const hiringdate = new Date(keywordArray?.hiringKeywordsUpdatedOn);
        const trackingdate = new Date(keywordArray?.trackingKeywordsUpdatedOn);
        const factdate = new Date(keywordArray?.factKeywordsUpdatedOn);

        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];


        const dayhiring = hiringdate.getDate();
        const daytracking = trackingdate.getDate();
        const dayfact = factdate.getDate();

        const monthhiring = monthNames[hiringdate.getMonth()];
        const monthtracking = monthNames[trackingdate.getMonth()];
        const monthfact = monthNames[factdate.getMonth()];

        const resulthiring = `${dayhiring} ${monthhiring}`;
        const resulttracking = `${daytracking} ${monthtracking}`;
        const resultfact = `${dayfact} ${monthfact}`;

        setTrackingKeywordsUpdatedOn(resulttracking);
        setHiringKeywordsUpdatedOn(resulthiring);
        setFactKeywordsUpdatedOn(resultfact);


    }, [keywordArray])

    const fetchCompanyKeywords = async (orgId) => {
        setLoading(true);
        try {
            const obj = { organizationId: orgId };
            userService.companyKeywords(obj)
                .then((response) => {
                    setKeywordArray(response?.data);
                })
        }
        catch (error) {
            console.error("Error fetching company list", error);
        }
        setLoading(false);
    };

    const getUserInfoApi = async (userId = "") => {
        setLoading(true);
        try {
            const obj = { userId: loggedInMemberDetails?.userId, organizationId: viewMemberOrgId };
            userService.getUserInfoData(obj)
                .then((response) => {
                    setUserInfoData(response);
                    setPitch(response?.pitch);
                    setPersona(response?.persona);
                    setSelectedStages(response?.meetingTags);
                    setIsFrameworkSelection(response?.isFrameworkSelection);
                })
        }
        catch (error) {
            console.error("Error fetching company list", error);
        }
        setLoading(false);
    };

    const handleSaveCompanyKeywords = async (keywords) => {
        setTrackingLoading(true);
        setLoading(true);
        const obj = {
            "organizationId": viewMemberOrgId,
            "keywords": keywords,
        };
        try {
            userService.saveCompanyKeywords(obj)
                .then((response) => {
                    fetchCompanyKeywords(viewMemberOrgId);
                    setTrackingLoading(false);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
            setTrackingLoading(false);
            setLoading(false);
        }
    }

    const handleDelete = (word) => {
        setLoading(true);
        setTrackingLoading(true);
        const obj = {
            "organizationId": viewMemberOrgId,
            "keywords": keywordArray?.trackingKeywords?.filter(item => item !== word),
        };
        try {
            userService.saveCompanyKeywords(obj)
                .then((response) => {
                    fetchCompanyKeywords(viewMemberOrgId);
                    setLoading(false);
                    setTrackingLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
            setLoading(false);
            setTrackingLoading(false);
        }
    }

    const handleFactSubmit = async (keywords) => {
        setFactLoading(true);
        setLoading(true);
        const obj = {
            "organizationId": viewMemberOrgId,
            keywords
        };
        try {
            userService.saveFactKeywords(obj)
                .then((response) => {
                    fetchCompanyKeywords(viewMemberOrgId);
                    setFactLoading(false);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
            setFactLoading(false);
            setLoading(false);
        }
    }

    const handleFactKeywordDelete = (word) => {
        setFactLoading(true);
        setLoading(true);
        const obj = {
            "organizationId": viewMemberOrgId,
            "keywords": keywordArray?.factKeywords?.filter(item => item !== word),
        };
        try {
            userService.saveFactKeywords(obj)
                .then((response) => {
                    fetchCompanyKeywords(viewMemberOrgId);
                    setFactLoading(false);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
            setFactLoading(false);
            setLoading(false);
        }
    }

    const handleJobSubmit = async (jobs) => {
        setHiringLoading(true);
        setLoading(true);
        const obj = {
            "organizationId": viewMemberOrgId,
            "keywords": jobs,
        };
        try {
            userService.saveHiringKeywords(obj)
                .then((response) => {
                    fetchCompanyKeywords(viewMemberOrgId);
                    setHiringLoading(false);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
            setHiringLoading(false);
            setLoading(false);
        }
    }

    const handleHiringKeywordDelete = (word) => {
        setHiringLoading(true);
        setLoading(true);
        const obj = {
            "organizationId": viewMemberOrgId,
            "keywords": keywordArray?.hiringKeywords?.filter(item => item !== word),
        };
        try {
            userService.saveHiringKeywords(obj)
                .then((response) => {
                    fetchCompanyKeywords(viewMemberOrgId);
                    setHiringLoading(false);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
            setHiringLoading(false);
            setLoading(false);
        }
    }

    const getMemberList = async (organizationId = "") => {
        setLoading(true);
        const members = await axiosHelperCore({
            url: `rapport/user/getOranizationMemberList`,
            method: "POST",
            JSONData: { organizationId: organizationId ? organizationId : loggedInMemberDetails.organizationId }
        });
        let memberList = members?.data?.data?.map(m => ({ value: m.userId, label: m.firstName + " " + m.lastName + " <" + m.email + ">" }));
        memberList.unshift({ value: "-1", label: "Select Member" })
        setMemberData(memberList);
        let frameworkEnabledList = 
            members?.data?.data
                .filter((item) => item.frameworkSelectionEnabled === true)
                .map((item) => item.userId);
        setUserList(frameworkEnabledList);
        setLoading(false);
    };

    const handleSelectedMemberChange = (selectedOptions) => {
        if (selectedOptions.length === 0 && userList.length > 0) {
            setShowRemoveWarning(true);
            setUserToRemove(userList);
        } else {
            setUserList(selectedOptions.filter(option => option.value !== "-1").map(option => option.value));
            setIsEditingMembers(true);
        }
    };

    const saveMemberSelection = async (newUserList = null) => {
        try {
          await axiosHelperCore({
            url: `rapport/user/addOrUpdateMemberFrameworkSelectionSetting`,
            method: "POST",
            JSONData: { userList : newUserList ?? userList, userId: loggedInMemberDetails?.userId, organizationId: viewMemberOrgId },
          });
          setShowSnackBar(true);
          setSnackbarType('success');
          setSnackbarMessage('Member selection saved successfully');
          setIsEditingMembers(false);
        } catch (error) {
          console.error("Error saving member selection", error);
          setShowSnackBar(true);
          setSnackbarType('error');
          setSnackbarMessage('Failed to save member selection.');
        }
    };

    const handleCancelMemberSelection = () => {
        const originalSelection = memberData.filter((option) =>
          userList.includes(option.value)
        );
        setUserList(originalSelection.map(option => option.value));
        setIsEditingMembers(false);
    };

    const handleMemberRemoveInitiate = (user) => {
        const users = [];
        users.push(user.value);
        setUserToRemove(users);
        setShowRemoveWarning(true);
    };
    
    const handleConfirmRemove = async () => {
        const newList = userList.filter((id) => !userToRemove.includes(id));
        setUserList((prevList) => {
            const newList = prevList.filter((id) => !userToRemove.includes(id));
            saveMemberSelection(newList);
            return newList;
        });
        updateSequenceUserFramework(userToRemove)
        setShowRemoveWarning(false);
        setUserToRemove([]);
    };
    
    const handleCancelRemove = () => {
        setShowRemoveWarning(false);
        setUserToRemove([]);
    };

    const updateSequenceUserFramework = async(userList) => {
        try {
            await axiosHelperCore({
                url: `rapport/user/updateSequenceUserFramework`,
                method: "POST",
                JSONData: { userList : userList, userId: loggedInMemberDetails?.userId },
            });
        } catch (error) {
            console.error("Error updating sequence user framework", error);
            setShowSnackBar(true);
            setSnackbarType('error');
            setSnackbarMessage('Operation failed.');
        }
    }

    const submitForm = async () => {
        if (
            (countWords(pitch) <= 300 &&
            pitch?.trim().length > 1) || countWords(persona?.trim()) <= MAX_LENGTH_FOR_PERSONA
        ) {
            setLoading(true);
            try {
                const response = await userService.updateMemberPitch({
                    userId: loggedInMemberDetails?.userId,
                    pitch,
                    persona: persona?.trim()
                });
                if (response.success) {
                    setShowSnackBar(true);
                    setSnackbarType('success');
                    setSnackbarMessage('Persona/Pitch saved successfully');
                }
            } catch (error) {
                console.error("Error updating persona/pitch", error);
                setShowSnackBar(true);
                setSnackbarType('error');
                setSnackbarMessage('Failed to save persona/pitch.');
            }

            setIsEditingPitch(false);
            setIsEditingPersona(false);
            setLoading(false);
        }
    };

    const handleEditPitchChange = (setter, value) => {
        setter(value);
        setIsEditingPitch(true);
    };

    const handleEditPersonaChange = (setter, value) => {
        setter(value);
        setIsEditingPersona(true);
    };

    const handleCancelPitch = () => {
        setPitch(userInfoData?.pitch);
        setIsEditingPitch(false);
    };

    const handleCancelPersona = () => {
        setPersona(userInfoData?.persona);
        setIsEditingPersona(false);
    };

    useEffect(() => {
        if (loggedInMemberDetails) {
            setViewMemberOrgId(loggedInMemberDetails?.organizationId);
            fetchCompanyKeywords(loggedInMemberDetails?.organizationId);
            getMemberList(loggedInMemberDetails?.organizationId);
            getUserInfoApi();
        }
    }, [loggedInMemberDetails]);

    // useEffect(() => {
    //     getStageList();
    // }, [])

    const getStageList = async () => {
        try {
            setLoading(true);
            const result = await axiosHelperCore({
                url: `outreach/getStages`,
                method: "POST",
                JSONData: {}
            });
            if(result?.response?.status === 404){
                setLoading(false);
                return;
            }
            console.log("resultresultresult", result);
            setStageList(result.data.stageList);
        }
        catch (error) {
            console.log("Error fetching outreach field data:", error);
            setLoading(false);
            return;
        }
        setLoading(false);
    }

    const saveMeetingTag = async (tags) => {
        try {

            const result = await axiosHelperCore({
                url: `rapport/user/saveMeetingTags`,
                method: "POST",
                JSONData: { meetingTags: tags.map(t => t.value) }
            });
            console.log("resultresultresult", result);
        }
        catch (error) {
            console.error("Error fetching outreach field data:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            {loading &&
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <MemoRevenoidLoader />
                </Backdrop>}
            <Flex background={"#fff"} flexDirection={"column"} padding="25px 32px" marginTop={"10px"} overflowY={"scroll"} borderRadius={"8px"}>

                <Flex flexDirection={"column"} padding={"15px"}>
                    <Flex alignItems={"center"} fontSize={"15px"} opacity={0.7} paddingBottom={"4px"}>Pitch</Flex>
                    <TextareaAutosize
                        style={{
                            fontSize: "14px",
                            width: "100%",
                            borderRadius: "6px",
                            padding: "16px",
                            border: "1px solid rgba(0, 0, 0, 0.20)",
                            fontFamily: "Inter ,sans-serif",
                        }}
                        value={pitch}
                        placeholder="A product pitch is a short description that highlights the key features and benefits of a product in order to persuade potential customers to buy it."
                        onChange={(e) => handleEditPitchChange(setPitch, e.target.value)}
                    />

                    <Flex justifyContent={"space-between"}>
                        <Box1 sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            color: '#9F9F9F',
                            fontSize: '10px',
                            marginTop: '4px'
                        }}>
                            {pitch ? (300 - countWords(pitch || "")) < 0 ? 0 : 300 - countWords(pitch || "") : "300"} word(s) remaining
                        </Box1>

                        <Box1 sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            color: '#9F9F9F',
                            fontSize: '10px',
                            marginTop: '4px'
                        }}>
                            (Up to 300 words are allowed)
                        </Box1>
                    </Flex>

                    {(countWords(pitch || "") > 300) && (
                        <Typography color="rgb(238, 0, 4)" marginTop={"4px"}>
                            {`Maximum 300 words are allowed`}
                        </Typography>
                    )}

                    {isEditingPitch && (
                        <Flex justifyContent="flex-end" gap="10px" marginTop="10px">
                            <Button onClick={handleCancelPitch} variant="secondary" fontSize={"14px"} padding={"10px 10px"} size="xs">Cancel</Button>
                            <Button disabled={countWords(pitch || "") > 300} onClick={submitForm} variant="primary" fontSize={"14px"} padding={"10px 10px"} size="xs">Save</Button>
                        </Flex>
                    )}
                </Flex>

                <Flex flexDirection={"column"} padding={"15px"}>
                    <Flex fontSize={"15px"} alignItems={"center"} opacity={0.7} paddingBottom={"4px"}>Persona</Flex>
                    <TextareaAutosize
                        style={{
                            width: "100%",
                            fontSize: "14px",
                            borderRadius: "6px",
                            padding: "16px",
                            border: "1px solid rgba(0, 0, 0, 0.20)",
                            fontFamily: "Inter ,sans-serif",
                        }}
                        value={persona}
                        placeholder="A persona is a detailed description of a fictional character representing a user type. It includes their goals, behaviors, and pain points to help address the needs of target customers. "
                        onChange={(e) => handleEditPersonaChange(setPersona, e.target.value)}
                    />
                    <Flex justifyContent={"space-between"}>
                        <Box1 sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            color: '#9F9F9F',
                            fontSize: '10px',
                            marginTop: '4px'
                        }}>
                            {persona ? (MAX_LENGTH_FOR_PERSONA - countWords(persona || "")) < 0 ? 0 : MAX_LENGTH_FOR_PERSONA - countWords(persona || "") : MAX_LENGTH_FOR_PERSONA} word(s) remaining
                        </Box1>
                        <Box1 sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            color: '#9F9F9F',
                            fontSize: '10px',
                            marginTop: '4px'
                        }}>
                            (Up to {MAX_LENGTH_FOR_PERSONA}  words are allowed)
                        </Box1>
                    </Flex>
                    {(countWords(persona || "") > MAX_LENGTH_FOR_PERSONA) && (
                        <Typography color="rgb(238, 0, 4)" marginTop={"4px"}>
                            {`Maximum ${MAX_LENGTH_FOR_PERSONA} words are allowed`}
                        </Typography>
                    )}
                    {isEditingPersona && (
                        <Flex justifyContent="flex-end" gap="10px" marginTop="10px">
                            <Button onClick={handleCancelPersona} variant="secondary" fontSize={"14px"} padding={"10px 10px"} size="xs">Cancel</Button>
                            <Button disabled={countWords(persona || "") > MAX_LENGTH_FOR_PERSONA} onClick={submitForm} variant="primary" fontSize={"14px"} padding={"10px 10px"} size="xs">Save</Button>
                        </Flex>
                    )}
                    {/* <hr style={{ marginTop: "30px" }}></hr> */}
                </Flex>

                <Flex flexDirection={"column"} padding={"15px"}>
                    <Flex flexDirection={"column"}>
                        <Flex marginBottom={"-4px"} alignItems={"center"} fontSize={"15px"} opacity={0.7} >Tracking Keywords&nbsp;
                            <div style={{ fontSize: "12px", fontStyle: "italic", color: "#0094E3" }}>(Updated By&nbsp;<span><b>{keywordArray?.trackingKeywordsUpdatedBy || "-"}</b></span>&nbsp;on&nbsp;<span>
                                <b>{trackingKeywordsUpdatedOn !== "NaN undefined" ? trackingKeywordsUpdatedOn : "-"}</b>
                            </span>)</div></Flex>
                    </Flex>
                    <SettingCard>
                        <Box1>
                            <KeywordTagManager
                                name='Keywords'
                                defaultValue={keywordArray?.trackingKeywords || []}
                                placeholder='e.g. growth challenges, acquisition etc.'
                                onSubmit={handleSaveCompanyKeywords}
                                loading={trackingLoading}
                                MAX_LENGTH={keywordArray?.trackingLimit}
                            />

                            <Box1 sx={{ marginTop: '10px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                {keywordArray?.trackingKeywords?.map(words => (
                                    <Tags key={words} disabled={loading}>
                                        {words}
                                        <svg onClick={() => handleDelete(words)} xmlns="http://www.w3.org/2000/svg" style={{ minWidth: 9 }} width={9} height={9} viewBox="0 0 9 9" fill="none">
                                            <path d="M8.80065 0.206172C8.7375 0.142889 8.66249 0.0926821 8.5799 0.0584261C8.49732 0.02417 8.40879 0.00653721 8.31939 0.00653721C8.22999 0.00653721 8.14146 0.02417 8.05888 0.0584261C7.97629 0.0926821 7.90128 0.142889 7.83813 0.206172L4.5 3.53747L1.16187 0.199346C1.09867 0.136145 1.02364 0.086012 0.941068 0.0518081C0.858492 0.0176043 0.769989 6.65925e-10 0.68061 0C0.591231 -6.65925e-10 0.502727 0.0176043 0.420151 0.0518081C0.337576 0.086012 0.262546 0.136145 0.199346 0.199346C0.136145 0.262546 0.086012 0.337576 0.0518081 0.420151C0.0176043 0.502727 -6.65925e-10 0.591231 0 0.68061C6.65925e-10 0.769989 0.0176043 0.858492 0.0518081 0.941068C0.086012 1.02364 0.136145 1.09867 0.199346 1.16187L3.53747 4.5L0.199346 7.83813C0.136145 7.90133 0.086012 7.97636 0.0518081 8.05893C0.0176043 8.14151 0 8.23001 0 8.31939C0 8.40877 0.0176043 8.49727 0.0518081 8.57985C0.086012 8.66242 0.136145 8.73745 0.199346 8.80065C0.262546 8.86385 0.337576 8.91399 0.420151 8.94819C0.502727 8.9824 0.591231 9 0.68061 9C0.769989 9 0.858492 8.9824 0.941068 8.94819C1.02364 8.91399 1.09867 8.86385 1.16187 8.80065L4.5 5.46253L7.83813 8.80065C7.90133 8.86385 7.97636 8.91399 8.05893 8.94819C8.14151 8.9824 8.23001 9 8.31939 9C8.40877 9 8.49727 8.9824 8.57985 8.94819C8.66242 8.91399 8.73745 8.86385 8.80065 8.80065C8.86385 8.73745 8.91399 8.66242 8.94819 8.57985C8.9824 8.49727 9 8.40877 9 8.31939C9 8.23001 8.9824 8.14151 8.94819 8.05893C8.91399 7.97636 8.86385 7.90133 8.80065 7.83813L5.46253 4.5L8.80065 1.16187C9.06006 0.902469 9.06006 0.465577 8.80065 0.206172Z" fill="white" />
                                        </svg>
                                    </Tags>
                                ))}
                            </Box1>
                        </Box1>
                    </SettingCard>
                </Flex>
                <Flex flexDirection={"column"} padding={"15px"}>
                    <Flex marginBottom={"-4px"} alignItems={"center"} fontSize={"15px"} opacity={0.7}>Hiring Keywords&nbsp;<div style={{ fontSize: "12px", opacity: 1, fontStyle: "italic", color: "#0094E3" }}>(Updated By&nbsp;<span style={{ fontWeight: 'bold' }}><b>{keywordArray?.hiringKeywordsUpdatedBy || "-"}</b></span>&nbsp;on&nbsp;<span><b>
                        {hiringKeywordsUpdatedOn !== "NaN undefined" ? hiringKeywordsUpdatedOn : "-"}
                    </b></span>)</div></Flex>
                    <SettingCard>

                        <Box1>
                            <KeywordTagManager
                                name='Job'
                                defaultValue={keywordArray?.hiringKeywords || []}
                                placeholder='SDR, Director of Sales'
                                onSubmit={handleJobSubmit}
                                loading={hiringLoading}
                                MAX_LENGTH={keywordArray?.hiringLimit}
                            />

                            <Box1 sx={{ marginTop: '10px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                {keywordArray?.hiringKeywords?.map(words => (
                                    <Tags key={words} disabled={loading}>
                                        {words}
                                        <svg onClick={() => handleHiringKeywordDelete(words)} xmlns="http://www.w3.org/2000/svg" style={{ minWidth: 9 }} width={9} height={9} viewBox="0 0 9 9" fill="none">
                                            <path d="M8.80065 0.206172C8.7375 0.142889 8.66249 0.0926821 8.5799 0.0584261C8.49732 0.02417 8.40879 0.00653721 8.31939 0.00653721C8.22999 0.00653721 8.14146 0.02417 8.05888 0.0584261C7.97629 0.0926821 7.90128 0.142889 7.83813 0.206172L4.5 3.53747L1.16187 0.199346C1.09867 0.136145 1.02364 0.086012 0.941068 0.0518081C0.858492 0.0176043 0.769989 6.65925e-10 0.68061 0C0.591231 -6.65925e-10 0.502727 0.0176043 0.420151 0.0518081C0.337576 0.086012 0.262546 0.136145 0.199346 0.199346C0.136145 0.262546 0.086012 0.337576 0.0518081 0.420151C0.0176043 0.502727 -6.65925e-10 0.591231 0 0.68061C6.65925e-10 0.769989 0.0176043 0.858492 0.0518081 0.941068C0.086012 1.02364 0.136145 1.09867 0.199346 1.16187L3.53747 4.5L0.199346 7.83813C0.136145 7.90133 0.086012 7.97636 0.0518081 8.05893C0.0176043 8.14151 0 8.23001 0 8.31939C0 8.40877 0.0176043 8.49727 0.0518081 8.57985C0.086012 8.66242 0.136145 8.73745 0.199346 8.80065C0.262546 8.86385 0.337576 8.91399 0.420151 8.94819C0.502727 8.9824 0.591231 9 0.68061 9C0.769989 9 0.858492 8.9824 0.941068 8.94819C1.02364 8.91399 1.09867 8.86385 1.16187 8.80065L4.5 5.46253L7.83813 8.80065C7.90133 8.86385 7.97636 8.91399 8.05893 8.94819C8.14151 8.9824 8.23001 9 8.31939 9C8.40877 9 8.49727 8.9824 8.57985 8.94819C8.66242 8.91399 8.73745 8.86385 8.80065 8.80065C8.86385 8.73745 8.91399 8.66242 8.94819 8.57985C8.9824 8.49727 9 8.40877 9 8.31939C9 8.23001 8.9824 8.14151 8.94819 8.05893C8.91399 7.97636 8.86385 7.90133 8.80065 7.83813L5.46253 4.5L8.80065 1.16187C9.06006 0.902469 9.06006 0.465577 8.80065 0.206172Z" fill="white" />
                                        </svg>
                                    </Tags>
                                ))}
                            </Box1>
                        </Box1>
                    </SettingCard>
                </Flex>

                <Flex flexDirection={"column"} padding={"15px"}>
                    <Flex marginBottom={"-4px"} alignItems={"center"} fontSize={"15px"}>Fact Keywords&nbsp;<div style={{ fontSize: "12px", opacity: 1, fontStyle: "italic", color: "#0094E3" }}>(Updated By&nbsp;<span style={{ fontWeight: 'bold' }}><b>{keywordArray?.factKeywordsUpdatedBy || "-"}</b></span>&nbsp;on&nbsp;<span><b>
                        {factKeywordsUpdatedOn !== "NaN undefined" ? hiringKeywordsUpdatedOn : "-"}
                    </b></span>)</div></Flex>

                    <SettingCard>
                        <Box1>
                            <KeywordTagManager
                                name='Fact'
                                defaultValue={keywordArray?.factKeywords || []}
                                placeholder='uses Microsoft dynamics, offers employee benefits'
                                onSubmit={handleFactSubmit}
                                loading={factLoading}
                                MAX_LENGTH={keywordArray?.factLimit}
                            />

                            <Box1 sx={{ marginTop: '10px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                {keywordArray?.factKeywords?.map(words => (
                                    <Tags key={words} disabled={loading}>
                                        {words}
                                        <svg onClick={() => handleFactKeywordDelete(words)} xmlns="http://www.w3.org/2000/svg" style={{ minWidth: 9 }} width={9} height={9} viewBox="0 0 9 9" fill="none">
                                            <path d="M8.80065 0.206172C8.7375 0.142889 8.66249 0.0926821 8.5799 0.0584261C8.49732 0.02417 8.40879 0.00653721 8.31939 0.00653721C8.22999 0.00653721 8.14146 0.02417 8.05888 0.0584261C7.97629 0.0926821 7.90128 0.142889 7.83813 0.206172L4.5 3.53747L1.16187 0.199346C1.09867 0.136145 1.02364 0.086012 0.941068 0.0518081C0.858492 0.0176043 0.769989 6.65925e-10 0.68061 0C0.591231 -6.65925e-10 0.502727 0.0176043 0.420151 0.0518081C0.337576 0.086012 0.262546 0.136145 0.199346 0.199346C0.136145 0.262546 0.086012 0.337576 0.0518081 0.420151C0.0176043 0.502727 -6.65925e-10 0.591231 0 0.68061C6.65925e-10 0.769989 0.0176043 0.858492 0.0518081 0.941068C0.086012 1.02364 0.136145 1.09867 0.199346 1.16187L3.53747 4.5L0.199346 7.83813C0.136145 7.90133 0.086012 7.97636 0.0518081 8.05893C0.0176043 8.14151 0 8.23001 0 8.31939C0 8.40877 0.0176043 8.49727 0.0518081 8.57985C0.086012 8.66242 0.136145 8.73745 0.199346 8.80065C0.262546 8.86385 0.337576 8.91399 0.420151 8.94819C0.502727 8.9824 0.591231 9 0.68061 9C0.769989 9 0.858492 8.9824 0.941068 8.94819C1.02364 8.91399 1.09867 8.86385 1.16187 8.80065L4.5 5.46253L7.83813 8.80065C7.90133 8.86385 7.97636 8.91399 8.05893 8.94819C8.14151 8.9824 8.23001 9 8.31939 9C8.40877 9 8.49727 8.9824 8.57985 8.94819C8.66242 8.91399 8.73745 8.86385 8.80065 8.80065C8.86385 8.73745 8.91399 8.66242 8.94819 8.57985C8.9824 8.49727 9 8.40877 9 8.31939C9 8.23001 8.9824 8.14151 8.94819 8.05893C8.91399 7.97636 8.86385 7.90133 8.80065 7.83813L5.46253 4.5L8.80065 1.16187C9.06006 0.902469 9.06006 0.465577 8.80065 0.206172Z" fill="white" />
                                        </svg>
                                    </Tags>
                                ))}
                            </Box1>
                        </Box1>
                    </SettingCard>
                </Flex>

                <Flex flexDirection={"column"} padding={"15px"}>
                    {isFrameworkSelection &&
                        <div>
                            <label htmlFor="select-member" style={{ margin: '15px 0 5px 0', display: 'block', fontWeight: "300", fontSize: "14px" }}>
                                Select Member
                            </label>
                            <Select
                                isMulti
                                id='select-member'
                                className="basic-multi-select"
                                classNamePrefix="select"
                                options={memberData}
                                name="menu"
                                menuPlacement='top'
                                placeholder="Select Member"
                                value={memberData.filter((option) =>
                                    userList.includes(option.value)
                                )}
                                closeMenuOnSelect={false}
                                onChange={handleSelectedMemberChange}
                                styles={{
                                    control: (provided: Record<string, unknown>, state: any) => ({
                                        ...provided,
                                        padding: '8px 0',
                                        margin: "15px 0",
                                        fontWeight: "300",
                                        fontSize: "14px",
                                        width : "100%"
                                    }),
                                }}
                                components={{
                                    MultiValueRemove: (props) => (
                                      <components.MultiValueRemove
                                        {...props}
                                        innerProps={{
                                          ...props.innerProps,
                                          onClick: (e) => {
                                            e.stopPropagation();
                                            handleMemberRemoveInitiate(props.data);
                                          },
                                        }}
                                      >
                                        {props.children}
                                      </components.MultiValueRemove>
                                    ),
                                }}
                            />
                            {isEditingMembers && (
                                <Flex justifyContent="flex-end" gap="10px" marginTop="10px">
                                    <Button onClick={handleCancelMemberSelection} variant="secondary" fontSize={"14px"} padding={"10px 10px"} size="xs">Cancel</Button>
                                    <Button onClick={() => {saveMemberSelection(null)}} variant="primary" fontSize={"14px"} padding={"10px 10px"} size="xs">Save</Button>
                                </Flex>
                            )}

                            <Dialog
                                open={showRemoveWarning}
                                onClose={handleCancelRemove}
                                style={{ "padding": "5px" }}
                            >
                                
                                <DialogTitle display={"flex"}>
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <svg
                                            className="h-6 w-6 text-red-600"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="pl-2 text-sm text-left">
                                        This action will revoke selected member access for framework option on sequences and remove the selected framework from all sequences on extension
                                    </div>
                                </DialogTitle>
                                <DialogActions>
                                <Button onClick={handleCancelRemove} variant="secondary" fontSize={"14px"} padding={"10px 10px"} size="xs">
                                    Cancel
                                </Button>
                                <Button onClick={handleConfirmRemove} variant="primary" fontSize={"14px"} padding={"10px 10px"} size="xs">
                                    Confirm
                                </Button>
                                </DialogActions>
                            </Dialog>
                        </div>}
                </Flex>

                {/* <Flex flexDirection={"column"} padding={"15px"}>
                    <Flex alignItems={"center"} fontSize={"15px"} opacity={0.7} paddingBottom={"4px"}>Meeting Tags</Flex>

                    <Select
                        styles={customStyles}
                        isMulti
                        menuPortalTarget={document.body}
                        className="text-sm  "
                        menuPlacement='auto'
                        placeholder="Select"
                        name="field1"
                        value={selectedStages}
                        options={stageList}
                        onChange={async (selectedField: any) => {
                            setSelectedStages(selectedField);
                            await saveMeetingTag(selectedField);
                        }}
                    />
                </Flex> */}

            </Flex>
        </div>
    )
}

export default SettingContent;
