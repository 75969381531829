import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { CustomFields } from "../component/custom.interface";

interface FrameworkAndContextStateType {
  contextId: string;
  memberListingData: any[];
  customDataFieldsApiData: CustomFields;
  dataRepositoryFeatureEnabled: boolean;
  openContextAddPopup: boolean;
  contextWordMaxLimit: number;
}

const initialFrameworkAndContextState: FrameworkAndContextStateType = {
  memberListingData: [],
  contextId: "",
  dataRepositoryFeatureEnabled: false,
  contextWordMaxLimit: 150,
  customDataFieldsApiData: {
    success: false,
    predefinedPrompt: [],
    variables: [],
    allFileNameofContentLibrary: [],
  },
  openContextAddPopup: false,
};

const frameworkAndContextSlice = createSlice({
  name: "frameworkAndContextState",
  initialState: initialFrameworkAndContextState,
  reducers: {
    setMemberListingData: (state, action: PayloadAction<any[]>) => {
      //@ts-ignore
      state.memberListingData = action.payload;
    },
    setContextId: (state, action: PayloadAction<string>) => {
      state.contextId = action.payload;
    },
    customDataFieldsApiData: (state, action: PayloadAction<CustomFields>) => {
      state.customDataFieldsApiData = action.payload;
    },
    setDataRepositoryFeatureEnabled: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.dataRepositoryFeatureEnabled = action.payload;
    },
    setOpenContextAddPopup: (state, action: PayloadAction<boolean>) => {
      state.openContextAddPopup = action.payload;
    },
    setContextMaxWordLimit: (state, action: PayloadAction<number>) => {
      state.contextWordMaxLimit = action.payload;
    },
  },
});

export const {
  setMemberListingData,
  setContextId,
  customDataFieldsApiData,
  setDataRepositoryFeatureEnabled,
  setOpenContextAddPopup,
  setContextMaxWordLimit,
} = frameworkAndContextSlice.actions;

export const frameworkAndContextStateSelector = (state: RootState) =>
  state.frameworkAndContextState;

export default frameworkAndContextSlice.reducer;
