import React, { useEffect, useCallback } from "react";
import "./App.css";
import Provider from "@evabot/eva-react-core-library/dist/Provider";
import EvaRoutes from "Routes";
// import { IntlProvider } from "react-intl";
// import { en } from "./locales";
import { axiosHelperCore } from "utils";
import { useDispatch } from "react-redux";
import {
  aeReturnProfileData,
  aeReturnProfileDataLoading,
} from "containers/AEReturnProfile/store/aeReturnProfileReducer";
import {
  setLoggedInState,
  setLoggedInUserName,
  setMenuItems,
  setProspectCount,
  setAccountCount,
  setUserMenuAccess,
} from "store/globalStateReducer";
import { useAppSelector } from "hooks";
import userService from "services/userService";
import menuDetails from "utils/menuBuilder";
import { ToastContainer } from "react-toastify";

interface GetTotalCountParams {
  organizationId: string | null;
  userType: string | null;
  userId?: string | null;
}

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useAppSelector((state) => state.globalState.isLoggedIn);
  const profileData = useAppSelector((state) => state.profileState.profileData);

  useEffect(() => {});

  const fetchProfile = useCallback(async () => {
    try {
      //@ts-ignore
      dispatch(aeReturnProfileDataLoading(true));
      const result = await axiosHelperCore({
        url: `rapport/user/getMemberInfoForProfile`,
        method: "POST",
      });
      const data = result.data.data;
      const formData = {
        userId: data?.userId,
        firstName: data?.identityPersonal?.firstName,
        lastName: data?.identityPersonal?.lastName,
        email: data?.identityContact?.email,
        role: data?.access?.type,
        designation: result.data?.designation,
        comapnyName: data?.identityBusiness?.name,
        phoneNo: data?.identityContact?.mobile,
        imageFile: data?.identityPersonal?.image,
        refreshTokenType:
          data?.access?.refreshToken?.find((token) => token.type == "salesloft")
            ?.type ||
          data?.access?.refreshToken?.find((token) => token.type == "outreach")
            ?.type ||
          data?.access?.refreshToken?.find(
            (token) => token.type == "salesforce"
          )?.type ||
          data?.access?.refreshToken?.find((token) => token.type == "hubspot")
            ?.type,
        refreshToken:
          data?.access?.refreshToken?.find((token) => token.type == "salesloft")
            ?.token ||
          data?.access?.refreshToken?.find((token) => token.type == "outreach")
            ?.token ||
          data?.access?.refreshToken?.find(
            (token) => token.type == "salesforce"
          )?.token ||
          data?.access?.refreshToken?.find((token) => token.type == "hubspot")
            ?.token ||
          "",
        expiredOn:
          data?.access?.refreshToken?.find((token) => token.type == "salesloft")
            ?.token ||
          data?.access?.refreshToken?.find((token) => token.type == "outreach")
            ?.expiredOn ||
          data?.access?.refreshToken?.find(
            (token) => token.type == "salesforce"
          )?.expiredOn ||
          data?.access?.refreshToken?.find((token) => token.type == "hubspot")
            ?.expiredOn,
        tokenNumber: data?.tokenNumber,
        organizationId: data?.organizationId,
        isExaSummary: result.data?.isExaSummary,
      };
      dispatch(aeReturnProfileData(formData));
      dispatch(setLoggedInUserName(data?.identityPersonal?.firstName));
      //@ts-ignore
      dispatch(aeReturnProfileDataLoading(false));
      dispatch(setUserMenuAccess(data?.access?.menu));
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  const base64ToUint8Array = (base64) => {
    const binaryString = atob(base64);
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }
    return byteArray;
  };

  const isSuperAdminView = localStorage.getItem("superadminview");
  const fetchMenuItems = useCallback(async () => {
    try {
      const obj = { isSuperAdminView };
      userService.getMenuOptions(obj).then(async (result) => {
        const memberMenu = result.menuItems?.message?.data;

        const data = result.menuItems?.message;

        if (!data?.encryptionKey) {
          return data;
        }
        const keyBuffer = base64ToUint8Array(data.encryptionKey);
        const iv = base64ToUint8Array(data.iv);
        const hexString = data.data;
        const encryptedData = new Uint8Array(
          hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
        );
        const key = await crypto.subtle.importKey(
          "raw",
          keyBuffer,
          "AES-CBC",
          false,
          ["decrypt"]
        );
        const decryptedData = await crypto.subtle.decrypt(
          { name: "AES-CBC", iv },
          key,
          encryptedData
        );
        const decryptedText: any = new TextDecoder().decode(decryptedData);
        const memberArray = JSON.parse(decryptedText)?.map((menuValue) =>
          menuDetails.find(
            (m) => m.dbValue?.toLowerCase() === menuValue?.toLowerCase()
          )
        );
        dispatch(setMenuItems(memberArray));
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getTotalAccountAndProspectCount = useCallback(
    async (organizationId, userType) => {
      try {
        let params: GetTotalCountParams = { organizationId, userType };
        if (userType !== "admin" && userType !== "superadmin") {
          params.userId = profileData.userId;
        }

        const response = await userService.getTotalCount(params);

        if (response) {
          if (response.prospectCount !== undefined) {
            dispatch(setProspectCount(response?.prospectCount));
          } else {
            console.log(
              "Unexpected response format for prospectCount:",
              response
            );
          }

          if (response.accountCount !== undefined) {
            dispatch(setAccountCount(response?.accountCount?.totalCount));
          } else {
            console.log(
              "Unexpected response format for accountCount:",
              response
            );
          }
        }
      } catch (error) {
        console.log("Error in getTotalAccountAndProspectCount:", error);
      }
    },
    [profileData.userId, dispatch]
  );

  useEffect(() => {
    if (isLoggedIn) {
      fetchProfile();
      fetchMenuItems();
    }
  }, [fetchProfile, isLoggedIn]);

  useEffect(() => {
    dispatch(setProspectCount(""));
    dispatch(setAccountCount(""));
    if (profileData.role && profileData.organizationId) {
      getTotalAccountAndProspectCount(
        profileData.organizationId,
        profileData.role
      );
    }
  }, [profileData, getTotalAccountAndProspectCount]);

  useEffect(() => {
    if (localStorage.getItem("Authorization")) {
      dispatch(setLoggedInState(true));
    }
  }, []);

  return (
    <Provider>
      {/* <IntlProvider locale="en" messages={en}> */}
      <ToastContainer hideProgressBar />
      <EvaRoutes />
      {/* <HeaderMessage /> */}
      {/* </IntlProvider> */}
    </Provider>
  );
}

export default App;
