import React from "react";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { useFormikContext } from "formik";
import _ from "lodash";

interface SelectRadioProps {
  name: string;
  title: string;
  options: { label: string; value: string }[];
  value: string;
  disabled?: boolean;
  extraIcon?: React.ReactElement;
  required?: boolean;
  handleValuesChange?: () => void;
}

const SelectRadio: React.FC<SelectRadioProps> = ({
  name,
  title,
  options,
  value,
  disabled,
  extraIcon,
  required,
  handleValuesChange,
}) => {
  const { handleChange, errors, touched } = useFormikContext();

  const keyValue = options?.find((option) => option?.value === value)?.value;

  return (
    <>
      <Flex flexDirection="column" paddingY="5px">
        <Text variant="bodyRegular" fontWeight="600" paddingBottom="5px">
          {title}
          {required && (
            <span style={{ color: "red", marginLeft: "5px" }}>*</span>
          )}
          {extraIcon}
        </Text>
        <Flex flexDirection="row" paddingY="5px" gap="10px">
          {options.map((option) => (
            <label
              key={option.value}
              style={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <input
                name={name}
                type="radio"
                value={option.value}
                checked={keyValue === option?.value}
                onChange={(value) => {
                  if (handleValuesChange) {
                    handleValuesChange();
                  }
                  handleChange(value);
                }}
                disabled={disabled}
              />
              {option?.label}
            </label>
          ))}
        </Flex>
        {_.get(errors, name) && _.get(touched, name) && (
          <Text color={"red"} fontSize={"12px"}>
            {_.get(errors, name)}
          </Text>
        )}
      </Flex>
    </>
  );
};

export default SelectRadio;
